import { MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY, MONITOR_MEDIAQUERY, IMAGE_PROXY_M, IMAGE_PROXY_L } from './../../../constants';
import { PublicationService } from '../../../../graphql/services/publication.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DEAFULT_IMAGE_PUBLICATION, DEAFULT_SHIELD_COMPETITION, DEAFULT_SHIELD_TEAM, TYPE_COMPETITION_TEAM } from '../../../constants';
import { SharedService } from '../../../services/shared.service';
import { PublicationState } from '../../../../redux/state/publication.state';
import { Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from 'app/graphql/types';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

declare var lightGallery: any;

@UntilDestroy()
@Component({
  selector: 'app-publication-gallery',
  styleUrls: ['./publication-gallery.component.scss'],
  templateUrl: './publication-gallery.component.html',
})
export class PublicationGalleryComponent implements OnInit, OnDestroy {

  @Input() galleryImages: string[];

  body = null;
  defaultImagePublication = DEAFULT_IMAGE_PUBLICATION;
  defaultShieldCompetition = DEAFULT_SHIELD_COMPETITION;
  IMAGE_PROXY_M = IMAGE_PROXY_M;
  TYPE_COMPETITION_TEAM = TYPE_COMPETITION_TEAM;
  defaultShieldTeam = DEAFULT_SHIELD_TEAM;
  entity: Entity = this.store.snapshot().Entity.entity;

  options: any[] = [];

  private layoutChanges: Subscription;

  isMobileScreen = false;
  isTabletScreen = false;

  constructor(private store: Store,public sharedService: SharedService,private breakpointObserver: BreakpointObserver,public translate: TranslateService,private publicationService: PublicationService,  private http: HttpClient, private titleService: Title, private metaService: Meta, private sanitizer: DomSanitizer, private route: ActivatedRoute, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.layoutChanges = this.breakpointObserver
    .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY, MONITOR_MEDIAQUERY]).pipe(untilDestroyed(this))
    .subscribe(state => {
      this.isMobileScreen = state.breakpoints[MOBILE_MEDIAQUERY];
      this.isTabletScreen = state.breakpoints[TABLET_MEDIAQUERY];
    });
  }

  ngOnDestroy() {
    this.layoutChanges.unsubscribe();
  }
  openGallery(index: number){
    let item = {};
    const galleries = [];

    this.galleryImages.map((image: string) => {
        item = {
          src: this.sharedService.getImage(image,IMAGE_PROXY_L),
          thumb: this.sharedService.getImage(image,IMAGE_PROXY_M),
        };
        galleries.push(item);
    });
    const galleryLight = document.getElementById('lightgallery');


    lightGallery(galleryLight, {
      index,
      dynamic: true,
      dynamicEl: galleries,
      showThumbByDefault: false,
      download: false,
      showAfterLoad: false,
      animateThumb:true
    });

    galleryLight.addEventListener('onCloseAfter', () => {
      galleryLight.setAttribute('lg-uid', '');
    });
  }



}






