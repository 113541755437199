import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { AdminLayoutComponent } from './theme/admin-layout/admin-layout.component';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
export const appRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: { title: 'Dashboard', titleI18n: 'dashboard' },
      },
      {
        path: 'news',
        loadChildren: () => import('./routes/news/news.module').then(m => m.NewsModule),
        data: { title: 'News', titleI18n: 'news' },
      },
      {
        path: 'teams',
        loadChildren: () => import('./routes/teams/teams.module').then(m => m.TeamsModule),
        data: { title: 'Teams', titleI18n: 'teams' },
      },
      {
        path: 'competitions',
        loadChildren: () => import('./routes/competitions/competitions.module').then(m => m.CompetitionsModule),
        data: { title: 'Competitions', titleI18n: 'competitions' },
      },
      {
        path: 'matches',
        loadChildren: () => import('./routes/matches/matches.module').then(m => m.MatchesModule),
        data: { title: 'Matches', titleI18n: 'matches' },
      },
      {
        path: 'sponsors',
        loadChildren: () => import('./routes/sponsors/sponsors.module').then(m => m.SponsorsModule),
        data: { title: 'Sponsors', titleI18n: 'sponsors' },
      },
      {
        path: 'multimedia',
        loadChildren: () => import('./routes/multimedia/multimedia.module').then(m => m.MultimediaModule),
        data: { title: 'Matches', titleI18n: 'media' },
      },
      {
        path: 'page',
        loadChildren: () => import('./routes/page/page.module').then(m => m.PageModule),
        data: { title: 'Página', titleI18n: 'page' },
      },
      {
        path: 'design',
        loadChildren: () => import('./routes/design/design.module').then(m => m.DesignModule),
        data: { title: 'Design', titleI18n: 'design' },
      },
      {
        path: 'material',
        loadChildren: () => import('./routes/material/material.module').then(m => m.MaterialModule),
        data: { title: 'Material', titleI18n: 'material' },
      },
      {
        path: 'media',
        loadChildren: () => import('./routes/multimedia/multimedia.module').then(m => m.MultimediaModule),
        data: { title: 'Multimedia', titleI18n: 'multimedia' },
      },
      {
        path: 'forms',
        loadChildren: () => import('./routes/forms/forms.module').then(m => m.FormsModule),
        data: { title: 'Forms', titleI18n: 'forms' },
      },
      {
        path: 'tables',
        loadChildren: () => import('./routes/tables/tables.module').then(m => m.TablesModule),
        data: { title: 'Tables', titleI18n: 'tables' },
      },
      {
        path: 'error',
        loadChildren: () => import('./routes/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Sessions', titleI18n: 'sessions' },
      },
      {
        path: 'helpers',
        loadChildren: () => import('./routes/helpers/helpers.module').then(m => m.HelpersModule),
      },
    ],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: environment.useHash,
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
