<mat-toolbar fxHide="true" fxShow.xs="true" fxShow.sm="true" class="matero-toolbar">
    <button fxHide="true" fxShow.xs="true" fxShow.sm="true" mat-icon-button class="matero-toolbar-button" (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

    <div *ngIf="entity$ | async; let entity" style="display: flex; width: calc(100% - 104px);
  align-items: center; justify-content: center;">
        <img appImgFallback fxHide="true" fxShow.xs="true" fxShow.sm="true" src="{{(entity.image) ? sharedService.getImage(entity.image) : sharedService.getImage(defaultImageEntity)}}" style="height:40px;margin-right:8px;" alt="logo" />
        <p fxHide="true" fxShow.xs="true" fxShow.sm="true" style="font-size: 1rem; font-weight:700; white-space:normal; text-align:left; line-height: 1.2rem; max-width: 64%;">{{ entity.name }}</p>

        <span *ngIf="options.navPos==='side'" fxHide="true" fxShow.gt-md="true" style="margin-left:20px;margin-top:5px;">{{ entity.name }}</span>
    </div>


    <!--   <app-branding style="display:none;" *ngIf="showBranding"></app-branding> -->
    <span fxFlex></span>

    <!--   <app-github-button></app-github-button>
 -->
    <!--   <button mat-icon-button class="matero-toolbar-button">
    <mat-icon>search</mat-icon>
  </button> -->

    <!-- <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
          (click)="toggleFullscreen()">
    <mat-icon>fullscreen</mat-icon>
  </button>

  <app-notification fxHide.lt-sm></app-notification> -->


    <!--
  <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
          (click)="toggleSidenavNotice.emit()">
          <mat-icon>notifications</mat-icon>
          <span class="badge bg-red-500">5</span>
  </button>
  <app-user></app-user> -->
    <!--   <span class="pointer" fxHide.lt-md *ngIf="socials && socials.length > 0" style="height:24px;font-size:14px;line-height: 40px;font-weight: 300;">
    <a *ngFor="let social of socials" mat-icon-button class="matero-toolbar-button mat-social pointer" fxHide.lt-sm target="new" href="{{ social.value }}">
          <mat-icon class="pointer" *ngIf="social.icon.includes('svg_')" svgIcon="{{ social.icon }}"> </mat-icon>
          <mat-icon class="pointer" *ngIf="!social.icon.includes('svg_')"> {{ social.icon }}</mat-icon>
    </a>
</span> -->


    <!-- <span fxHide.lt-md style="margin-left:30px;height:24px;font-size:14px;line-height: 40px;font-weight: 300;">Patrocinadores principales | </span>
  <span fxHide.lt-md style="margin-top:2px;margin-left:10px;">
    <img appImgFallback  src="https://www.valenciacf.com/assets/images/logos/puma.svg">
    <img appImgFallback  src="https://www.valenciacf.com/assets/images/logos/bwin-white.svg">
  </span> -->


    <!-- <div>
    <a *ngFor="let social of socials" class="mat-social" mat-list-item href="{{social.value}}">
      <mat-icon *ngIf="social.icon.includes('svg_')" svgIcon="{{ social.icon }}"> </mat-icon>
      <mat-icon *ngIf="!social.icon.includes('svg_')"> {{ social.icon }}</mat-icon>
    </a>
  </div> -->

    <!--   <app-translate></app-translate> -->

</mat-toolbar>