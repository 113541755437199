<!-- <div *ngIf="!inPublication" class="matero-row m-l-0 m-r-0" fxLayout="row wrap">
  <div class="matero-col match-tile" fxFlex="100" fxFlex.gt-sm="100">
    <div class="date">
      <ng-container *ngIf="!hideDate">
        <span class="date" *ngIf="!onlyTime">
          {{ match.date | dateFormat:'LLLL' }}
        </span>
        <span class="date" *ngIf="onlyTime && match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED">
          {{ 'general.match_time' | translate }}: {{ match.date | dateFormat:'LT' }}
        </span>
      </ng-container>
      <div *ngIf="match.matchDayId">{{ getMatchDay(match.matchDayId) }}</div>
    </div>

    <div class="matero-row content" fxLayout="row wrap">
      <div class="matero-col div_shield" fxFlex="15">
        <img appImgFallback  class="shield"
             src="{{(localMember.image) ? sharedService.getImage(localMember.image) : sharedService.getImage(defaultImageProfile)}}">
      </div>
      <div class="matero-col local_team" fxFlex="25">
        {{ localMember.firstName }} {{ localMember.lastName }}
      </div>
      <div class="matero-col div_result" fxFlex="20">
        <div *ngIf="match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED"
             [class.text-green-500]="match.statusId === STATUS_IN_GAME" class=""
             style="font-size: 1.5rem;font-weight: 500;margin-bottom:10px;">{{ match.localResult }}
          - {{ match.visitorResult }}</div>
        <div *ngIf="match.statusId === STATUS_UNDEFINED || match.statusId === STATUS_NOT_STARTED"
             [class.text-green-500]="match.statusId === STATUS_IN_GAME" class=""
             style="font-size: 1.5rem;font-weight: 500;margin-bottom:10px;">{{ match.date |
          dateFormat:'LT' }}</div>
        <div class="status">{{ typeService.getType(match.statusId).langKey | translate }}</div>
      </div>
      <div class="matero-col visitor_team" fxFlex="25">
        {{ visitorMember.firstName }} {{ visitorMember.lastName }}
      </div>
      <div class="matero-col div_shield" fxFlex="15">
        <img appImgFallback  class="shield"
             src="{{(visitorMember.image) ? sharedService.getImage(visitorMember.image) : sharedService.getImage(defaultImageProfile)}}">
      </div>
    </div>
  </div>
  <div *ngIf="match.competition" style="width: 100%;text-align: center;">{{ match.competition.name +' | '+  (typeService.getType(match.competition.categoryId).langKey | translate) +' | '+  (typeService.getType(match.competition.sportId).langKey | translate)}}</div>
</div> -->

<div *ngIf="!inPublication" class="matero-row m-l-0 m-r-0" fxLayout="row wrap">
  <mat-card class="p-0" [ngClass]="{'pointer match-tile-widget': pointer===undefined || pointer===true}" style="width:100%;border: 1px solid #e0e0e0;">
    <mat-card-content>
      <div class="matero-row m-l-0 m-r-0" fxLayout="row wrap">
        <div *ngIf="match.competition" class="matero-col match-tile" fxFlex="100" fxFlex.gt-sm="25" style="background-color: #f1f1f1;height: auto;display: flex;
        align-items: center;">
          <div style="width: 100%;line-height: 1.2rem;padding-top:5px;text-align: center;margin-bottom: 5px;">
            <div style="font-size: 1.1rem;font-weight: 400;margin-bottom:5px;">{{ (typeService.getType(match.competition.categoryId).langKey | translate) }}</div>
            <div class="matero-row m-l-0 m-r-0" fxLayout="row wrap">
              <div fxFlex="33" fxFlex.gt-sm="100">{{ match.competition.name }}</div>
              <div fxFlex="33" fxFlex.gt-sm="100">{{ (typeService.getType(match.competition.sportId).langKey | translate)}}</div>
              <div *ngIf="match.matchDayId" fxFlex="33" fxFlex.gt-sm="100">{{ getMatchDay(match.matchDayId) }}</div>
            </div>
          </div>
        </div>
        <div class="matero-col match-tile" fxFlex="100" fxFlex.gt-sm="{{match.competition ? '75' : '100'}}" >
          <div class="date m-t-8" >
            <ng-container *ngIf="!hideDate">
              <span class="date" *ngIf="!onlyTime">
                {{ match.date | dateFormat:'LLLL' }}
              </span>
              <span class="date" *ngIf="onlyTime && match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED">
                {{ 'general.match_time' | translate }}: {{ match.date | dateFormat:'LT' }}
              </span>
            </ng-container>
          </div>
          <div class="matero-row content p-8" fxLayout="row wrap">
            <div class="matero-col local_team" fxFlex="38" style="text-align: center; font-weight: 400;">
              <div class="matero-col div_shield">
                <img appImgFallback  class="shield" style="max-width: 75px;padding:0%"
                  src="{{(localMember.image) ? sharedService.getImage(localMember.image) : sharedService.getImage(defaultImageProfile)}}">
              </div>
              {{ localMember.firstName }} {{ localMember.lastName }}
            </div>
            <div class="matero-col div_result" fxFlex="24">
              <div *ngIf="match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED"
                  [class.text-green-500]="match.statusId === STATUS_IN_GAME" class=""
                  style="font-size: 1.5rem;font-weight: 500;margin-bottom:10px;">{{ match.localResult }}
                - {{ match.visitorResult }}</div>
              <div *ngIf="match.statusId === STATUS_UNDEFINED || match.statusId === STATUS_NOT_STARTED"
                  [class.text-green-500]="match.statusId === STATUS_IN_GAME" class=""
                  style="font-size: 1.5rem;font-weight: 500;margin-bottom:10px;">{{ match.date |
                dateFormat:'LT' }}</div>
              <div class="status">{{ typeService.getType(match.statusId).langKey | translate }}</div>
            </div>
            <div class="matero-col visitor_team" fxFlex="38" style="text-align: center; font-weight: 400;">
              <div class="div_shield">
                <img appImgFallback  class="shield" style="max-width: 75px;padding:0%"
                src="{{(visitorMember.image) ? sharedService.getImage(visitorMember.image) : sharedService.getImage(defaultImageProfile)}}">
              </div>
              {{ visitorMember.firstName }} {{ visitorMember.lastName }}
            </div>
          </div>
        </div>

      </div>
      </mat-card-content>
  </mat-card>
</div>

<div *ngIf="inPublication" class="matero-row m-l-0 m-r-0" fxLayout="row wrap">
  <div [routerLink]="sharedService.buildRoute(['matches', match.id])" class="matero-col match-tile match-tile-widget pointer" fxFlex="100" fxFlex.gt-sm="100">
    <ng-container *ngIf="!hideDate">
      <div class="date" style="font-size: 0.7rem;margin-top:2px!important;">
        {{ match.date | dateFormat:'LLLL' }}
      </div>
    </ng-container>
    <div class="matero-row content" style="margin-bottom:0px;
    margin-top:0px" fxLayout="row wrap">
      <div class="matero-col div_shield" fxFlex="15">
        <img appImgFallback  class="shield"
             src="{{(localMember.image) ? sharedService.getImage(localMember.image) : sharedService.getImage(defaultImageProfile)}}">
      </div>
      <div class="matero-col local_team" style="font-size: 0.7rem;display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;" fxFlex="25">
        {{ localMember.firstName }} {{ localMember.lastName }}
      </div>
      <div class="matero-col div_result" fxFlex="20">
        <div *ngIf="match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED"
             [class.text-green-500]="match.statusId === STATUS_IN_GAME" class=""
             style="font-size: 1.3rem;font-weight: 500;margin-bottom:2px;">{{ match.localResult }}
          - {{ match.visitorResult }}</div>
        <div *ngIf="match.statusId === STATUS_UNDEFINED || match.statusId === STATUS_NOT_STARTED"
             [class.text-green-500]="match.statusId === STATUS_IN_GAME" class=""
             style="font-size: 1.3rem;font-weight: 500;margin-bottom:2px;">{{ match.date |
          dateFormat:'LT' }}</div>
        <div class="status" style="font-size: 0.65rem;margin-bottom:5px;">{{ typeService.getType(match.statusId).langKey | translate }}</div>
      </div>
      <div class="matero-col visitor_team" style="font-size: 0.7rem;display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;" fxFlex="25">
        {{ visitorMember.firstName }} {{ visitorMember.lastName }}
      </div>
      <div class="matero-col div_shield" fxFlex="15">
        <img appImgFallback  class="shield"
             src="{{(visitorMember.image) ? sharedService.getImage(visitorMember.image) : sharedService.getImage(defaultImageProfile)}}">
      </div>
    </div>
  </div>
</div>
