import { Component, Inject, ViewChild, EventEmitter } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

export interface DialogFormData{
  title: string;
  formOptions: FormlyFormOptions;
  formFields: FormlyFieldConfig[];
  formModel: any;
  autocomplete: boolean;
}

@Component({
  selector: 'app-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.scss'],
})


export class DialogFormComponent  {
  loading: boolean = false;
  form = new FormGroup({});
  onClick = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogFormData
    ) {

    }

    sendData(){
      this.loading = true;
      this.onClick.emit(this.data.formModel);
    }

    closeDialog(){
      this.dialogRef.close();
    }

}

