 <div class="cropper-image-container">
 <img appImgFallback  class="mat-card" [src]="(formControl.value.indexOf('yulava/') == 0) ? sharedService.getImage(formControl.value, imageSize) : formControl.value">
 <a class="cropper-btn" (click)="imageInput.click()" mat-fab>
    <mat-icon>camera_alt</mat-icon>
</a>
<input #imageInput
         hidden
         type="file"
         accept="image/*"
         (change)="processFile($event)">
</div>
