import { Team } from "app/graphql/types";

export class GetPublicTeams {
  static readonly type = '[TEAM] Get public teams';
  constructor(public readonly seasonId: string){}
}

export class GetPublicCurrentTeam {
  static readonly type = '[TEAM] Get public current team';
  constructor(public readonly id: string,public readonly seasonId: string){}
}

export class SetPublicCurrentTeam {
  static readonly type = '[TEAM] Set public current publication';
  constructor(public readonly team: Team){}
}



