import { CustomizationInputModel } from '../../../../graphql/interfaces/publicationModel';
import { PublicationService } from '../../../../graphql/services/publication.service';
import { GetPublicCurrentPublication, SetPublicCurrentPublication } from '../../../../redux/actions/publication.actions';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DEAFULT_IMAGE_PUBLICATION, DEAFULT_SHIELD_TEAM, STATUS_IN_GAME, STATUS_NOT_STARTED, STATUS_UNDEFINED } from '../../../constants';
import { SharedService } from '../../../services/shared.service';
import { PublicationState } from '../../../../redux/state/publication.state';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetMorePublicPublications, GetPublicPublications } from 'app/redux/actions/publication.actions';
import { FormPublication, Poll, Publication } from 'app/graphql/interfaces/publicationModel';
import { Observable } from 'rxjs';
import { DEAFULT_IMAGE_ENTITY } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityState } from 'app/redux/state/entity.state';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Match, Member, Team } from 'app/graphql/types';
import { TypeService } from 'app/graphql/services/type.service';

@UntilDestroy()
@Component({
  selector: 'app-match-tile',
  styleUrls: ['./match-tile.component.scss'],
  templateUrl: './match-tile.component.html',
})
export class MatchTileComponent implements OnInit, OnChanges {

  @Input() localTeam: Team;
  @Input() visitorTeam: Team;
  @Input() localMember: Member;
  @Input() visitorMember: Member;
  @Input() match: Match;
  @Input() inPublication: Boolean;
  @Input() widget: Boolean;
  @Input() onlyTime: Boolean;
  @Input() hideDate: Boolean;
  @Input() pointer: Boolean;
  defaultShieldTeam = DEAFULT_SHIELD_TEAM;
  STATUS_UNDEFINED = STATUS_UNDEFINED;
  STATUS_NOT_STARTED = STATUS_NOT_STARTED;
  STATUS_IN_GAME = STATUS_IN_GAME;



  constructor(private store: Store,public sharedService: SharedService,public translate: TranslateService,public typeService: TypeService,private publicationService: PublicationService,  private http: HttpClient, private titleService: Title, private metaService: Meta, private sanitizer: DomSanitizer, private route: ActivatedRoute, private translateService: TranslateService) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  getMatchDay(id: string){
    if(this.match.competition && this.match.competition.matchDays){
      const matchDay = this.match.competition.matchDays.filter(item => item.id === id);
      if(matchDay.length > 0 && matchDay[0].detail){
        return matchDay[0].detail
      }
      return '';
    }
  }


}






