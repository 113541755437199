<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>

  <form [formGroup]="form" autocomplete="{{data.autocomplete ? 'on' : 'off'}}" align="end">
    <formly-form [model]="data.formModel" [fields]="data.formFields" [options]="data.formOptions" [form]="form">
    </formly-form>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button  mat-button  (click)="closeDialog()">{{ 'form.cancel' | translate }}</button>
  <button  mat-raised-button color="accent" [class.btn_spinner]="loading" [disabled]="loading || !form.valid" (click)="sendData()">{{ 'form.accept' | translate }}</button>
</mat-dialog-actions>
