import { GetPublicEntity } from './redux/actions/entity.actions';
import { Entity } from './graphql/interfaces/entityModel';
import { EntityState } from './redux/state/entity.state';
import { Component, OnInit, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { PreloaderService } from '@core';
import { Store } from '@ngxs/store';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { EntityService } from './graphql/services/entity.service';
import { DOCUMENT } from '@angular/common';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
import { DEAFULT_IMAGE_ENTITY, MODULE_WEB } from '@shared/constants';
import { SharedService } from '@shared/services/shared.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy /* ,AfterViewInit */ {
  constructor(private ccService: NgcCookieConsentService,private preloader: PreloaderService, private sharedService: SharedService, private translateService: TranslateService, private loginService: EntityService, private store: Store,@Inject(DOCUMENT) private document: Document) {}

  @Select(EntityState.entity)
  entity$: Observable<Entity>;

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  ngOnInit() {

    this.translateService//
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy', 'cookie.cookiePolicyLink', 'cookie.privacyPolicyLink'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.getConfig().content.cookiePolicyLink = data['cookie.cookiePolicyLink'];
        this.ccService.getConfig().content.privacyPolicyLink = data['cookie.privacyPolicyLink'];

        this.ccService.destroy();//remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.store.dispatch(new GetPublicEntity(this.document.location.hostname));

    this.entity$.subscribe(value=>{
      if(value){

        //Comprobamos si el dominio actual es el configurado como main en yulava
        if(value.webDomain.filter(item => (item.url === window.location.hostname || "www."+item.url === window.location.hostname || item.url.replace("www.","") === window.location.hostname)).length > 0 && !value.webDomain.filter(item => (item.url === window.location.hostname || "www."+item.url === window.location.hostname || item.url.replace("www.","") === window.location.hostname))[0].main && value.webDomain.filter(item => item.main === true).length > 0){

          let mainDomain = value.webDomain.filter(item => item.main === true);
          if(mainDomain.length === 0){
            mainDomain = value.webDomain;
          }  

          window.location.href =  window.location.href.replace(window.location.hostname, mainDomain[0].url);
        }else if(value.webDomain.filter(item => item.main === true && item.url !== window.location.hostname).length > 0){
          let mainDomain = value.webDomain.filter(item => item.main === true);
          if(mainDomain.length === 0){
            mainDomain = value.webDomain;
          }  

          window.location.href =  window.location.href.replace(window.location.hostname, mainDomain[0].url);
        }else{
          let maxDate = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
          if (value.modules.find(item => item.moduleId === MODULE_WEB ).expirationDate == null && value.modules.find(item => item.moduleId === MODULE_WEB ).active == true){
            this.preloader.hide((value.image) ? this.sharedService.getImage(value.image) : this.sharedService.getImage(DEAFULT_IMAGE_ENTITY));
          } else{
            if (new Date(value.modules.find(item => item.moduleId === MODULE_WEB ).expirationDate) < maxDate || value.modules.find(item => item.moduleId === MODULE_WEB ).active == false) {
              this.preloader.suscriptionExpire((value.image) ? this.sharedService.getImage(value.image) : this.sharedService.getImage(DEAFULT_IMAGE_ENTITY));
            } else {
              this.preloader.hide((value.image) ? this.sharedService.getImage(value.image) : this.sharedService.getImage(DEAFULT_IMAGE_ENTITY));
            }
          }
          
        }
        
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
