import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map,   } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import gql from 'graphql-tag';
import { QueryMultimediaArgs, QueryMultimediaCompetitionsArgs, QueryMultimediaTeamsArgs } from '../types';
import {  multimediaTabsQuery, publicMultimediaQuery, multimediaGalleriesQuery, multimediaTeamsQuery, multimediaCompetitionsQuery } from '../operations/queries/multimediaQueries';

@Injectable({
  providedIn: 'root'
})

export class MultimediaService {

  constructor(
    private apollo: Apollo
    ) {

  }

  getMultimediaTabs(data:QueryMultimediaArgs){
    return this.apollo.query<any>({
      query: gql(multimediaTabsQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.multimediaTabs)
    );
  }

  getPublicMultimedia(data:QueryMultimediaArgs){
    return this.apollo.query<any>({
      query: gql(publicMultimediaQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.publicMultimedia)
    );
  }

  MultimediaGalleries

  getMultimediaGalleries(data:QueryMultimediaArgs){
    return this.apollo.query<any>({
      query: gql(multimediaGalleriesQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.multimediaGalleries)
    );
  }

  getMultimediaTeams(data:QueryMultimediaTeamsArgs){
    return this.apollo.query<any>({
      query: gql(multimediaTeamsQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.multimediaTeams)
    );
  }

  getMultimediaCompetitions(data:QueryMultimediaCompetitionsArgs){
    return this.apollo.query<any>({
      query: gql(multimediaCompetitionsQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.multimediaCompetitions)
    );
  }

}
