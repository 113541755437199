import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TYPE_OF_CUT, IMAGE_M, TYPE_OF_QUALITY, IMAGE_TRANSFORM_ENDPOINT, DEAFULT_IMAGE_PROFILE, GS_BUCKET, IMAGES_ENDPOINT, IMG_KEY, IMG_SALT, IMAGE_PROXY_S, IMAGE_PROXY_M } from '../constants';
import jsSHA from "jssha";
 
@Injectable()
export class SharedService {

constructor( private metaService: Meta, private localize: LocalizeRouterService) { }

getTypeOfCut(typeOfCut: TYPE_OF_CUT):string {
  switch (typeOfCut) {
    case TYPE_OF_CUT.COVER:
      return "cover";
    case TYPE_OF_CUT.INSIDE:
      return "inside";
    case TYPE_OF_CUT.OUTSIDE:
      return "outside";
    case TYPE_OF_CUT.CONTAIN:
      return "contain";
    case TYPE_OF_CUT.FILL:
      return "fill";
    default:
      return "cover";
  }
}

hex_to_ascii(str1)
 {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
 }

 urlSafeBase64(string){
  return btoa(string).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

sign(salt, target, secret) {
  var shaObj = new jsSHA("SHA-256", "BYTES")
  shaObj.setHMACKey(secret, "HEX")
  shaObj.update(this.hex_to_ascii(salt))
  shaObj.update(target)
  let hmac = shaObj.getHMAC("B64").replace(/=/g, "").replace(/\//g, '_').replace(/\+/g, '-')
  return hmac
}

getImage(urlImage: string, size: string = IMAGE_PROXY_M, typeCut: TYPE_OF_CUT = TYPE_OF_CUT.INSIDE, quality: TYPE_OF_QUALITY = TYPE_OF_QUALITY.HIGH) : string {
  let ext = urlImage.split('.').pop().toLowerCase();

  if (ext == "heic") {
    ext = "jpg";
  }

  if (ext == "mov") {
    ext = "jpg";
  }
  
  let path = "/resize:fit" + size + "/" + this.urlSafeBase64("gs://" + GS_BUCKET + "/" + urlImage)+"."+ext;
  return IMAGES_ENDPOINT + '/' + this.sign(IMG_SALT, path, IMG_KEY)+path;
}

getImageProfile(urlImage: string, size: string = IMAGE_PROXY_S, typeCut: TYPE_OF_CUT = TYPE_OF_CUT.INSIDE, quality: TYPE_OF_QUALITY = TYPE_OF_QUALITY.HIGH) : string {
  if(urlImage){
    let path = "/resize:fit" + size + "/" + this.urlSafeBase64("gs://" + GS_BUCKET + "/" + urlImage);
    return IMAGES_ENDPOINT + '/' + this.sign(IMG_SALT, path, IMG_KEY)+path;
  }else{
    let path = "/resize:fit" + size + "/" + this.urlSafeBase64("gs://" + GS_BUCKET + "/" + DEAFULT_IMAGE_PROFILE);
    return IMAGES_ENDPOINT + '/' + this.sign(IMG_SALT, path, IMG_KEY)+path;
  }
}

getSVG(urlImage: string, tint: string, size: string = IMAGE_M, typeCut: TYPE_OF_CUT = TYPE_OF_CUT.INSIDE, quality: TYPE_OF_QUALITY = TYPE_OF_QUALITY.HIGH) : string {
  return IMAGE_TRANSFORM_ENDPOINT + urlImage + '?tint=' + tint + '&' + size + '&format=png' + '&fit=' + this.getTypeOfCut(typeCut);
}

buildRoute(routes: string[]) {
  let route = '';
  routes.forEach(item => {
    if (item && item.trim()) {
      route += '/' + item.replace(/^\/+|\/+$/g, '');
    }
  });
  return this.localize.translateRoute(route);
}

LightenDarkenColor(col, amt) {
  let usePound = false;
  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = (num & 0x0000ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = ((num >> 8) & 0x00ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + ('00000' + (b | (g << 8) | (r << 16)).toString(16)).slice(-6);
}


checkTextGroupColor(color: string): string {
  let resp = 'white';
  const rgb = this.hexToRgb(color);
  if (rgb) {
    const luminance = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
    resp = luminance < 190 ? '#ffffff' : '#424242';
  }
  return resp;
}

checkTextGroupLink(color: string): string {
  let resp = color;
  const rgb = this.hexToRgb(color);
  if (rgb) {
    const luminance = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
    resp = luminance > 190 ? '#000000' : resp;
  }
  return resp;
}

checkWhiteLuminance(color: string): boolean {
  let resp = 'white';
  const rgb = this.hexToRgb(color);
  if (rgb) {
    const luminance = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
    return luminance > 190;
  }
  return true;

}

hexToRgb(hex?: string) {
  const color = hex || null;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

setPrimaryColor(color: string){

  //Si el color es muy claro ponemos un grey
  const luminance = this.checkWhiteLuminance(color);
  if(luminance) color = '#616161';

  const root = document.documentElement;
  root.style.setProperty('--color-mat-50', this.LightenDarkenColor(color, 55));
  root.style.setProperty('--color-mat-100', this.LightenDarkenColor(color, 45));
  root.style.setProperty('--color-mat-200', this.LightenDarkenColor(color, 35));
  root.style.setProperty('--color-mat-300', this.LightenDarkenColor(color, 20));
  root.style.setProperty('--color-mat-400', this.LightenDarkenColor(color, 10));
  root.style.setProperty('--color-mat-500', color);
  root.style.setProperty('--color-mat-600', this.LightenDarkenColor(color, -10));
  root.style.setProperty('--color-mat-700', this.LightenDarkenColor(color, -20));
  root.style.setProperty('--color-mat-800', this.LightenDarkenColor(color, -30));
  root.style.setProperty('--color-mat-900', this.LightenDarkenColor(color, -40));
  root.style.setProperty('--color-mat-A100', this.LightenDarkenColor(color, 70));
  root.style.setProperty('--color-mat-A200', this.LightenDarkenColor(color, 50));
  root.style.setProperty('--color-mat-A400', this.LightenDarkenColor(color, 30));
  root.style.setProperty('--color-mat-A700', this.LightenDarkenColor(color, 10));
}

setSecondaryColor(color: string){

  //Si el color es muy claro ponemos un grey
  const luminance = this.checkWhiteLuminance(color);
  if(luminance) color = '#616161';

  const root = document.documentElement;
  root.style.setProperty('--color-mat-accent-50', this.LightenDarkenColor(color, 55));
  root.style.setProperty('--color-mat-accent-100', this.LightenDarkenColor(color, 45));
  root.style.setProperty('--color-mat-accent-200', this.LightenDarkenColor(color, 35));
  root.style.setProperty('--color-mat-accent-300', this.LightenDarkenColor(color, 20));
  root.style.setProperty('--color-mat-accent-400', this.LightenDarkenColor(color, 10));
  root.style.setProperty('--color-mat-accent-500', color);
  root.style.setProperty('--color-mat-accent-600', this.LightenDarkenColor(color, -10));
  root.style.setProperty('--color-mat-accent-700', this.LightenDarkenColor(color, -20));
  root.style.setProperty('--color-mat-accent-800', this.LightenDarkenColor(color, -30));
  root.style.setProperty('--color-mat-accent-900', this.LightenDarkenColor(color, -40));
  root.style.setProperty('--color-mat-accent-A100', this.LightenDarkenColor(color, 70));
  root.style.setProperty('--color-mat-accent-A200', this.LightenDarkenColor(color, 50));
  root.style.setProperty('--color-mat-accent-A400', this.LightenDarkenColor(color, 30));
  root.style.setProperty('--color-mat-accent-A700', this.LightenDarkenColor(color, 10));
}

removeAllMetaTags(){
  this.metaService.removeTag("name='keywords'");
  this.metaService.removeTag("name='description'")
  this.metaService.removeTag("property='og:title'")
  this.metaService.removeTag("property='og:site_name'")
  this.metaService.removeTag("property='og:url'")
  this.metaService.removeTag("property='og:description'")
  this.metaService.removeTag("property='og:image'")
}

}
