<!--
          <div class="without_answer" *ngIf="poll">
            <mat-card>
              <mat-card-title>
                {{ poll.description }}
              </mat-card-title>
              <mat-card-content>
                <div class="option" *ngFor="let option of poll.options; let i = index"
                 style="">
                  {{option}}
                  <span></span>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div class="with_answer" *ngIf="poll">
            <mat-card>
              <mat-card-title>
                {{ poll.description }}
              </mat-card-title>
              <mat-card-content>
                <div class="option" *ngFor="let option of poll.options; let i = index"
                [ngClass]="{ 'option_selected': i === 1}"
                >
                  {{option}}
                  <span></span>
                </div>
              </mat-card-content>
            </mat-card>
          </div> -->

          <mat-card class="publication-poll" fxFlex="100" fxFlex.gt-sm="100">
            <mat-card-title class="publication-card"><mat-icon matTooltip="{{ 'publication.poll' | translate }}">equalizer</mat-icon>{{poll.description}}
            </mat-card-title>
            <mat-card-content>
              <div class="mini-survey-post-component">
                <mat-radio-group class="mini-survey-radio-group" [(ngModel)]="selectedValue">
                  <div class="mini-survey-radio-button-wrapper"
                    [ngClass]="{ 'selected' : selectedValue === idx, 'survey-hover': !poll_ended  && show_vote_button }"
                    *ngFor="let option of options; let idx = index;" (click)="wrapperSelectOption(idx)"
                    [ngStyle]="(!show_vote_button) ? getVotesGradient(idx) : ''">
                      <mat-radio-button  class="mini-survey-radio-button" [value]="idx" [disabled]="poll_ended || !show_vote_button">
                          {{option.option}}
                      </mat-radio-button>
                      <div *ngIf="option.votes > 0 && voted" class="mini-survey-votes-indicator" [style.color]="color">{{option.votes}}</div>
                  </div>
                </mat-radio-group>
                <button mat-flat-button mat-button-base color="primary" (click)="vote()" *ngIf="show_vote_button && !poll_ended">{{'publication.btn_vote'
                  | translate}}</button>
              </div>
              <mat-card-actions>
                <div class="m-y-16" style="color:rgba(0, 0, 0, 0.54)">
                  <span class="d-block" *ngIf="total_votes === 1">1 {{ 'publication.vote' | translate }}</span>
                  <span *ngIf="total_votes !== 1">{{total_votes}} {{ 'publication.votes' | translate }}</span>
                  <span *ngIf="poll.finishDate">- {{ 'publication.end_date' | translate }}: {{poll.finishDate | dateFormat:'lll'}}</span>
                </div>
  
              </mat-card-actions>
            </mat-card-content>

          </mat-card>


