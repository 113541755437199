import gql from 'graphql-tag';
import { loginResponse } from './entityMutations';

export const signupMutation = `
  mutation signup($token: String!) {
    signup(input: { token: $token }) {
      `+loginResponse+`
    }
  }
`;

export const updateCurrentUserMutation = `
  mutation updateCurrentUser($firstName: String!, $lastName: String, $status: String, $image: String) {
    updateCurrentUser(input: { firstName: $firstName, lastName: $lastName, status: $status, image: $image}) {
      `+loginResponse+`
    }
  }
`;

