import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { gtag, install } from 'ga-gtag';



declare const ga: any;
export interface AnalyticsEvent {
  type: 'PAGEVIEW' | 'EVENT';
  category?: string;
  action?: string;
  label: string;
}

@Injectable()
export class AnalyticsService {

  eventsQueue$ = new ReplaySubject<AnalyticsEvent>();

  constructor() {}

  public startTracking(code: string): void {
    if (code.startsWith("G-")) {
      install(code); 
      gtag('config', code, {
        send_page_view: false
      });
      this.subscribeToEventsA4();
    } else {
      ga('create', code, 'auto');
      this.subscribeToEvents();
    }
    
  }

  private subscribeToEvents(): void {
    this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
      if (e.type === 'PAGEVIEW') {
        ga('send', {
            hitType: 'pageview',
            page: e.label,
          });
      } else if (e.type === 'EVENT') {
        ga('send', {
            hitType: 'event',
            eventCategory: e.category,
            eventAction: e.action,
            eventLabel: e.label,
          });
      }
    });
  }

  private subscribeToEventsA4(): void {
    this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
      if (e.type === 'PAGEVIEW') {
        gtag('event', 'page_view', {
          page_location: e.label
        });
      } else if (e.type === 'EVENT') {
        gtag('event', e.label, {
          'time': Date.now(),
          });
      }
    });
  }

  public trackVirtualPageview(name: string): void {
    this.eventsQueue$.next({type: 'PAGEVIEW', label: name});
  }

  public trackEvent(category: string, action: string, label: string) {
    this.eventsQueue$.next({type: 'EVENT', category, action, label});
  }
}
