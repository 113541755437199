import { Entity } from './../../graphql/interfaces/entityModel';


import { SharedService } from './../../shared/services/shared.service';
import { DEAFULT_IMAGE_ENTITY } from './../../shared/constants';

import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EntityState } from 'app/redux/state/entity.state';

@Component({
  selector: 'app-branding',
  template: `
    <a *ngIf="entity$ | async; let entity" class="matero-branding" style="position: sticky;" href="/">
      <img appImgFallback  src="{{(entity.image) ? sharedService.getImage(entity.image) : sharedService.getImage(defaultImageEntity)}}" class="matero-branding-logo-expanded" alt="logo" />
      <span class="matero-branding-name">{{entity.name}}</span>
    </a>
  `,
})
export class BrandingComponent {

  @Select(EntityState.entity)
  entity$: Observable<Entity>;
  defaultImageEntity = DEAFULT_IMAGE_ENTITY;


  constructor(
    private sharedService: SharedService) {}
}
