<div class="matero-container-wrap" [ngClass]="{'matero-sidenav-collapsed': options.sidenavCollapsed && options.navPos!=='top',
                 'matero-navbar-side': options.navPos==='side',
                 'matero-navbar-top': options.navPos==='top',
                 'matero-header-above': options.headerPos==='above',
                 'matero-header-fixed': options.headerPos==='fixed',
                 'matero-header-white': options.theme==='light'}" [dir]="options.dir">
    <ng-progress></ng-progress>

    <!-- Header Above -->
    <app-header fxHide.lt-md #header class="matero-header" *ngIf="options.showHeader && options.headerPos==='above'" (toggleSidenav)="sidenav.toggle()" (toggleSidenavNotice)="sidenavNotice.toggle()" [showBranding]="true">
    </app-header>

    <mat-sidenav-container class="matero-container" autosize autoFocus>

        <mat-sidenav #sidenav class="matero-sidenav" [mode]="options.navPos==='top' ? 'over': isOver ? 'over' : 'side'" [opened]="options.navPos==='side' && options.sidenavOpened && !isOver" (openedChange)="sidenavOpenedChange($event)" (closedStart)="sidenavCloseStart()">

            <app-sidebar [showToggle]="!isOver" [showUser]="options.showUserPanel" [showHeader]="options.headerPos!=='above'" (toggleCollapsed)="toggleCollapsed()" [toggleChecked]="options.sidenavCollapsed">
            </app-sidebar>
        </mat-sidenav>

        <!--     <ng-container *ngIf="options.navPos==='side'">
      <mat-sidenav  #sidenav class="matero-sidenav"
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos==='side' && options.sidenavOpened && !isOver"
      (openedChange)="sidenavOpenedChange($event)"
      (closedStart)="sidenavCloseStart()">
<app-sidebar [showToggle]="!isOver"
        [showUser]="options.showUserPanel"
        [showHeader]="options.headerPos!=='above'"
        (toggleCollapsed)="toggleCollapsed()"
        [toggleChecked]="options.sidenavCollapsed">
</app-sidebar>
</mat-sidenav>
    </ng-container> -->





        <mat-sidenav #sidenavNotice position="end" mode="over">
            <app-sidebar-notice></app-sidebar-notice>
        </mat-sidenav>

        <mat-sidenav-content #content class="matero-content-wrap">
            <app-header #header class="matero-header" *ngIf="options.showHeader && options.headerPos!=='above'" [showToggle]="!options.sidenavCollapsed && options.navPos!=='top'" [showBranding]="options.navPos==='top'" (toggleSidenav)="sidenav.toggle()" (toggleSidenavNotice)="sidenavNotice.toggle()">
            </app-header>

            <app-topmenu fxHide.lt-md *ngIf="options.navPos==='top'"></app-topmenu>

            <div class="ylv-wrapper" *ngIf="entity$ | async; let entity" [ngClass]="{'mat-card-transparent': !entity?.web?.autoContent }">
                <mat-card style="padding: 0;">
                    <div *ngIf="path == sharedService.buildRoute(['home']) || path == sharedService.buildRoute(['/']) || path == '/'" fxLayout="row wrap">
                        <div class="m-b-16" fxFlex="100" fxFlex.gt-md="100">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                    <div *ngIf="path != sharedService.buildRoute(['home']) && path != sharedService.buildRoute(['/']) && path != '/'" class="matero-content" fxLayout="row wrap">
                        <div class="matero-col" fxFlex="100" fxFlex.gt-md="70">
                            <router-outlet></router-outlet>
                        </div>
                        <div class="matero-col ylv-widgets p-l-16 p-r-8" fxFlex="100" fxFlex.gt-sm="30">
                            <div class="matero-row" fxLayout="row wrap">
                                <div class="matero-col" fxFlex="100" fxFlex.gt-sm="100">
                                    <app-widgets></app-widgets>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>

            <div class="footer" *ngIf="entity$ | async; let entity">
                <mat-card-footer *ngIf="entity?.sponsor?.length > 0 && (entity.sponsor | filter : filterFunction).length > 0" class="sponsor-footer">
                    <app-sponsor></app-sponsor>
                </mat-card-footer>
                <div class="footer">
                    <div class="matero-content">
                        <div class="info-footer">
                            <img appImgFallback class="m-b-8" src="{{(entity.image) ? sharedService.getImage(entity.image) : sharedService.getImage(defaultImageEntity)}}" style="width:100%;max-width:100px;margin-right:0px;" alt="logo" />
                            <div class="matero-branding-name">{{ entity.name }}</div>
                            <div class="footer-links">
                                <a *ngIf="entity.web.street"><i class="fa fa-map-marker-alt"></i> {{ entity.web.street }} </a>
                                <a *ngIf="entity.web.tel1"><i class="fa fa-phone" style="width: 14px;"></i> {{ entity.web.tel1 }} <span *ngIf="entity.web.tel2"> - {{ entity.web.tel2 }}</span></a>
                                <a *ngIf="entity.web.fax"><i class="fa fa-fax"></i> {{ entity.web.fax }} </a>
                                <a *ngIf="entity.web.email"><i class="fa fa-envelope"></i> {{ entity.web.email }} </a>
                            </div>
                            <button *ngIf="entity?.sponsor?.length > 0 && (entity.sponsor | filter : filterFunction).length > 0" mat-button [routerLink]="sharedService.buildRoute(['sponsors'])">
                <span class="menu-name">{{ "menu.sponsors" | translate }}</span>
              </button>
                            <span class="pointer" fxHide.lt-md *ngIf="socials && socials.length > 0">
                <a *ngFor="let social of socials" mat-icon-button class="matero-toolbar-button mat-social pointer" fxHide.lt-sm target="new" href="{{ social.value }}">
                      <mat-icon class="pointer" *ngIf="social.icon.includes('svg_')" svgIcon="{{ social.icon }}"> </mat-icon>
                      <mat-icon class="pointer" *ngIf="!social.icon.includes('svg_')"> {{ social.icon }}</mat-icon>
                </a>
              </span>
                        </div>

                        <div class="footer-links">
                            <div>
                                <button mat-button [routerLink]="sharedService.buildRoute(['page', 'legal-warning-privacy-policy'])">
                  <span class="menu-name">{{ "page.legal-warning-privacy-policy.title" | translate }}</span>
                </button>
                                <button mat-button [routerLink]="sharedService.buildRoute(['page', 'cookies-policy'])">
                  <span class="menu-name">{{ "page.cookies-policy.title" | translate }}</span>
                </button>
                            </div>
                            <div style="display: flex; align-items: center;">
                                <span style="margin-right: 16px;margin-top:10px">Powered by</span>
                                <a href="https://connect.yulava.com/" target="new">
                                    <img src="assets/images/logo_yulava_white.svg">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <!-- Demo Purposes Only -->
    <!-- <app-customizer (optionsEvent)="receiveOptions($event)"></app-customizer> -->
</div>

