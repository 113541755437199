import { Injectable } from '@angular/core';
import { StorageService } from '@shared/services/secure-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private selector = 'globalLoader';
  private imageSelector = 'clientShield';
  private initSelector = 'initLoader';


  constructor(private storageService: StorageService) {}

  private getElement() {
    return document.getElementById(this.selector);
  }
  private getElementImage() {
    return document.getElementById(this.imageSelector);
  }
  private getElementInit() {
    return document.getElementById(this.initSelector);
  }

  hide(image: string) {
    const el = this.getElement();
    const im = this.getElementImage();
    const init = this.getElementInit();

    if(this.storageService.getItem('shield')){
      if (el) {
        el.addEventListener('transitionend', () => {
          el.className = 'global-loader-hidden';
        });

        if (!el.className.includes('global-loader-hidden')) {
          el.className += ' global-loader-fade-in';
        }
      }
      this.storageService.setItem('shield', image);
    }else{
      this.storageService.setItem('shield', image);
      if(im){
        im.setAttribute("src",image);
        im.style.display = "block";
        im.classList.remove("hide");
        init.classList.add("hide");
      }

      if (el) {
        el.addEventListener('transitionend', () => {
          el.className = 'global-loader-hidden';
        });

        if (!el.className.includes('global-loader-hidden')) {
          el.className += ' global-loader-fade-in';
        }
      }

    }


  }

  suscriptionExpire(image: string) {
    const el = this.getElement();
    const im = this.getElementImage();
    const init = this.getElementInit();

    if(this.storageService.getItem('shield')){
      if (el) {
        init.innerHTML = "<p style='font-weight: 700;color: #414141;font-size: 1.2rem;font-family: var(--client-font-family)!important;'>Esta página web no está activa</p> <br>  <br> <p style='font-weight: 700;color: #414141;font-size: 1rem;font-family: var(--client-font-family)!important;'> Consigue una app/web para tu club con<p>  <br> <a href='https://www.yulava.com/' target='new'><img width='200px' src='/assets/images/logo_yulava_color.svg'></a>";
        init.className = 'lds-spinner-expired';
      }
      this.storageService.setItem('shield', image);
    }else{
      this.storageService.setItem('shield', image);
      init.innerHTML = "<p style='font-weight: 700;color: #414141;font-size: 1.2rem;font-family: var(--client-font-family)!important;'>Esta página web no está activa</p> <br>  <br> <p style='font-weight: 700;color: #414141;font-size: 1rem;font-family: var(--client-font-family)!important;'> Consigue una app/web para tu club con<p>  <br> <a href='https://www.yulava.com/' target='new'><img width='200px' src='/assets/images/logo_yulava_color.svg'></a>";
      init.className = 'lds-spinner-expired';

    }


  }
}
