<mat-form-field style="width:100%">
  <mat-label>{{ field.templateOptions.label }}</mat-label>
  <input matInput [ngxMatDatetimePicker]="datetimepicker"
    [min]="minDate" [max]="maxDate" [disabled]="disabled" [formControl]="formControl">
  <mat-datepicker-toggle matSuffix [for]="datetimepicker"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker #datetimepicker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
    [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
    [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
    <!-- Custom icon or text of Apply icon -->
    <ng-template>
      <!-- <mat-icon>star</mat-icon> -->
      <span>OK</span>
    </ng-template>
  </ngx-mat-datetime-picker>
</mat-form-field>

