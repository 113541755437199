import { competitionResponse } from './../responses/responses';
import gql from 'graphql-tag';
import { memberResponse, teamResponse } from '../responses/responses';

export const publicCompetitionsQuery =
`
  query($entityId:String!, $seasonId: String) {
  publicCompetitions(entityId: $entityId seasonId: $seasonId)
    {
      id
      entityId
      typeId
      seasonId
      name
      externalLink
      categoryId
      sportId
      icon
      favourite
    }
  }
`;


export const teamsParticipatingCompetitionQuery =
`
  query($entityId:String!, $competitionId: String!) {
  teamsParticipatingCompetition(entityId: $entityId, competitionId: $competitionId)
    {
      `+teamResponse+`
    }
  }
`;

export const extraCompetitionQuery =
`
  query($entityId:String!, $competitionId: String!) {
  publicExtraCompetition(entityId: $entityId, competitionId: $competitionId) {
    id
    entityId
    typeId
    seasonId
    name
    externalLink
    categoryId
    sportId
    icon
    favourite
    private
    multimedia{
      id
      name
      public
      active
      anyone
      items {
        id
        url
        userId
      }
    }
    classification{
      teamId
      position
      points
      pj
      pg
      pe
      pp
      gf
      gc
      dg
      coefficient
    }
    matchDays {
        id
        detail
        startDate
        finishDate
        pairings {
          id
          localId
          visitorId
        }
      }
      matches {
        id
        localId
        visitorId
        statusId
        typeId
        date
        localResult
        visitorResult
        matchDayId
        pairingId
      }
    }
  }
`;

export const membersParticipatingCompetitionQuery =
`
  query($entityId:String!, $competitionId: String!) {
  membersParticipatingCompetition(entityId: $entityId, competitionId: $competitionId)
    {
      `+memberResponse+`
    }
  }
`;


export const externalTeamsParticipatingCompetitionQuery =
`
  query($entityId:String!, $competitionId: String!) {
  externalTeamsParticipatingCompetition(entityId: $entityId, competitionId: $competitionId)
    {
      id
      name
      image
      team {
        id
        entityId
        name
        categoryId
        sportId
        shield
        favourite
      }
    }
  }
`;

export const generatePairingsQuery =
`
  query($entityId:String!, $competitionId: String!, $mode: String!, $pairing: String!) {
  generatePairings(entityId: $entityId, competitionId: $competitionId, mode: $mode, pairing: $pairing)
    {
      id
      detail
      startDate
      finishDate
      pairings {
        id
        localId
        visitorId
        externalReference
      }
    }
  }
`;

export const generateExternalPairingsQuery =
`
  query($entityId:String!, $competitionId: String!) {
  generateExternalPairings(entityId: $entityId, competitionId: $competitionId)
    {
      id
      detail
      startDate
      finishDate
      pairings {
        id
        localId
        visitorId
        externalReference
      }
    }
  }
`;
