<ul navAccordion class="matero-sidemenu level-0">
  <li navAccordionLink class="matero-sidemenu-item" routerLinkActive="active"
      *ngFor="let menuItem of menus | async"
      [group]="menuItem.route">
    <a class="matero-sidemenu-link" [routerLink]="sharedService.buildRoute([menuItem.route])"
       *ngIf="menuItem.type === 'link' && (menuItem.visible == undefined || menuItem.visible)"
       matRipple [matRippleDisabled]="!ripple">
      <!-- <mat-icon class="menu-icon"><i class="{{menuItem.icon}}"></i></mat-icon> -->
      <span class="menu-name">{{menuItem.name | translate}}</span>
      <span class="menu-label bg-{{menuItem.label.color}}" *ngIf="menuItem.label">
        {{menuItem.label.value}}
      </span>
      <span fxFlex></span>
      <span class="menu-badge bg-{{menuItem.badge.color}}" *ngIf="menuItem.badge">
        {{menuItem.badge.value}}
      </span>
    </a>
    <a class="matero-sidemenu-link" [href]="menuItem.route" *ngIf="menuItem.type === 'extLink'"
       matRipple [matRippleDisabled]="!ripple">
      <!-- <mat-icon class="menu-icon"><i class="{{menuItem.icon}}"></i></mat-icon> -->
      <span class="menu-name">{{menuItem.name | translate}}</span>
      <span class="menu-label bg-{{menuItem.label.color}}" *ngIf="menuItem.label">
        {{menuItem.label.value}}
      </span>
      <span fxFlex></span>
      <span class="menu-badge bg-{{menuItem.badge.color}}" *ngIf="menuItem.badge">
        {{menuItem.badge.value}}
      </span>
      <mat-icon class="menu-caret">launch</mat-icon>
    </a>
    <a class="matero-sidemenu-link" [href]="menuItem.route" target="_blank"
       *ngIf="menuItem.type === 'extTabLink'"
       matRipple [matRippleDisabled]="!ripple">
      <!-- <mat-icon class="menu-icon"><i class="{{menuItem.icon}}"></i></mat-icon> -->
      <span class="menu-name">{{menuItem.name | translate}}</span>
      <span class="menu-label bg-{{menuItem.label.color}}" *ngIf="menuItem.label">
        {{menuItem.label.value}}
      </span>
      <span fxFlex></span>
      <span class="menu-badge bg-{{menuItem.badge.color}}" *ngIf="menuItem.badge">
        {{menuItem.badge.value}}
      </span>
      <mat-icon class="menu-caret">launch</mat-icon>
    </a>
    <!-- level 1 -->
    <a navAccordionToggle class="matero-sidemenu-toggle" href="javascript:void(0);"
       *ngIf="menuItem.type === 'sub'"
       matRipple [matRippleDisabled]="!ripple">
      <!-- <mat-icon class="menu-icon"><i class="{{menuItem.icon}}"></i></mat-icon> -->
      <span class="menu-name">{{menuItem.name | translate}}</span>
      <span class="menu-label bg-{{menuItem.label.color}}" *ngIf="menuItem.label">
        {{menuItem.label.value}}
      </span>
      <span fxFlex></span>
      <span class="menu-badge bg-{{menuItem.badge.color}}" *ngIf="menuItem.badge">
        {{menuItem.badge.value}}
      </span>
      <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </a>
    <ul navAccordion class="matero-sidemenu submenu level-1" *ngIf="menuItem.type === 'sub'">
      <li navAccordionLink class="matero-sidemenu-item" routerLinkActive="active"
          *ngFor="let childLvl1 of menuItem.children"
          [group]="childLvl1.route">
        <a class="matero-sidemenu-link"
           [routerLink]="sharedService.buildRoute([menuItem.route, childLvl1.route])"
           *ngIf="childLvl1.type === 'link'"
           matRipple [matRippleDisabled]="!ripple">
          <span class="menu-name">{{childLvl1.name | translate}}</span>
        </a>
        <a class="matero-sidemenu-link" [href]="childLvl1.route"
           *ngIf="childLvl1.type === 'extLink'"
           matRipple [matRippleDisabled]="!ripple">
          <span class="menu-name">{{childLvl1.name | translate}}</span>
          <span fxFlex></span>
          <mat-icon class="menu-caret">launch</mat-icon>
        </a>
        <a class="matero-sidemenu-link" [href]="childLvl1.route" target="_blank"
           *ngIf="childLvl1.type === 'extTabLink'"
           matRipple [matRippleDisabled]="!ripple">
          <span class="menu-name">{{childLvl1.name | translate}}</span>
          <span fxFlex></span>
          <mat-icon class="menu-caret">launch</mat-icon>
        </a>
        <!-- level 2 -->
        <a navAccordionToggle class="matero-sidemenu-toggle" href="javascript:void(0);"
           *ngIf="childLvl1.type === 'sub'"
           matRipple [matRippleDisabled]="!ripple">
          <span class="menu-name">{{childLvl1.name | translate}}</span>
          <span fxFlex></span>
          <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
        </a>
        <ul class="matero-sidemenu submenu level-2" *ngIf="childLvl1.type === 'sub'">
          <li class="matero-sidemenu-item" routerLinkActive="active"
              *ngFor="let childLvl2 of childLvl1.children">
            <a class="matero-sidemenu-link"
               [routerLink]="sharedService.buildRoute([menuItem.route, childLvl1.route, childLvl2.route])"
               *ngIf="childLvl2.type === 'link'"
               matRipple [matRippleDisabled]="!ripple">
              <span class="menu-name">{{childLvl2.name | translate}}</span>
            </a>
            <a class="matero-sidemenu-link" [href]="childLvl2.route"
               *ngIf="childLvl2.type === 'extLink'"
               matRipple [matRippleDisabled]="!ripple">
              <span class="menu-name">{{childLvl2.name | translate}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">launch</mat-icon>
            </a>
            <a class="matero-sidemenu-link" [href]="childLvl2.route" target="_blank"
               *ngIf="childLvl2.type === 'extTabLink'"
               matRipple [matRippleDisabled]="!ripple">
              <span class="menu-name">{{childLvl2.name | translate}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">launch</mat-icon>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
<span class="pointer m-t-16" *ngIf="socials && socials.length > 0" style="white-space: nowrap; display: block; text-align: center;">
  <a *ngFor="let social of socials" mat-icon-button class="matero-toolbar-button mat-social pointer" target="new" href="{{ social.value }}">
        <mat-icon class="pointer" *ngIf="social.icon.includes('svg_')" svgIcon="{{ social.icon }}"> </mat-icon>
        <mat-icon class="pointer" *ngIf="!social.icon.includes('svg_')"> {{ social.icon }}</mat-icon>
  </a>
</span>
