import { Component, OnInit} from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { TypeService } from 'app/graphql/services/type.service';
import { SharedService } from '@shared/services/shared.service';

@Component({
  selector: 'app-widgets',
  styleUrls: ['./widgets.component.scss'],
  templateUrl: './widgets.component.html',
})

export class WidgetsComponent implements OnInit {

  widgetNews = true;
  matchesNews = true;
  widgetBanners = true;
  widgetYulava = true;


  constructor(
    private router: Router,
    public sharedService: SharedService,
    public typeService: TypeService, 
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.checkUrl(this.router.url);
  
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.checkUrl(evt.url);
      }
    });
  }

  checkUrl(url:string){
    this.widgetNews = url.includes(this.translateService.instant('ROUTES.news')) ? false : true;
    this.matchesNews = url.includes(this.translateService.instant('ROUTES.matches')) ? false : true;
  }

}






