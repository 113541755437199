import { Component, OnInit} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SharedService } from '@shared/services/shared.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
 selector: 'formly-custom-toggle-input',
 templateUrl: './custom-toggle-input.html',
 styleUrls: ['./custom-toggle-input.scss'],
})
export class FormlyCustomToggleInput extends FieldType implements OnInit {

  isChecked = false;

  constructor(
    public sharedService: SharedService) {
    super();

  }

  ngOnInit(){
    this.isChecked = this.formControl.value;
  }

  handleChange(event: MatSlideToggleChange) {
    this.formControl.setValue(event.checked);
  }

}
