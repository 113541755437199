import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '@shared/services/shared.service';
import { IMAGE_PROXY_M } from '@shared/constants';

@Component({
 selector: 'formly-cropper-image-input',
 templateUrl: './cropper-image-input.html',
 styleUrls: ['./cropper-image-input.scss'],
})
export class FormlyCropperImageInput extends FieldType {
  //Constantes
  imageSize = IMAGE_PROXY_M;

  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService) {
    super();
  }

  processFile(event){
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    /* reader.onload = () => {
      const dialogRef = this.dialog.open(DialogCropperComponent,{
        data: {
          image: reader.result,
          title: this.field.templateOptions.label,
          format: this.field.templateOptions.attributes.format,
          ratio: this.field.templateOptions.attributes.ratio,
        }
        });

      dialogRef.afterClosed().subscribe(result => {
        this.formControl.setValue(result.image);
      });

    } */
  };
}
