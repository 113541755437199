import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';

@Component({
 selector: 'formly-date-input',
 templateUrl: './date-input.html',
 styleUrls: ['./date-input.scss'],
})

export class FormlyDateInput extends FieldType {

  @ViewChild(MatInput) formFieldControl: MatInput;

}
