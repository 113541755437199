import { SharedService } from './../../shared/services/shared.service';
import { Entity } from './../../graphql/interfaces/entityModel';
import { DEAFULT_IMAGE_ENTITY, TYPE_MENU_TRANSPARENT } from './../../shared/constants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { MenuService } from '@core';
import { Observable } from 'rxjs';
import { EntityState } from 'app/redux/state/entity.state';
import { Select } from '@ngxs/store';
import { TypeService } from 'app/graphql/services/type.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  animations: [
    trigger('sticky', [
      state(
        'open',
        style({
          height: '85px',
          marginTop: '0px',
        })
      ),
      state(
        'closed',
        style({
          height: '40px',
          marginTop: '50px',
        })
      ),
      transition('open => closed', [animate('0.2s')]),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
})
export class TopmenuComponent implements OnInit {
  menus = this.menuService.getAll();
  isOpen = true;

  @Select(EntityState.entity)
  entity$: Observable<Entity>;
  socials: any[];
  defaultImageEntity = DEAFULT_IMAGE_ENTITY;
  menuTransparent = false;

  constructor(
    public typeService: TypeService,
    public menuService: MenuService,
    private sharedService: SharedService,
    private localize: LocalizeRouterService
  ) {}

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);

    this.entity$.subscribe(entity => {
      if (entity) {
        if(entity.web?.typeMenu && entity.web?.typeMenu === TYPE_MENU_TRANSPARENT){
          this.menuTransparent = true;
        }
        this.socials = entity.social.map(obj => {
            const type = this.typeService.getType(obj.typeId);
            return { ...obj, icon: this.typeService.getIcon(type.langKey), type: type.langKey };
          })
          .filter(item => !['type.social_email', 'type.social_web'].includes(item.type));
      }
    });
  }

  scroll = (event): void => {
    if (event.target.scrollTop > 50) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }
  };

  // Delete empty values and rebuild route
  buildRoute(routes: string[]) {
    let route = '';
    routes.forEach(item => {
      if (item && item.trim()) {
        route += '/' + item.replace(/^\/+|\/+$/g, '');
      }
    });
    return this.localize.translateRoute(route);
  }
}
