import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetMorePublicPublications, GetPublicPublications, GetPublicPublicationsWidget } from 'app/redux/actions/publication.actions';
import { Observable } from 'rxjs';
import { DEAFULT_IMAGE_ENTITY, DEAFULT_IMAGE_PUBLICATION, DEAFULT_SHIELD_TEAM, TYPE_COMPETITION_TEAM } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityState } from 'app/redux/state/entity.state';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeService } from 'app/graphql/services/type.service';
import { Match, Publication } from 'app/graphql/types';
import { PublicationState } from 'app/redux/state/publication.state';
import { SharedService } from '@shared/services/shared.service';
import { GetPublicMatchesWidget } from 'app/redux/actions/matches.actions';
import { MatchState } from 'app/redux/state/matches.state';
@Component({
  selector: 'app-widget-matches',
  styleUrls: ['./matches.component.scss'],
  templateUrl: './matches.component.html',
})
export class WidgetMatchesComponent implements OnInit/* , AfterViewInit */  {

  @Select(MatchState.nextMatches)
  nextMatches$: Observable<[Match]>;

  @Select(MatchState.lastMatches)
  lastMatches$: Observable<[Match]>;

  defaultImagePublication = DEAFULT_IMAGE_PUBLICATION;
  defaultShieldTeam = DEAFULT_SHIELD_TEAM;

  TYPE_COMPETITION_TEAM = TYPE_COMPETITION_TEAM;

  entity: Entity = this.store.snapshot().Entity.entity;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private http: HttpClient, 
    public typeService: TypeService, 
    private translateService: TranslateService, 
    private sanitizer: DomSanitizer, 
    private titleService: Title, 
    private metaService: Meta
    ) {}

  ngOnInit() {
    this.store.dispatch(new GetPublicMatchesWidget());
  }

}






