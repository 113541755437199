import gql from 'graphql-tag';
import { teamDetailResponse, teamResponse } from '../responses/responses';

export const publicTeamsQuery = `
query ($entityId: String!, $seasonId:String) {
  publicTeams(entityId: $entityId, seasonId: $seasonId) {
    `+teamResponse+`
  }
}
`;

export const publicTeamQuery = `
query ($entityId: String!, $teamId:String!) {
  publicTeam(entityId: $entityId, teamId: $teamId) {
    `+teamDetailResponse+`
  }
}
`;

export const publicListTeamMembersQuery = `
query ($entityId: String!, $teamId:String!, $teamTemplateId:String!, $isPlayers: Boolean, $isCoachingStaff: Boolean, $isCollaborators: Boolean) {
  publicListTeamMembers(entityId: $entityId, teamId: $teamId, teamTemplateId: $teamTemplateId, isPlayers: $isPlayers, isCoachingStaff: $isCoachingStaff, isCollaborators: $isCollaborators) {
    players{
      id
      member{
        id
        entityId
        image
        firstName
        lastName
      }
      positionId
      dorsal
    }
    coachingStaff{
      id
      member{
        id
        entityId
        image
        firstName
        lastName
      }
      positionId
      dorsal
    }
    collaborators{
      id
      member{
        id
        entityId
        image
        firstName
        lastName
      }
      positionId
      dorsal
    }
  }
}
`;

