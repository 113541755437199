import { PublicationService } from '../../../../graphql/services/publication.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DEAFULT_IMAGE_PUBLICATION, DEAFULT_SHIELD_COMPETITION, DEAFULT_SHIELD_TEAM, TYPE_COMPETITION_TEAM } from '../../../constants';
import { SharedService } from '../../../services/shared.service';
import { PublicationState } from '../../../../redux/state/publication.state';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetMorePublicPublications, GetPublicPublications } from 'app/redux/actions/publication.actions';

import { Observable } from 'rxjs';
import { DEAFULT_IMAGE_ENTITY } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityState } from 'app/redux/state/entity.state';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypeService } from 'app/graphql/services/type.service';
import { Publication } from 'app/graphql/types';

@UntilDestroy()
@Component({
  selector: 'app-publication-tile',
  styleUrls: ['./publication-tile.component.scss'],
  templateUrl: './publication-tile.component.html',
})
export class PublicationTileComponent implements OnInit {

  @Input() publication: Publication;
  @Input() first: Boolean;
  @Input() widget: Boolean;

  body = null;
  defaultImagePublication = DEAFULT_IMAGE_PUBLICATION;
  defaultShieldCompetition = DEAFULT_SHIELD_COMPETITION;
  TYPE_COMPETITION_TEAM = TYPE_COMPETITION_TEAM;
  defaultShieldTeam = DEAFULT_SHIELD_TEAM;
  entity: Entity = this.store.snapshot().Entity.entity;

  options: any[] = [];

  constructor(private store: Store,public sharedService: SharedService,public translate: TranslateService,public typeService: TypeService,private publicationService: PublicationService,  private http: HttpClient, private titleService: Title, private metaService: Meta, private sanitizer: DomSanitizer, private route: ActivatedRoute, private translateService: TranslateService) {
  }

  ngOnInit() {

  }



}






