import { Entity } from './../../graphql/interfaces/entityModel';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Page } from 'app/graphql/types';
import { MODULE_COMPETITION, MODULE_TEAM } from '@shared/constants';

export interface MenuTag {
  color: string; // Background Color
  value: string;
}

export interface MenuChildrenItem {
  route: string;
  name: string;
  type: 'link' | 'sub' | 'extLink' | 'extTabLink';
  children?: MenuChildrenItem[];
}

export interface Menu {
  route: string;
  name: string;
  type: 'link' | 'sub' | 'extLink' | 'extTabLink';
  icon: string;
  visible?: boolean;
  label?: MenuTag;
  badge?: MenuTag;
  children?: MenuChildrenItem[];
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menu: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);
  constructor(private localize: LocalizeRouterService){}
  getAll(): Observable<Menu[]> {
    return this.menu.asObservable();
  }

  set(entity: Entity): Observable<Menu[]> {

    let menu: Menu[] = [
      {
        "route": "home",
        "name": "home",
        "type": "link",
        "icon": "fa fa-newspaper",
      },
      {
          "route": "news",
          "name": "news",
          "type": "link",
          "icon": "fa fa-newspaper",
      }
    ];

    if(entity.modules.filter(item => item.moduleId === MODULE_TEAM && item.active == true).length > 0){
      menu.push({
        "route": "teams",
        "name": "teams",
        "type": "link",
        "icon": "fa fa-user-friends",
      });
    }

    if(entity.modules.filter(item => item.moduleId === MODULE_COMPETITION && item.active == true).length > 0){
      menu.push({
        "route": "competitions",
        "name": "competitions",
        "type": "link",
        "icon": "fa fa-trophy",
      },
      {
        "route": "matches",
        "name": "matches",
        "type": "link",
        "icon": "fa fa-compress-alt",
      });
    }


    menu.push({
      "route": "multimedia",
      "name": "multimedia",
      "type": "link",
      "icon": "fa fa-photo-video",
    },
    {
      "route": "sponsors",
      "name": "sponsors",
      "type": "link",
      "icon": "fa fa-photo-video",
      "visible": false
    });

    /* 
      {
        "route": "teams",
        "name": "teams",
        "type": "link",
        "icon": "fa fa-user-friends",
      },
      {
        "route": "competitions",
        "name": "competitions",
        "type": "link",
        "icon": "fa fa-trophy",
      },
      {
        "route": "matches",
        "name": "matches",
        "type": "link",
        "icon": "fa fa-compress-alt",
      }, */
      
    this.recursMenuForTranslation(menu, 'menu');

    //montamos el array de páginas
    entity.pages.forEach(pageRoot => {
      let childs: MenuChildrenItem[] = [];
      if(pageRoot.parentId == null || pageRoot.parentId == undefined){
        entity.pages.forEach(page => {
          if(page.parentId == pageRoot.id){
            childs.push({
              route: 'page/'+page.urlTitle,
              name: page.title,
              type: 'link'
            });
          }
        });
        if(childs.length > 0){
          menu.push({
            name: pageRoot.title,
            route: '/',
            icon: "fa fa-photo-video",
            type: 'sub',
            children: childs
          });
        } else {
          menu.push({
            name: pageRoot.title,
            route: 'page/'+pageRoot.urlTitle,
            icon: "fa fa-photo-video",
            type: 'link',
          });
        }

      }

    });

    this.menu.next(menu);
    return this.menu.asObservable();
  }

  add(menu: Menu) {
    const tmpMenu = this.menu.value;
    tmpMenu.push(menu);
    this.menu.next(tmpMenu);
  }

  reset() {
    this.menu.next([]);
  }

  getMenuItemName(routeArr: string[]): string {
    return this.getMenuLevel(routeArr)[routeArr.length - 1];
  }

  // TODO:
  getMenuLevel(routeArr: string[]): any[] {
    const tmpArr = [];
    this.menu.value.forEach(item => {
      if (this.localize.translateRoute(item.route) === routeArr[1]) {
        tmpArr.push({name:item.name, route:'/'+routeArr[1]});
        // Level1
        if (item.children && item.children.length) {
          item.children.forEach(itemlvl1 => {
            if (routeArr[2] && this.localize.translateRoute(itemlvl1.route) === routeArr[2]) {
              tmpArr.push({name:itemlvl1.name, route:'/'+routeArr[1]+'/'+routeArr[2]});
              // Level2
              if (itemlvl1.children && itemlvl1.children.length) {
                itemlvl1.children.forEach(itemlvl2 => {
                  if (routeArr[3] && this.localize.translateRoute(itemlvl2.route) === routeArr[3]) {
                    tmpArr.push({name:itemlvl2.name, route:'/'+routeArr[1]+'/'+routeArr[2]+'/'+routeArr[3]});
                  }
                });
              }
            } else if (routeArr[2]) {
              // Level2
              if (itemlvl1.children && itemlvl1.children.length) {
                itemlvl1.children.forEach(itemlvl2 => {
                  if (this.localize.translateRoute(itemlvl2.route) === routeArr[2]) {
                    tmpArr.push(itemlvl1.name, itemlvl2.name);
                    tmpArr.push({name:itemlvl1.name, route:'/'+routeArr[1]+'/'+routeArr[2]});
                  }
                });
              }
            }
          });
        }
      }
    });
    return tmpArr;
  }

  recursMenuForTranslation(menu: Menu[] | MenuChildrenItem[], namespace: string) {
    menu.forEach(menuItem => {
      menuItem.name = `${namespace}.${menuItem.name}`;
      if (menuItem.children && menuItem.children.length > 0) {
        this.recursMenuForTranslation(menuItem.children, menuItem.name);
      }
    });
  }
}
