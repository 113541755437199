import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';

@Component({
 selector: 'formly-file-input',
 templateUrl: './file-input.html',
 styleUrls: ['./file-input.scss'],
})

export class FormlyFileInput extends FieldType {

  @ViewChild(MatInput) formFieldControl: MatInput;

}
