import gql from 'graphql-tag';
import { publicationDetailResponse, publicationResponse } from '../responses/responses';

export const publicPublicationsQuery = `
query ($entityId: String!, $lastId:String, $limit:Int!, $teamId:String, $tags:String, $competitionId:String, $matchId:String, $seasonId:String, $showAll: Boolean) {
  publicPublications(input:{entityId: $entityId, teamId: $teamId,competitionId: $competitionId,matchId: $matchId,tags: $tags, seasonId: $seasonId, showAll: $showAll}, pagination: {lastId: $lastId, limit: $limit}) {
    `+publicationResponse+`
  }
}
`;

export const publicPublicationQuery = `
query ($entityId: String!, $urlTitle:String) {
  publicPublication(entityId:$entityId , urlTitle: $urlTitle) {
    `+publicationDetailResponse+`
  }
}
`;
