import { competitionResponse, matchResponse } from '../responses/responses';

export const competitionsQuery =
`
  query($entityId:String!, $search:String, $seasonId: String, $listSelectedCategory: [String], $listSelectedSport:[String], $listSelectedType:[String], $limit: Int!, $page: Int) {
  competitions(entityId: $entityId, search: $search, seasonId: $seasonId, listSelectedCategory: $listSelectedCategory, listSelectedSport: $listSelectedSport, listSelectedType: $listSelectedType, pagination: {limit: $limit, page: $page})
    {
      `+competitionResponse+`
    }
  }
`;

export const matchesQuery =
`
  query($entityId:String!, $fromDate: Date, $toDate: Date, $teamId: String, $seasonId: String) {
  matches(entityId: $entityId, fromDate: $fromDate, toDate: $toDate, teamId: $teamId, seasonId: $seasonId)
    {
      id
      localId
      visitorId
      statusId
      date
      localResult
      url
      visitorResult
      matchDayId
      typeId
      competition {
        id
        name
        entityId
        typeId
        seasonId
        categoryId
        sportId
        icon
        matchDays {
          id
          startDate
          finishDate
          detail
        }
      }
      localTeam {
        id
        name
        shield
        categoryId
        sportId
      }
      visitorTeam {
        id
        name
        shield
        categoryId
        sportId
      }
      localMember {
        id
        firstName
        lastName
        image
      }
      visitorMember {
        id
        firstName
        lastName
        image
      }
    }
  }
`;

export const matchesWithMultimediaQuery =
`
  query($entityId:String!, $fromDate: Date, $toDate: Date, $teamId: String, $seasonId: String) {
  matchesWithMultimedia(entityId: $entityId, fromDate: $fromDate, toDate: $toDate, teamId: $teamId, seasonId: $seasonId)
    {
      id
      localId
      visitorId
      statusId
      date
      localResult
      url
      visitorResult
      matchDayId
      typeId
      competition {
        id
        name
        entityId
        typeId
        seasonId
        categoryId
        sportId
        icon
      }
      multimedia{
        id
        name
        public
        active
        anyone
        matchId
        competitionId
        teamTemplateId
        items {
          id
          url
          userId
        }
      }
      localTeam {
        id
        name
        shield
        categoryId
        sportId
      }
      visitorTeam {
        id
        name
        shield
        categoryId
        sportId
      }
      localMember {
        id
        firstName
        lastName
        image
      }
      visitorMember {
        id
        firstName
        lastName
        image
      }
    }
  }
`;

export const matchQuery =
`
  query($entityId:String!, $matchId: String!) {
    match(entityId: $entityId, matchId: $matchId)
    {
      `+matchResponse+`
    }
  }
`;
