import { Component, OnInit} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SharedService } from '@shared/services/shared.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
 selector: 'formly-checkbox-html-input',
 templateUrl: './checkbox-html-input.html',
 styleUrls: ['./checkbox-html-input.scss'],
})
export class FormlyCheckboxHtmlInput extends FieldType implements OnInit {

  /* isChecked = false; */

  constructor(
    public sharedService: SharedService) {
    super();

  }

  ngOnInit(){
    /* this.isChecked = this.formControl.value; */
  }

  /* handleChange(event: MatSlideToggleChange) {
    this.formControl.setValue(event.checked);
  } */

}
