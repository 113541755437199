<nav aria-label="breadcrumb" *ngIf="nav.length > 1">
  <ol class="matero-breadcrumb">
    <li class="matero-breadcrumb-item"
        *ngFor="let navlink of nav; trackBy: trackByNavlink; first as isFirst;">
      <a href="#" [routerLink]="'/'" class="link" *ngIf="isFirst">{{ ((navlink.name | translate).length>40)? ((navlink.name | translate) | slice:0:40)+'..':((navlink.name | translate)) }}</a>
      <ng-container *ngIf="!isFirst">
        <mat-icon class="chevron" style="color: rgba(0, 0, 0, 0.54);">chevron_right</mat-icon>
        <a *ngIf="navlink.route" href="#" [routerLink]="'/'+navlink.route">
          <span class="link">{{ ((navlink.name | translate).length>40)? ((navlink.name | translate) | slice:0:40)+'..':((navlink.name | translate)) }}</span>
        </a>
        <span *ngIf="!navlink.route" class="link">{{ ((navlink.name | translate).length>40)? ((navlink.name | translate) | slice:0:40)+'..':((navlink.name | translate)) }}</span>
      </ng-container>
    </li>
  </ol>
</nav>
