<div *ngIf="!inPublication && !widget" class="matero-row m-l-0 m-r-0" fxLayout="row wrap">
    <mat-card class="p-0" [ngClass]="{'pointer match-tile-widget': pointer===undefined || pointer===true}" style="width:100%;border: 1px solid #e0e0e0;">
        <div class="matero-row m-l-0 m-r-0" fxLayout="row wrap">
            <div *ngIf="match.competition" class="matero-col match-tile " fxFlex="100" fxFlex.gt-sm="25" style="background-color: #f1f1f1;height: auto;display: flex;
        align-items: center;">
                <div style="width: 100%;line-height: 1.2rem;padding:8px;text-align: center;margin-bottom: 5px;">
                    <div style="font-weight:600;font-size: 1.1rem;margin-bottom:5px;">{{ (typeService.getType(match.competition.categoryId).langKey | translate) }}</div>
                    <div class="matero-row m-l-0 m-r-0" fxLayout="row wrap" style="align-items: center;">
                        <div fxFlex="33" fxFlex.gt-sm="100">{{ match.competition.name }}</div>
                        <div fxFlex="33" fxFlex.gt-sm="100">{{ (typeService.getType(match.competition.sportId).langKey | translate)}}</div>
                        <div *ngIf="match.matchDayId" fxFlex="33" fxFlex.gt-sm="100">{{ getMatchDay(match.matchDayId) }}</div>
                    </div>
                </div>
            </div>
            <div class="matero-col match-tile" fxFlex="100" fxFlex.gt-sm="{{match.competition ? '75' : '100'}}">
                <div class="date m-t-16">
                    <ng-container *ngIf="!hideDate">
                        <span class="date" *ngIf="!onlyTime">
                {{ match.date | dateFormat:'LLLL' }}
              </span>
                        <span class="date" *ngIf="onlyTime && match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED">
                {{ 'general.match_time' | translate }}: {{ match.date | dateFormat:'LT' }}
              </span>
                    </ng-container>
                </div>
                <div class="matero-row content p-16 p-t-0" fxLayout="row wrap">
                    <div class="matero-col local_team" *ngIf="localTeam" fxFlex="38" style="text-align: center; font-weight: 400;">
                        <div class="div_shield">
                            <img appImgFallback class="shield" style="max-width: 75px;padding:0%" src="{{(localTeam.shield) ? sharedService.getImage(localTeam.shield) : sharedService.getImage(defaultShieldTeam)}}">
                        </div>
                        {{ localTeam.name }}
                    </div>
                    <div class="matero-col local_team" *ngIf="localMember" fxFlex="38" style="text-align: center; font-weight: 400;">
                        <div class="matero-col div_shield">
                            <img appImgFallback class="shield" style="max-width: 75px;padding:0%;border-radius: 50%;" src="{{(localMember.image) ? sharedService.getImage(localMember.image) : sharedService.getImage(defaultImageProfile)}}">
                        </div>
                        {{ localMember.firstName }} {{ localMember.lastName }}
                    </div>
                    <div class="matero-col div_result" fxFlex="24">
                        <div *ngIf="match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED" [class.text-green-500]="match.statusId === STATUS_IN_GAME" class="" style="font-size: 1.5rem;font-weight: 500;margin-bottom:10px;">{{ match.localResult }} - {{ match.visitorResult }}</div>
                        <div *ngIf="match.statusId === STATUS_UNDEFINED || match.statusId === STATUS_NOT_STARTED" [class.text-green-500]="match.statusId === STATUS_IN_GAME" class="" style="font-size: 1.5rem;font-weight: 500;margin-bottom:10px;">{{ match.date | dateFormat:'LT' }}</div>
                        <div class="status">{{ typeService.getType(match.statusId).langKey | translate }}</div>
                    </div>
                    <div class="matero-col visitor_team" *ngIf="visitorTeam" fxFlex="38" style="text-align: center; font-weight: 400;">
                        <div class="div_shield">
                            <img appImgFallback class="shield" style="max-width: 75px;padding:0%" src="{{(visitorTeam.shield) ? sharedService.getImage(visitorTeam.shield) : sharedService.getImage(defaultShieldTeam)}}">
                        </div>
                        {{ visitorTeam.name }}
                    </div>
                    <div class="matero-col visitor_team" *ngIf="visitorMember" fxFlex="38" style="text-align: center; font-weight: 400;">
                        <div class="div_shield">
                            <img appImgFallback class="shield" style="max-width: 75px;padding:0%;border-radius: 50%;" src="{{(visitorMember.image) ? sharedService.getImage(visitorMember.image) : sharedService.getImage(defaultImageProfile)}}">
                        </div>
                        {{ visitorMember.firstName }} {{ visitorMember.lastName }}
                    </div>
                </div>
            </div>

        </div>
    </mat-card>
</div>

<div *ngIf="widget" class="matero-row m-l-0 m-r-0 " fxLayout="row wrap">
    <div [routerLink]="sharedService.buildRoute(['matches', match.id])" class="matero-col match-tile pointer match-tile-widget" fxFlex="100" fxFlex.gt-sm="100">
        <ng-container *ngIf="!hideDate">
            <div class="date m-b-4 p-x-8" style="margin-top:2px!important;">
                {{ match.date | dateFormat:'llll' }}
            </div>
        </ng-container>
        <div class="matero-row content" style="align-items:center;margin-bottom:0px;margin-top:0px" fxLayout="row wrap">
            <div class="matero-col div_shield" fxFlex="20">
                <img appImgFallback *ngIf="localTeam" class="shield" src="{{(localTeam.shield) ? sharedService.getImage(localTeam.shield) : sharedService.getImage(defaultShieldTeam)}}">
                <img appImgFallback *ngIf="localMember" class="shield" style="max-width: 75px;padding:0%;border-radius: 50%;" src="{{(localMember.image) ? sharedService.getImage(localMember.image) : sharedService.getImage(defaultImageProfile)}}">
            </div>

            <div class="matero-col div_result m-b-8" fxFlex="60">

                <div *ngIf="match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED" [class.text-green-500]="match.statusId === STATUS_IN_GAME" class="" style="font-size: 1.3rem;font-weight: 500;margin-bottom:2px;">{{ match.localResult }} - {{ match.visitorResult }}</div>
                <div *ngIf="match.statusId === STATUS_UNDEFINED || match.statusId === STATUS_NOT_STARTED" [class.text-green-500]="match.statusId === STATUS_IN_GAME" class="" style="font-size: 1.3rem;font-weight: 500;margin-bottom:2px;">{{ match.date | dateFormat:'LT' }}</div>
                <div class="status" style="margin-bottom:5px;">{{ typeService.getType(match.statusId).langKey | translate }}</div>
            </div>

            <div class="matero-col div_shield" fxFlex="20">
                <img appImgFallback *ngIf="visitorTeam" class="shield" src="{{(visitorTeam.shield) ? sharedService.getImage(visitorTeam.shield) : sharedService.getImage(defaultShieldTeam)}}">
                <img appImgFallback *ngIf="visitorMember" class="shield" style="max-width: 75px;padding:0%;border-radius: 50%;" src="{{(visitorMember.image) ? sharedService.getImage(visitorMember.image) : sharedService.getImage(defaultImageProfile)}}">
            </div>
            <div class="matero-col div_shield" fxFlex="50">
                <div *ngIf="localTeam" class="matero-col local_team" style="font-weight: 500; display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;">
                    {{ localTeam.name }}
                </div>

                <div *ngIf="localMember" class="matero-col local_team" style="font-weight: 500;display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;">
                    {{ localMember.firstName }} {{ localMember.lastName }}
                </div>

            </div>
            <div class="matero-col div_shield" fxFlex="50">
                <div *ngIf="visitorTeam" class="matero-col visitor_team" style="font-weight: 500;display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;">
                    {{ visitorTeam.name }}
                </div>

                <div *ngIf="visitorMember" class="matero-col visitor_team" style="font-weight: 500;display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;">
                    {{ visitorMember.firstName }} {{ visitorMember.lastName }}
                </div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="inPublication" class="matero-row m-l-0 m-r-0 " fxLayout="row wrap">
    <div [routerLink]="sharedService.buildRoute(['matches', match.id])" class="matero-col match-tile pointer" fxFlex="100" fxFlex.gt-sm="100">
        <ng-container *ngIf="!hideDate">
            <div class="date m-b-2" style="font-size: 0.8rem;margin-top:2px!important;">
                {{ match.date | dateFormat:'LLLL' }}
            </div>
        </ng-container>
        <div class="matero-row content" style="margin-bottom:0px;margin-top:0px;padding:0px 5px 0px 5px;" fxLayout="row wrap">
            <div class="matero-col div_shield" fxFlex="10">
                <img appImgFallback *ngIf="localTeam" class="shield" src="{{(localTeam.shield) ? sharedService.getImage(localTeam.shield) : sharedService.getImage(defaultShieldTeam)}}">
                <img appImgFallback *ngIf="localMember" class="shield" style="max-width: 75px;padding:0%;border-radius: 50%;" src="{{(localMember.image) ? sharedService.getImage(localMember.image) : sharedService.getImage(defaultImageProfile)}}">
            </div>

            <div class="matero-col div_shield" style="align-self: center!important;" fxFlex="30">
                <div *ngIf="localTeam" class="matero-col local_team" style="display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;">
                    {{ localTeam.name }}
                </div>

                <div *ngIf="localMember" class="matero-col local_team" style="display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;">
                    {{ localMember.firstName }} {{ localMember.lastName }}
                </div>

            </div>

            <div class="matero-col div_result" fxFlex="20">

                <div *ngIf="match.statusId !== STATUS_UNDEFINED && match.statusId !== STATUS_NOT_STARTED" [class.text-green-500]="match.statusId === STATUS_IN_GAME" class="" style="font-size: 1.2rem;font-weight: 500;margin-bottom:0px;">{{ match.localResult }} - {{ match.visitorResult }}</div>
                <div *ngIf="match.statusId === STATUS_UNDEFINED || match.statusId === STATUS_NOT_STARTED" [class.text-green-500]="match.statusId === STATUS_IN_GAME" class="" style="font-size: 1.2rem;font-weight: 500;margin-bottom:0px;">{{ match.date | dateFormat:'LT' }}</div>
                <div class="status" style="margin-bottom:0px;">{{ typeService.getType(match.statusId).langKey | translate }}</div>
            </div>

            <div class="matero-col div_shield" style="align-self: center!important;" fxFlex="30">
                <div *ngIf="visitorTeam" class="matero-col visitor_team" style="display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;">
                    {{ visitorTeam.name }}
                </div>

                <div *ngIf="visitorMember" class="matero-col visitor_team" style="display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;">
                    {{ visitorMember.firstName }} {{ visitorMember.lastName }}
                </div>
            </div>

            <div class="matero-col div_shield" fxFlex="10">

                <img appImgFallback *ngIf="visitorTeam" class="shield" src="{{(visitorTeam.shield) ? sharedService.getImage(visitorTeam.shield) : sharedService.getImage(defaultShieldTeam)}}">
                <img appImgFallback *ngIf="visitorMember" class="shield" style="max-width: 75px;padding:0%;border-radius: 50%;" src="{{(visitorMember.image) ? sharedService.getImage(visitorMember.image) : sharedService.getImage(defaultImageProfile)}}">
            </div>



        </div>
    </div>
</div>