export class GetPublicMatches {
  static readonly type = '[Matches] Get public Matches';
  constructor(public readonly fromDate?: Date, public readonly toDate?: Date, public teamId?: string, public seasonId?: string){}
}

export class GetPublicMatchesWithMultimedia {
  static readonly type = '[Matches] Get public Matches with multimedia';
  constructor(public readonly fromDate?: Date, public readonly toDate?: Date, public teamId?: string, public seasonId?: string){}
}

export class GetPublicCurrentMatch {
  static readonly type = '[Matches] Get public current Matc';
  constructor(public readonly id: string){}
}

export class GetPublicMatchesWidget {
  static readonly type = '[Matches] Get public Matches Widget';
  constructor(){}
}








