

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveHexColorPipe } from './delta-to-html/removeHexColor.pipe';
import { MomentPipe } from './delta-to-html/MomentPipe.pipe';
import { ArrayMapPipe } from './delta-to-html/ArrayMap.pipe';
import { FilterPipe } from './delta-to-html/filter.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    RemoveHexColorPipe,
    MomentPipe,
    ArrayMapPipe,
    FilterPipe

  ],
  exports: [
    RemoveHexColorPipe,
    MomentPipe,
    ArrayMapPipe,
    FilterPipe
  ],
})
export class PipesModule {}
