import { PublicCreateFormAnswerMutation, PublicCreateOrUpdatePollAnswerMutation, PublicCreatePageFormMutation } from './../operations/mutations/publicationMutations';
import { Publication, PublicCreateOrUpdatePollAnswerMutationModel, PublicPublicationQueryModel, FormPublication, PublicCreateFormAnswerMutationModel } from './../interfaces/publicationModel';
import { publicPublicationQuery, publicPublicationsQuery } from './../operations/queries/publicationQueries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, filter,  } from 'rxjs/operators';
import { typesQuery } from '../operations/queries/typesQueries';
import { Store } from '@ngxs/store';
import { TypesModel } from '../interfaces/typesModel';
import { TranslateService } from '@ngx-translate/core';
import gql from 'graphql-tag';
import { EntityState } from 'app/redux/state/entity.state';
import { PublicPublicationsQueryModel } from '../interfaces/publicationModel';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TYPE_FORM_CHECK, TYPE_FORM_COLOR, TYPE_FORM_DATE, TYPE_FORM_DATETIME, TYPE_FORM_DOCUMENT, TYPE_FORM_EMAIL, TYPE_FORM_LIST_VALOURS, TYPE_FORM_NUMBER, TYPE_FORM_TEXT, TYPE_FORM_TEXT_AREA } from '@shared/constants';
import { MutationPublicCreatePageFormAnswerArgs } from '../types';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class PublicationService {

  constructor(
    private apollo: Apollo,
    private translateService: TranslateService
    ) {

  }

  getPublicPublications(data:PublicPublicationsQueryModel){
    return this.apollo.query<any>({
      query: gql(publicPublicationsQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.publicPublications)
    );
  }

  getPublicPublication(data:PublicPublicationQueryModel){
    return this.apollo.query<any>({
      query: gql(publicPublicationQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.publicPublication)
    );
  }

  publicCreateOrUpdatePollAnswer(data:PublicCreateOrUpdatePollAnswerMutationModel){
    return this.apollo.mutate<any>({
      mutation: gql(PublicCreateOrUpdatePollAnswerMutation),
      variables: data
    }).pipe(
      map((result:any) => result.data.publicCreateOrUpdatePollAnswer)
    );
  }

  publicCreateFormAnswer(data:PublicCreateFormAnswerMutationModel){
    return this.apollo.mutate<any>({
      mutation: gql(PublicCreateFormAnswerMutation),
      variables: data
    }).pipe(
      map((result:any) => result.data.publicCreateFormAnswer)
    );
  }

  publicCreatePageFormAnswer(data:MutationPublicCreatePageFormAnswerArgs){
    return this.apollo.mutate<any>({
      mutation: gql(PublicCreatePageFormMutation),
      variables: data
    }).pipe(
      map((result:any) => result.data.publicCreatePageFormAnswer)
    );
  }

  generateForm(form:FormPublication){
    let fields: FormlyFieldConfig[] = [];

    form.customFields.forEach(item => {
      switch(item.typeId){
        case TYPE_FORM_TEXT:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              required: item.require,
              label:item.name
            }
          });
        break;
        case TYPE_FORM_TEXT_AREA:
          fields.push({
            className: '',
            type: 'textarea',
            key: item.id,
            templateOptions: {
              required: item.require,
              label:item.name,
              rows: 8,
            }
          });
        break;
         case TYPE_FORM_CHECK:
          fields.push({
            className: '',
            type: 'custom-toggle',
            key: item.id,
            templateOptions: {
              required: item.require,
              label:item.name,
            }
          });
        break;
        case TYPE_FORM_COLOR:
          fields.push({
            className: '',
            type: 'color',
            key: item.id,
            defaultValue: '#c1c1c1',
            templateOptions: {
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_NUMBER:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              type: 'number',
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_DATE:
          fields.push({
            className: '',
            type: 'date',
            key: item.id,
            templateOptions: {
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_DATETIME:
          fields.push({
            className: '',
            type: 'datetime',
            key: item.id,
            templateOptions: {
              label:item.name,
            }
          });
        break;
        case TYPE_FORM_EMAIL:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              type: 'email',
              label:item.name,
            },
            validators: {
              ip: {
                expression: (c) => !c.value || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" `+this.translateService.instant('validarions.not_valid_email'),
              },
            },
          });
        break;
        case TYPE_FORM_LIST_VALOURS:
          let options = [];
          item.listOfValours.forEach(value =>{
            options.push({label: value, value: value});
          });
          fields.push({
            className: '',
            type: 'select',
            key: item.id,
            templateOptions: {
              label: item.name,
              options: options,
            },
          });
        break;
        case TYPE_FORM_DOCUMENT:
          fields.push({
            className: '',
            type: 'file',
            key: item.id,
            templateOptions: {
              label:item.name,
            }
          });
        break;
      }
    });

    fields.push({
      className: 'field-without-line',
      type: 'checkboxHTML',
      key: 'check-legal',
      defaultValue: false,
      validators: {
        validation: [Validators.requiredTrue],
      },
      templateOptions: {
        label:this.translateService.instant('general.accept_policy'),
        required: true,
      },
    });

    return fields;
  }


}
