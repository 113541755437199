import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '@shared/services/shared.service';
import { ColorEvent } from 'ngx-color';

@Component({
 selector: 'formly-color-input',
 templateUrl: './color-input.html',
 styleUrls: ['./color-input.scss'],
})
export class FormlyColorInput extends FieldType implements OnInit {

  show_picker:Boolean = false;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if(this.show_picker){
      let dentro = false;
      event.path.forEach(value => {
        if (value['className'] !== undefined && value['className'] !== null && value['className'].includes('color-picker')) dentro = true;
      });
      if(!dentro ) {
        this.show_picker = false;
      }
    }
  }

  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService,
    private eRef: ElementRef) {
    super();

  }

  ngOnInit(){
  }

  showPicker(value:Boolean, event: Event){
    this.show_picker = value;
    event.stopPropagation();
  }

  handleChange($event: ColorEvent) {
    this.formControl.setValue($event.color.hex);
  }

}
