import { Component, Input } from '@angular/core';
import { Event } from 'app/graphql/interfaces/publicationModel';

@Component({
  selector: 'app-event-publication',
  styleUrls: ['./event-publication.component.scss'],
  templateUrl: './event-publication.component.html',
})
export class EventPublicationComponent {

  @Input() event: Event;

  constructor() {}

}






