import gql from 'graphql-tag';

export const PublicCreateOrUpdatePollAnswerMutation = `
  mutation publicCreateOrUpdatePollAnswer ($entityId: String!, $answer: String!, $publicationId: String!) {
    publicCreateOrUpdatePollAnswer(entityId: $entityId, answer: $answer, publicationId:$publicationId) {
      id
      entityId
      mainImage
      title
      urlTitle
      body
      seasonId
      team{
        id
        entityId
        name
        categoryId
        sportId
        shield
        favourite
        teamTemplate {
          id
          seasonId
          image
        }
      }
      active
      public
      canComment
      createdAt
      poll {
        id
        description
        finishDate
        options
        answers{
          id
          date
          answer
        }
      }
      event{
        id
        description
        startDate
        finishDate
      }
      form{
        id
        customFields{
          id
          typeId
          name
          nameKey
          require
          listOfValours
        }
      }
    }
  }
`;

export const PublicCreatePageFormMutation = `
  mutation publicCreatePageFormAnswer ($entityId: String!, $pageId: String!, $answers: [CustomizationInput!], ) {
    publicCreatePageFormAnswer(entityId: $entityId, pageId:$pageId, answers: $answers ) {
      id
        entityId
        title
        urlTitle
        galleryImages
        body
        parentId
        active
        public
        form {
          id
          customFields{
            id
            typeId
            name
            nameKey
            require
            listOfValours
          }
        }
    }
  }
`;

export const PublicCreateFormAnswerMutation = `
  mutation publicCreateFormAnswer ($entityId: String!, $publicationId: String!, $answers: [CustomizationInput!], ) {
    publicCreateFormAnswer(entityId: $entityId, publicationId:$publicationId, answers: $answers ) {
      id
      entityId
      mainImage
      title
      urlTitle
      body
      seasonId
      team{
        id
        entityId
        name
        categoryId
        sportId
        shield
        favourite
        teamTemplate {
          id
          seasonId
          image
        }
      }
      active
      public
      canComment
      createdAt
      poll {
        id
        description
        finishDate
        options
        answers{
          id
          date
          answer
        }
      }
      event{
        id
        description
        startDate
        finishDate
      }
      form{
        id
        customFields{
          id
          typeId
          name
          nameKey
          require
          listOfValours
        }
      }
    }
  }
`;

