<div *ngIf="entity$ | async; let entity" fxLayout="row wrap">
    <div class="matero-col" fxFlex="100" fxFlex.gt-sm="100" style="padding: 0;">
        <ngx-slick-carousel class="carousel home-slider-card" #slickModal="slick-carousel" [config]="slideConfig">
            <ng-container *ngFor="let homeImage of entity.homeImage; let i = index">
                <div ngxSlickItem *ngIf="homeImage.active != false" class="slide">
                    <mat-card class="hero-container">

                        <a *ngIf="homeImage.url" href="{{homeImage.url}}" target="{{ (homeImage.urlNewPage) ? 'new': '_self' }}">
                            <div [ngClass]="{ 'home-image': true, 'without_overloap': (!homeImage.homeImagesOverlap || homeImage.colorOverlapOnlyText) }" [style.background-image]="'url(' + sharedService.getImage(homeImage.image, IMAGE_PROXY_XL) + ')'"></div>
                        </a>
                        <div *ngIf="!homeImage.url" [ngClass]="{ 'home-image': true, 'without_overloap': (!homeImage.homeImagesOverlap || homeImage.colorOverlapOnlyText) }" [style.background-image]="'url(' + sharedService.getImage(homeImage.image, IMAGE_PROXY_XL) + ')'"></div>

                        <!-- Con overlay -->
                        <a *ngIf="homeImage.homeImagesOverlap && homeImage.url && (homeImage.textOverlap || (homeImage.buttonTextOverlap && homeImage.buttonLinkOverload))" href="{{homeImage.url}}" target="{{ (homeImage.urlNewPage) ? 'new': '_self' }}">
                            <div *ngIf="!homeImage.colorOverlapOnlyText" class="home-overlay" [style.align-items]="homeImage.align === TYPE_ALIGN_RIGHT ? 'end' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'start' : 'center'" [style.background-color]="homeImage.colorOverlap">

                            </div>
                            <div class="home-overlay-content"  *ngIf="homeImage.textOverlap" [style.justify-content]="homeImage.alignVertical === TYPE_ALIGN_VERTICAL_TOP ? 'flex-start' : (homeImage.alignVertical === TYPE_ALIGN_VERTICAL_BOTTOM) ? 'flex-end' : 'center'" [style.align-items]="homeImage.align === TYPE_ALIGN_RIGHT ? 'end' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'start' : 'center'" [style.text-align]="homeImage.align === TYPE_ALIGN_RIGHT ? 'right' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'left' : 'center'"
                                >
                                <div [style.color]="homeImage.textColorOverlap" [style.background-color]="homeImage.colorOverlapOnlyText ? (homeImage.colorOverlap + '88') : 'transparent'" [style.align-items]="homeImage.align === TYPE_ALIGN_RIGHT ? 'end' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'start' : 'center'" [style.text-align]="homeImage.align === TYPE_ALIGN_RIGHT ? 'right' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'left' : 'center'">
                                    {{homeImage.textOverlap}}
                                    <a class="mat-flat-button" [style.background-color]="homeImage.buttonColorOverlap" [style.color]="sharedService.checkTextGroupColor(homeImage.buttonColorOverlap)" href="{{homeImage.buttonLinkOverload}}" *ngIf="homeImage.buttonTextOverlap && homeImage.buttonLinkOverload"
                                    target="{{ (homeImage.urlNewPageOverlap) ? 'new': '_self' }}">{{homeImage.buttonTextOverlap}}</a>
                                </div>
                            </div>
                        </a>

                        <ng-container *ngIf="homeImage.homeImagesOverlap && !homeImage.url && (homeImage.textOverlap || (homeImage.buttonTextOverlap && homeImage.buttonLinkOverload))">
                            <div *ngIf="!homeImage.colorOverlapOnlyText" class="home-overlay" [style.align-items]="homeImage.align === TYPE_ALIGN_RIGHT ? 'end' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'start' : 'center'" [style.background-color]="homeImage.colorOverlap">

                            </div>
                            <div class="home-overlay-content"  *ngIf="homeImage.textOverlap" [style.justify-content]="homeImage.alignVertical === TYPE_ALIGN_VERTICAL_TOP ? 'flex-start' : (homeImage.alignVertical === TYPE_ALIGN_VERTICAL_BOTTOM) ? 'flex-end' : 'center'" [style.align-items]="homeImage.align === TYPE_ALIGN_RIGHT ? 'end' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'start' : 'center'" [style.text-align]="homeImage.align === TYPE_ALIGN_RIGHT ? 'right' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'left' : 'center'"
                                >
                                <div [style.color]="homeImage.textColorOverlap" [style.background-color]="homeImage.colorOverlapOnlyText ? (homeImage.colorOverlap + '88') : 'transparent'" [style.align-items]="homeImage.align === TYPE_ALIGN_RIGHT ? 'end' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'start' : 'center'" [style.text-align]="homeImage.align === TYPE_ALIGN_RIGHT ? 'right' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'left' : 'center'">
                                    {{homeImage.textOverlap}}
                                    <a class="mat-flat-button" [style.background-color]="homeImage.buttonColorOverlap" [style.color]="sharedService.checkTextGroupColor(homeImage.buttonColorOverlap)" href="{{homeImage.buttonLinkOverload}}" *ngIf="homeImage.buttonTextOverlap && homeImage.buttonLinkOverload"
                                    target="{{ (homeImage.urlNewPageOverlap) ? 'new': '_self' }}">{{homeImage.buttonTextOverlap}}</a>
                                </div>
                            </div>
                        </ng-container>
                        <!-- <div *ngIf="homeImage.homeImagesOverlap && !homeImage.url && (homeImage.textOverlap || (homeImage.buttonTextOverlap && homeImage.buttonLinkOverload))" class="home-overlay" [style.align-items]="homeImage.align === TYPE_ALIGN_RIGHT ? 'end' : (homeImage.align === TYPE_ALIGN_LEFT) ? 'start' : 'center'" 
                            [style.background-color]="homeImage.colorOverlap + '66'">
                            <div class="home-overlay-text" *ngIf="homeImage.textOverlap" [style.color]="homeImage.textColorOverlap">{{homeImage.textOverlap}}</div>
                            <a class="mat-flat-button m-t-16" [style.background-color]="homeImage.buttonColorOverlap" [style.color]="sharedService.checkTextGroupColor(homeImage.buttonColorOverlap)" href="{{homeImage.buttonLinkOverload}}" *ngIf="homeImage.buttonTextOverlap && homeImage.buttonLinkOverload"
                                target="{{ (homeImage.urlNewPageOverlap) ? 'new': '_self' }}">{{homeImage.buttonTextOverlap}}</a>
                        </div> -->
                        <!-- Fin con overlay -->
                    </mat-card>
                </div>
            </ng-container>

        </ngx-slick-carousel>
    </div>
    <div *ngIf="entity.web.showSponsorsHome" class="matero-col" fxFlex="100" fxFlex.gt-sm="100">
        <app-sponsor [topSponsors]="'true'" *ngIf="entity?.sponsor?.length > 0 && (entity.sponsor | filter : filterFunction).length > 0"></app-sponsor>
    </div>
    <div class="matero-content" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <div class="matero-col m-t-8" fxFlex="100" fxFlex.gt-sm="70" style="padding:8px">
            <ng-container *ngIf="(publications$ | async)?.length > 0 && (publications$ | async); let publications;">
                <div class="matero-row" fxLayout="row wrap">
                    <div *ngFor="let publication of publications; let i = index" class="matero-col" fxFlex="100" fxFlex.gt-sm="50" style="margin-bottom: 0px;">
                        <app-publication-tile [publication]="publication" [first]="i === 0">
                        </app-publication-tile>
                    </div>
                </div>
                <div style="position: relative;">
                    <hr style="margin: 32px;">
                    <div style="width: 100%;text-align:center; position: absolute; top:-18px;">
                        <button mat-flat-button color="basic" [routerLink]="sharedService.buildRoute(['news'])">{{ 'general.show_more' | translate }}</button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="matero-col" fxFlex="100" fxFlex.gt-sm="30" style="padding: 16px;">
            <app-widget-yulava [home]="true"></app-widget-yulava>
            <app-widget-banners [home]="true"></app-widget-banners>
            <app-widget-matches></app-widget-matches>
        </div>
    </div>
</div>
