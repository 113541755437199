import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetMorePublicPublications, GetPublicPublications, GetPublicPublicationsWidget } from 'app/redux/actions/publication.actions';
import { Observable } from 'rxjs';
import { DEAFULT_IMAGE_ENTITY, DEAFULT_IMAGE_PUBLICATION, DEAFULT_SHIELD_TEAM } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityState } from 'app/redux/state/entity.state';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeService } from 'app/graphql/services/type.service';
import { Publication } from 'app/graphql/types';
import { PublicationState } from 'app/redux/state/publication.state';
import { SharedService } from '@shared/services/shared.service';
@Component({
  selector: 'app-widget-news',
  styleUrls: ['./news.component.scss'],
  templateUrl: './news.component.html',
})
export class WidgetNewsComponent implements OnInit/* , AfterViewInit */  {

  @Select(PublicationState.publicationsWidget)
  publicationsWidget$: Observable<[Publication]>;

  @Select(PublicationState.showMore)
  showMore$: Observable<Boolean>;

  @Select(EntityState.entity)
  entity$: Observable<Entity>;

  defaultImagePublication = DEAFULT_IMAGE_PUBLICATION;
  defaultShieldTeam = DEAFULT_SHIELD_TEAM;
/*
  @ViewChild('masonry', { static: true }) masonry: ElementRef; */

  array = [];
  sum = 100;
  throttle = 300;
  scrollDistance = 2;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  default_publications_svg = null;
  entity: Entity = this.store.snapshot().Entity.entity;

  tagName: string;

  constructor(/* private renderer: Renderer2, */ private store: Store,private route: ActivatedRoute,public sharedService: SharedService,private http: HttpClient, public typeService: TypeService, private translateService: TranslateService, private sanitizer: DomSanitizer, private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.store.dispatch(new GetPublicPublicationsWidget({}));
  }

}






