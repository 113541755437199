
  <mat-card class="publication-tile pointer" [routerLink]="sharedService.buildRoute(['news', publication.urlTitle])" [ngClass]="{'publication-widget': widget===true}">
    <div *ngIf="!widget || publication.mainImage" style="position: relative;">
      <img appImgFallback  class="main_image" style="width:100%; padding:0" src="{{(publication.mainImage) ? sharedService.getImage(publication.mainImage) : sharedService.getSVG(defaultImagePublication, (entity.primaryColor | removeHexColor))}}">

      <div style="width: calc(100% - 14px);border-radius: 5px; position: absolute;bottom: 10px;left: 7px; margin: 0 auto;">
        <div class="match pointer" style="max-width: 400px;
        margin: 0 auto;
        margin-right: auto;
        padding:0px;
        left: 0;
        right: 0;
        text-align: center;
        " *ngIf="publication.match && publication.match.id">
          <app-match-tile
            [localTeam]="publication.match.localTeam"
            [visitorTeam]="publication.match.visitorTeam"
            [localMember]="publication.match.localMember"
            [visitorMember]="publication.match.visitorMember"
            [match]="publication.match"
            [inPublication]="true">
          </app-match-tile>
        </div>
      </div>
    </div>
    <div style="position: absolute;top: 5px;left: 5px;" class="m-b-4" *ngIf="publication.tags && publication.tags.length > 0">
      <span *ngFor="let tag of publication.tags; let i = index" [style.background]="tag.color"  class="pointer"
        [routerLink]="sharedService.buildRoute(['news','category', tag.id, tag.name])"
        style="color:#fff;margin-right:10px;padding:2px 10px 2px 10px;border-radius: 10px;font-size:0.7rem">
        {{ tag.name }}
      </span>
    </div>

    <mat-card-content>
      <mat-card-title>
        <span>{{publication.title}}</span>
      </mat-card-title>
      <mat-card-subtitle><span>{{publication.date | dateFormat:'lll'}}
        </span>
      </mat-card-subtitle>
      <div *ngIf="
      publication.poll ||
      publication.matchComponent ||
      publication.form ||
      publication.event ||
      publication.geolocation ||
      (publication.galleryImages && publication.galleryImages.length > 0) ||
      (publication.team && publication.team.teamTemplate && publication.team.teamTemplate.length > 0) ||
      (publication.team && publication.team.teamTemplate && publication.team.teamTemplate.length == 0) ||
      publication.competition && publication.competition.id
      " style="position: absolute; background-color: #fff; border-radius: 20px; top: -28px; right: 16px; min-height: 50px; display: flex; align-items: center;  padding: 8px;">
        <span *ngIf="publication.poll" class="mat-card-icon" style="float: right;"><mat-icon matTooltip="{{ 'publication.poll' | translate }}" style="height:14px;">equalizer</mat-icon></span>
        <span *ngIf="publication.matchComponent" class="mat-card-icon" style="float: right;"><mat-icon matTooltip="{{ 'menu.matches' | translate }}" style="height:14px;">swap_horiz</mat-icon></span>
        <span *ngIf="publication.form" class="mat-card-icon" style="float: right;"><mat-icon matTooltip="{{ 'publication.form' | translate }}" style="height:14px;">list_alt</mat-icon></span>
        <span *ngIf="publication.event" class="mat-card-icon" style="float: right;"><mat-icon matTooltip="{{ 'publication.event' | translate }}" style="height:14px;">date_range</mat-icon></span>
        <span *ngIf="publication.geolocation" class="mat-card-icon" style="float: right;"><mat-icon matTooltip="{{ 'publication.geolocation' | translate }}" style="height:14px;">place</mat-icon></span>
        <span *ngIf="publication.galleryImages && publication.galleryImages.length > 0" class="mat-card-icon" style="float: right;"><mat-icon matTooltip="{{ 'publication.multimedia' | translate }}" style="height:14px;">image</mat-icon></span>
        <ng-container *ngIf="publication.team && publication.team.teamTemplate.length > 0">
          <a class="team"
          matTooltip="{{publication.team.name}} | {{typeService.getType(publication.team.categoryId).name}} | {{typeService.getType(publication.team.sportId).name}}" href=""
          [routerLink]="sharedService.buildRoute(['teams', publication.team.id, typeService.getType(publication.team.teamTemplate[0].seasonId).name])">
            <img appImgFallback  src="{{(publication.team.shield) ? sharedService.getImage(publication.team.shield) : sharedService.getImage(defaultShieldTeam)}}">
          </a>
        </ng-container>
        <ng-container *ngIf="publication.team && publication.team.teamTemplate.length == 0">
          <span class="team"
          matTooltip="{{publication.team.name}} | {{typeService.getType(publication.team.categoryId).name}} | {{typeService.getType(publication.team.sportId).name}}" href=""
          >
            <img appImgFallback  src="{{(publication.team.shield) ? sharedService.getImage(publication.team.shield) : sharedService.getImage(defaultShieldTeam)}}">
          </span>
        </ng-container>
        <ng-container *ngIf="publication.competition && publication.competition.id">
          <span class="team"
          matTooltip="{{publication.competition.name}} | {{typeService.getType(publication.competition.categoryId).name}} | {{typeService.getType(publication.competition.sportId).name}}" href=""
          [routerLink]="sharedService.buildRoute(['competitions', publication.competition.id])"
          >
            <img appImgFallback  src="{{(publication.competition.icon) ? sharedService.getImage(publication.competition.icon) : sharedService.getImage(defaultShieldCompetition)}}">
          </span>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>

