import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class MomentPipe implements PipeTransform {
  constructor(private translate: TranslateService){}
  transform(value: string | Date, dateFormat?: string): any {
    return value
      ? moment
          .utc(value)
          .locale(this.translate.currentLang)
          .local()
          .format(dateFormat)
      : '';
  }
}
