export const environment = {
  SERVER_URL: 'https://pre-api.yulava.com',
  IMAGES_URL: 'https://images.yulava.com',
  GS_BUCKET: 'pre-cloudstorage.yulava.com',
  https: 'https',
  graphqlUrl: 'pre-api.yulava.com/graphql',
  production: true,
  useHash: false,
  hmr: false,
  firebase: {
    apiKey: "AIzaSyD1En2fQ5c-RMQjJ88lHd-CRv9QCaubMW0",
    authDomain: "yulava-connect.firebaseapp.com",
    databaseURL: "https://yulava-connect.firebaseio.com",
    projectId: "yulava-connect",
    storageBucket: "pre-cloudstorage.yulava.com",
    messagingSenderId: "920953191841",
    appId: "1:920953191841:web:c85d0fbc119d6ea7d29fe0"
  }
};
