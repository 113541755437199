import {  Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-widget-yulava',
  styleUrls: ['./yulava.component.scss'],
  templateUrl: './yulava.component.html',
})
export class WidgetYulavaComponent implements OnInit  {
  
  @Input() home: Boolean;

  constructor(
    public translate: TranslateService
    ) {}

  ngOnInit() {
  }

}






