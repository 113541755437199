
<mat-card class="publication-event" fxFlex="100" fxFlex.gt-sm="100">
  <mat-card-title class="publication-card">
    <mat-icon matTooltip="{{ 'publication.event' | translate }}">date_range</mat-icon>
    {{ event.description }}
  </mat-card-title>
  <mat-card-content>
    <div class="m-t-8" *ngIf="event.startDate">{{ 'publication.start_date' | translate }}: {{event.startDate | dateFormat:'lll'}}</div>
    <div class="m-t-8" *ngIf="event.finishDate">{{ 'publication.end_date' | translate }}: {{event.finishDate | dateFormat:'lll'}}</div>
  </mat-card-content>
</mat-card>

