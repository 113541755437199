import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetMorePublicPublications, GetPublicPublications, GetPublicPublicationsWidget } from 'app/redux/actions/publication.actions';
import { Observable } from 'rxjs';
import { DEAFULT_IMAGE_ENTITY, DEAFULT_IMAGE_PUBLICATION, DEAFULT_SHIELD_TEAM, TYPE_COMPETITION_TEAM } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityState } from 'app/redux/state/entity.state';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeService } from 'app/graphql/services/type.service';
import { Match, Publication } from 'app/graphql/types';
import { PublicationState } from 'app/redux/state/publication.state';
import { SharedService } from '@shared/services/shared.service';
import { GetPublicMatchesWidget } from 'app/redux/actions/matches.actions';
import { MatchState } from 'app/redux/state/matches.state';
@Component({
  selector: 'app-widget-banners',
  styleUrls: ['./banners.component.scss'],
  templateUrl: './banners.component.html',
})
export class WidgetBannersComponent implements OnInit/* , AfterViewInit */  {
  
  @Input() home: Boolean;
  entity: Entity = this.store.snapshot().Entity.entity;

  constructor(
    private store: Store,
    public sharedService: SharedService,
    public typeService: TypeService, 
    ) {}

  ngOnInit() {
  }

}






