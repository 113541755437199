import { Entity } from './../interfaces/entityModel';
import { publicEntityQuery, publicPagesQuery } from '../operations/queries/entityQueries';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { StorageService } from '@shared/services/secure-storage.service';
import { signupMutation, updateCurrentUserMutation } from 'app/graphql/operations/mutations/loginMutation';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { TYPE_ACTIONS } from '@shared/constants';

@Injectable({
  providedIn: 'root'
})


export class EntityService {
  userData: any; // Save logged in user data

  constructor(  // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private apollo: Apollo,
    private store: Store,
    private storageService: StorageService,
    ) {

  }

  getPublicEntity(webDomain:string){
    return this.apollo.query<any>({
      query: gql(publicEntityQuery),
      variables: {
        webDomain
      }
    }).pipe(
      map(result => result.data.publicEntity)
    );
  }

  getPublicPages(entityId:string){
    return this.apollo.query<any>({
      query: gql(publicPagesQuery),
      variables: {
        entityId
      }
    }).pipe(
      map(result => result.data.publicPages)
    );
  }


  // Sign in with email/password
  SignIn(token) {
      return this.apollo.mutate({
        mutation: gql(signupMutation),
        variables: { token: token }
      });
  }

  UpdateProfile(data:any){
    return this.apollo.mutate({
      mutation: gql(updateCurrentUserMutation),
      variables: data
    });
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        /* this.SetUserData(result.user); */
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = this.storageService.getJsonValue('user');
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {

    this.apollo.watchQuery<any>({
      query: gql`
        query{
          modules{
            name,
            id
          }
        }
      `
    })
    .valueChanges
    .subscribe(({ data, loading }) => {
    });
    /* return this.AuthLogin(new auth.GoogleAuthProvider()); */
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
      /* this.SetUserData(result.user); */
    }).catch((error) => {
      window.alert(error)
    })
  }




/*   checkModule(moduleId: string, entityId?:string):boolean {
    const entity:Entity = this.store.snapshot().Entity.entity;
    if(entity) {
      for(let e=0; e < entity.modules.length ; e++){
        if(entity.modules[e].moduleId === moduleId && entity.modules[e].active === true) {
          return true;
        }
      }

    }
    return false;
  } */

}
