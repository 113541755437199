import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  NgZone,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DEAFULT_IMAGE_ENTITY, IMAGE_PROXY_XL, TYPE_ALIGN_CENTER, TYPE_ALIGN_LEFT, TYPE_ALIGN_RIGHT, TYPE_ALIGN_VERTICAL_BOTTOM, TYPE_ALIGN_VERTICAL_TOP } from '@shared/constants';
import { SharedService } from '@shared/services/shared.service';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { Publication } from 'app/graphql/types';
import { GetPublicPublications } from 'app/redux/actions/publication.actions';
import { EntityState } from 'app/redux/state/entity.state';
import { PublicationState } from 'app/redux/state/publication.state';
import { SlickCarouselComponent } from 'ngx-slick-carousel/slick.component';
import { Observable } from 'rxjs';

import { DashboardService } from './dashboard.srevice';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DashboardService],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('slickModal') public slickComponent: any;

  @Select(PublicationState.publications)
  publications$: Observable<[Publication]>;

  @Select(EntityState.entity)
  entity$: Observable<Entity>;
  entity: Entity = this.store.snapshot().Entity.entity;
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay": true,
    "autoplaySpeed": 6000,
    speed: 1000,
    "dots": false,
    "swipe": false,
    "infinite": true,
    "centerPadding": "0px",
    "arrows": true,
    "rows": 1
  };

  IMAGE_PROXY_XL = IMAGE_PROXY_XL;
  TYPE_ALIGN_CENTER = TYPE_ALIGN_CENTER;
  TYPE_ALIGN_LEFT = TYPE_ALIGN_LEFT;
  TYPE_ALIGN_RIGHT = TYPE_ALIGN_RIGHT;

  TYPE_ALIGN_VERTICAL_TOP = TYPE_ALIGN_VERTICAL_TOP;
  TYPE_ALIGN_VERTICAL_BOTTOM = TYPE_ALIGN_VERTICAL_BOTTOM;

  constructor(
    private dashboardSrv: DashboardService,
    private ngZone: NgZone,
    private store: Store,
    public sharedService: SharedService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private titleService: Title, private metaService: Meta
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetPublicPublications({}));
    this.titleService.setTitle(this.store.snapshot().Entity.entity.name +" | "+ this.translateService.instant('seo.home.title'));

    this.sharedService.removeAllMetaTags();
    this.metaService.addTags([
      {name: 'keywords', content: this.translateService.instant('seo.home.keywords')},
      {name: 'description', content: this.entity.name +" | "+ this.translateService.instant('seo.home.description')},
      {property: 'og:title', content: this.entity.name +" | "+this.translateService.instant('seo.home.title')},
      {property: 'og:url', content: window.location.href},
      {property: 'og:site_name', content: this.entity.name},
      {property: 'og:description', content: this.entity.name +" | "+ this.translateService.instant('seo.home.description')},
      {property: 'og:image', content: (this.entity.image) ? this.sharedService.getImage(this.entity.image) : this.sharedService.getImage(DEAFULT_IMAGE_ENTITY)}
    ]);


    
    
  }

  public ngAfterViewInit(): void {
    if(this.slickComponent) {
      setTimeout(() =>{
        this.slickComponent.unslick()
        this.slickComponent.initSlick();
      },300);
    }
  }

  ngOnDestroy() {

  }

  filterFunction(t): boolean {
    if(t.active != false) return true;
    return false;
  }

}
