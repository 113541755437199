
import { QueryListTeamMembersArgs } from './../types';
import { PublicCreateFormAnswerMutation, PublicCreateOrUpdatePollAnswerMutation } from '../operations/mutations/publicationMutations';
import { PublicCreateOrUpdatePollAnswerMutationModel, PublicPublicationQueryModel, FormPublication, PublicCreateFormAnswerMutationModel } from '../interfaces/publicationModel';
import { publicPublicationQuery } from '../operations/queries/publicationQueries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import gql from 'graphql-tag';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TYPE_FORM_CHECK, TYPE_FORM_COLOR, TYPE_FORM_DATE, TYPE_FORM_DATETIME, TYPE_FORM_EMAIL, TYPE_FORM_LIST_VALOURS, TYPE_FORM_NUMBER, TYPE_FORM_TEXT, TYPE_FORM_TEXT_AREA } from '@shared/constants';
import { PublicListTeamMembersQueryModel, PublicTeamQueryModel, PublicTeamsQueryModel } from '../interfaces/teamModel';
import { publicListTeamMembersQuery, publicTeamQuery, publicTeamsQuery } from '../operations/queries/teamQueries';

@Injectable({
  providedIn: 'root'
})

export class TeamService {

  constructor(
    private apollo: Apollo,
    private translateService: TranslateService
    ) {

  }

  getPublicTeams(data:PublicTeamsQueryModel){
    return this.apollo.query<any>({
      query: gql(publicTeamsQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.publicTeams)
    );
  }

  getPublicTeam(data:PublicTeamQueryModel){
    return this.apollo.query<any>({
      query: gql(publicTeamQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.publicTeam)
    );
  }

  getPublicListTeamMembers(data:PublicListTeamMembersQueryModel){
    return this.apollo.query<any>({
      query: gql(publicListTeamMembersQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.publicListTeamMembers)
    );
  }

  publicCreateOrUpdatePollAnswer(data:PublicCreateOrUpdatePollAnswerMutationModel){
    return this.apollo.mutate<any>({
      mutation: gql(PublicCreateOrUpdatePollAnswerMutation),
      variables: data
    }).pipe(
      map((result:any) => result.data.publicCreateOrUpdatePollAnswer)
    );
  }

  publicCreateFormAnswer(data:PublicCreateFormAnswerMutationModel){
    return this.apollo.mutate<any>({
      mutation: gql(PublicCreateFormAnswerMutation),
      variables: data
    }).pipe(
      map((result:any) => result.data.publicCreateFormAnswer)
    );
  }

  generateForm(form:FormPublication){
    let fields: FormlyFieldConfig[] = [];

    form.customFields.forEach(item => {
      switch(item.typeId){
        case TYPE_FORM_TEXT:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              required: item.require,
              label:item.name
            }
          });
        break;
        case TYPE_FORM_TEXT_AREA:
          fields.push({
            className: '',
            type: 'textarea',
            key: item.id,
            templateOptions: {
              required: item.require,
              label:item.name,
              rows: 8,
            }
          });
        break;
        case TYPE_FORM_CHECK:
          fields.push({
            className: '',
            type: 'custom-toggle',
            key: item.id,
            expressionProperties: {
              'templateOptions.label': item.name,
            },
            templateOptions: {
              required: item.require,
              label:item.name,
            }
          });
        break;
        case TYPE_FORM_COLOR:
          fields.push({
            className: '',
            type: 'color',
            key: item.id,
            defaultValue: '#c1c1c1',
            templateOptions: {
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_NUMBER:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              type: 'number',
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_DATE:
          fields.push({
            className: '',
            type: 'date',
            key: item.id,
            templateOptions: {
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_DATETIME:
          fields.push({
            className: '',
            type: 'datetime',
            key: item.id,
            templateOptions: {
              label:item.name,
            }
          });
        break;
        case TYPE_FORM_EMAIL:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              type: 'email',
              label:item.name,
            },
            validators: {
              ip: {
                expression: (c) => !c.value || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" `+this.translateService.instant('validarions.not_valid_email'),
              },
            },
          });
        break;
        case TYPE_FORM_LIST_VALOURS:
          let options = [];
          item.listOfValours.forEach(value =>{
            options.push({label: value, value: value});
          });
          fields.push({
            className: '',
            type: 'select',
            key: item.id,
            templateOptions: {
              label: item.name,
              options: options,
            },
          });
        break;
      }
    });

    return fields;
  }


}
