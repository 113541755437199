import { MatchState } from './redux/state/matches.state';
import { TeamState } from './redux/state/team.state';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpHeaders } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';
import { RoutesModule } from './routes/routes.module';
import { AppComponent } from './app.component';
import { EmbedVideo } from 'ngx-embed-video';
import { DefaultInterceptor } from '@core';
/* import { StartupService } from '@core';
export function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
} */

import { FormlyModule } from '@ngx-formly/core';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '@env/environment';
import { appRoutes, AppRoutingModule } from './app-routing.module';
import { ApolloLink, concat } from 'apollo-link';
import { StorageService } from '@shared/services/secure-storage.service';
import { onError } from 'apollo-link-error';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EntityService } from './graphql/services/entity.service';
import { setContext } from 'apollo-link-context';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from '@core/class/paginatorI18n.class';
import { EntityState } from './redux/state/entity.state';
import { PublicationState } from './redux/state/publication.state';
import {LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings, ManualParserLoader} from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import {LocalizeRouterHttpLoader} from '@gilsdav/ngx-translate-router-http-loader';
import {Location} from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CompetitionState } from './redux/state/competition.state';
import { MultimediaState } from './redux/state/multimedia.state';
// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: '' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#434343'
    },
    button: {
      background: 'var(--color-client)',
      text: 'var(--color-client-text)'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a>
    </span>
    `,
  },
  content:{
    cookiePolicyHref: '/es/pagina/politica-cookies',
    privacyPolicyHref: '/es/pagina/aviso-legal-politica-privacidad',
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    ThemeModule,
    RoutesModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot( {
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    } ),
    RouterModule.forRoot(appRoutes),
    LocalizeRouterModule.forRoot(appRoutes, {
      parser: {
          provide: LocalizeParser,
          useFactory: (translate, location, settings) =>
              new ManualParserLoader(translate, location, settings, ['en','es']),
          deps: [TranslateService, Location, LocalizeRouterSettings]
      }
  }),
    InfiniteScrollModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    EmbedVideo.forRoot(),
    NgxsModule.forRoot([EntityState, PublicationState, TeamState, CompetitionState, MatchState, MultimediaState], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true,
    },
/*     StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true,
    }, */
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule

{
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private loginService: EntityService,
    storageService: StorageService
  ) {
    const http = httpLink.create({ uri: environment.https+'://'+environment.graphqlUrl });

    const errorlink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>{
          if(message.startsWith("error.")){
            this._snackBar.open(this.translate.instant(`${message}`), null, {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }
        );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const authMiddlewareLink = setContext(async () => {

      //const token =  await this.loginService.getToken();

      const headers = {
        headers: {
          //Authorization: `Bearer ${token}`
        }
      };

      return headers;
    });

    apollo.create({
      link: ApolloLink.from([
        errorlink,
        authMiddlewareLink,
        http
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all',
          fetchPolicy: 'cache-and-network',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
      }
    });
  }
}
