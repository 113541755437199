import { PublicationService } from '../../../../graphql/services/publication.service';
import { GetPublicCurrentPublication, SetPublicCurrentPublication } from '../../../../redux/actions/publication.actions';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DEAFULT_IMAGE_PUBLICATION } from '../../../constants';
import { SharedService } from '../../../services/shared.service';
import { PublicationState } from '../../../../redux/state/publication.state';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetMorePublicPublications, GetPublicPublications } from 'app/redux/actions/publication.actions';
import { Publication } from 'app/graphql/interfaces/publicationModel';
import { Observable } from 'rxjs';
import { DEAFULT_IMAGE_ENTITY } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityState } from 'app/redux/state/entity.state';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatchComponent } from 'app/graphql/types';

@UntilDestroy()
@Component({
  selector: 'app-publication-matches',
  styleUrls: ['./publication-matches.component.scss'],
  templateUrl: './publication-matches.component.html',
})
export class PublicationMatchesComponent implements OnInit {

  @Input() matchComponent: MatchComponent;
  @Input() publicationId: string;

  body = null;
  defaultImagePublication = DEAFULT_IMAGE_PUBLICATION;
  entity: Entity = this.store.snapshot().Entity.entity;



  constructor(private store: Store,private sharedService: SharedService,public translate: TranslateService,private _snackBar: MatSnackBar,private publicationService: PublicationService,  private http: HttpClient, private titleService: Title, private metaService: Meta, private sanitizer: DomSanitizer, private route: ActivatedRoute, private translateService: TranslateService) {
 
  }

  ngOnInit() {
    
  }

}






