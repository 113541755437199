<div *ngIf="home">
    <mat-card class="yulava-container">

        <a href="https://connect.yulava.com/" target="new">
            <img src="assets/images/logo_yulava_color.svg" class="yulava-logo" title="logo Yulava" alt="logo Yulava">
        </a>

        <mat-card-title>
            {{ 'general.member_club' | translate }}
        </mat-card-title>
        <mat-card-actions>
            <a href="https://connect.yulava.com/" target="new">
                <button mat-flat-button>{{ 'general.access' | translate }}</button>
            </a>
        </mat-card-actions>
        <div class="text-center" style="width: 100%;">
            <mat-card-subtitle>{{ 'general.download_app' | translate }}</mat-card-subtitle>
            <div style="display: flex; justify-content: center;">
                <a href="https://play.google.com/store/apps/details?id=com.yulava.connect" target="new" class="app-store-logo">
                    <img src="assets/images/app-store-google.png" title="Google Play" alt="Google Play">
                </a>
                <a href="https://apps.apple.com/es/app/yulava-connect/id1533240784" target="new" class="app-store-logo">
                    <img src="assets/images/app-store-apple.png" title="App Store" alt="App Store">
                </a>
            </div>
        </div>
    </mat-card>
</div>