import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ThemePalette } from '@angular/material/core';
@Component({
 selector: 'formly-datetime-input',
 templateUrl: './datetime-input.html',
 styleUrls: ['./datetime-input.scss'],
})

export class FormlyDateTimeInput extends FieldType {

  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: Date;
  public maxDate: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;

  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

  @ViewChild('datetimepicker') datetimepicker: any;

}
