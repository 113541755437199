

          <mat-card class="publication-matches" fxFlex="100" fxFlex.gt-sm="100">
            <mat-card-title class="publication-card"><mat-icon matTooltip="{{ 'menu.matches' | translate }}">swap_horiz</mat-icon>{{ 'menu.matches' | translate }}
            </mat-card-title>
            <mat-card-content>
              <div class="mini-survey-post-component">
                  <ng-container *ngFor="let match of matchComponent.matches;let e = index">
                    <app-match-tile
                            [routerLink]="sharedService.buildRoute(['matches', match.id])"
                            [localTeam]="match.localTeam"
                            [visitorTeam]="match.visitorTeam"
                            [localMember]="match.localMember"
                            [visitorMember]="match.visitorMember"
                            [match]="match"
                            [inPublication]="false">
                    </app-match-tile>
                  </ng-container>
              </div>
            </mat-card-content>
          </mat-card>


