import { QueryMatchArgs } from './../../graphql/types';
import { Member, QueryMatchesArgs, Team } from '../../graphql/types';

import { MatchService } from '../../graphql/services/match.service';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from '@shared/services/secure-storage.service';
import { Match } from 'app/graphql/types';
import { GetPublicCurrentMatch, GetPublicMatches, GetPublicMatchesWidget, GetPublicMatchesWithMultimedia} from '../actions/matches.actions';
import { LIMIT, TYPE_COMPETITION_TEAM } from '@shared/constants';
import * as moment from 'moment';


export interface MatchStateModel {
  matches: Match[];
  matchesWithMultimedia: Match[];
  lastMatches: Match[];
  nextMatches: Match[];
  currentMatch: Match;
  currentMatchTeams : Team[];
  currentMatchMembers : Member[];
  page: number;
}

@Injectable()
@State<MatchStateModel>({
  name: 'Match',
  defaults:{
    matches: null,
    matchesWithMultimedia: null,
    lastMatches: null,
    nextMatches: null,
    currentMatch: null,
    currentMatchTeams: [],
    currentMatchMembers: [],
    page: 0
  },
})

export class MatchState {
  constructor(
    private matchService: MatchService,
    public ngZone: NgZone,
    public router: Router,
    private store: Store,
    public storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ){}

  @Selector()
  static lastMatches(state: MatchStateModel){
    return state.lastMatches;
  }

  @Selector()
  static nextMatches(state: MatchStateModel){
    return state.nextMatches;
  }

  @Selector()
  static matches(state: MatchStateModel){
    return state.matches;
  }

  @Selector()
  static matchesWithMultimedia(state: MatchStateModel){
    return state.matchesWithMultimedia;
  }

  @Selector()
  static currentMatch(state: MatchStateModel){
    return state.currentMatch;
  }

  @Selector()
  static currentMatchTeams(state: MatchStateModel){
    return state.currentMatchTeams;
  }

  @Selector()
  static currentMatchMembers(state: MatchStateModel){
    return state.currentMatchMembers;
  }

   @Action(GetPublicMatches)
   getPublicMatches({ patchState, getState, dispatch }: StateContext<MatchStateModel>, {fromDate, toDate, teamId, seasonId}:GetPublicMatches){

      //Preparamos la consulta
      let payload :QueryMatchesArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
        fromDate: fromDate,
        toDate: toDate,
        teamId: teamId,
        seasonId: seasonId
      }
      this.matchService.getPublicMatches(payload).subscribe((data:any) =>{
        patchState({
          matches: data,
        });
      });

  }

  @Action(GetPublicMatchesWithMultimedia)
   getPublicMatchesWithMultimedia({ patchState, getState, dispatch }: StateContext<MatchStateModel>, {fromDate, toDate, teamId, seasonId}:GetPublicMatches){

      //Preparamos la consulta
      let payload :QueryMatchesArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
        fromDate: fromDate,
        toDate: toDate,
        teamId: teamId,
        seasonId: seasonId
      }
      this.matchService.getPublicMatchesWithMultimedia(payload).subscribe((data:any) =>{
        patchState({
          matchesWithMultimedia: data,
        });
      });

  }

  @Action(GetPublicMatchesWidget)
  getPublicMatchesWidget({ patchState, getState, dispatch }: StateContext<MatchStateModel>){

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();

    const startDay = new Date(year, month, day - 10);
    const endDay = new Date(year, month, day + 10)

    //Preparamos la consulta
    let payload :QueryMatchesArgs = {
      entityId: this.store.snapshot().Entity.entity.id,
      fromDate: startDay,
      toDate: endDay
    }
    this.matchService.getPublicMatches(payload).subscribe((data:Match[]) =>{
      let lastMatches = [];
      let nextMatches = [];
      let currentTimeStamp = Date.now();
      data.forEach(match => {
        if(new Date(match.date).getTime() > currentTimeStamp){
          nextMatches.push(match);
        }else{
          lastMatches.push(match);
        }
      });

      patchState({
        lastMatches: lastMatches,
        nextMatches: nextMatches
      });

    });
  }
  

  @Action(GetPublicCurrentMatch)
  getPublicCurrentMatch({ patchState, getState, dispatch }: StateContext<MatchStateModel>, payload:GetPublicCurrentMatch){

    let match: Match = null
    if(getState().matches){
      match = getState().matches.find(item => item.id === payload.id);
    }

    if(match){
      patchState({
        currentMatch: match,
      });
    }
    
    //Preparamos la consulta
    let requestPayload :QueryMatchArgs = {
      entityId: this.store.snapshot().Entity.entity.id,
      matchId: payload.id
    }

    this.matchService.getPublicMatch(requestPayload).subscribe((data:Match) =>{
      patchState({
        currentMatch: data,
      });
    });
    
  }
}
