
<agm-map [streetViewControl]="false" [latitude]="location.geolocation.latitude" [zoom]="zoom" [longitude]="location.geolocation.longitude">
  <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
    <agm-marker [latitude]="location.geolocation.latitude" [longitude]="location.geolocation.longitude"></agm-marker>
  </agm-marker-cluster>
</agm-map>
<div class="matero-row content m-l-8 m-r-8 p-y-16" fxLayout="row wrap">
      <div class="geolocation-info">
        <span *ngIf="location.name"><b>{{location.name}}</b></span>
        <span *ngIf="location.geolocation && location.geolocation.formattedAddress">{{location.geolocation.formattedAddress}}</span>
        <span *ngIf="location.name">{{ 'general.max_capacity' | translate }}: {{location.capacity}}</span>
      </div>
      
      <button mat-flat-button color="primary" (click)="open_maps()">{{ 'general.open_maps' | translate }}</button>
</div>
