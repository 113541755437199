import { Entity } from './../../graphql/interfaces/entityModel';


import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { MenuService } from '@core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { SharedService } from '@shared/services/shared.service';
import { EntityService } from 'app/graphql/services/entity.service';
import { TYPE_ACTIONS, MODULES } from '@shared/constants';
import { EntityState } from 'app/redux/state/entity.state';
import { TypeService } from 'app/graphql/services/type.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  TYPE_ACTIONS = TYPE_ACTIONS;
  MODULES = MODULES;
  socials: any[];

  @Select(EntityState.entity)
  entity$: Observable<Entity>;

  @Input() ripple = false;
  menus = this.menuService.getAll();

  constructor(private menuService: MenuService,
    public loginService: EntityService,
    public typeService: TypeService,
    private sharedService: SharedService) {}

  ngOnInit(){
    this.entity$.subscribe(entity => {
      if (entity) {
        this.socials = entity.social.map(obj => {
            const type = this.typeService.getType(obj.typeId);
            return { ...obj, icon: this.typeService.getIcon(type.langKey), type: type.langKey };
          })
          .filter(item => !['type.social_email', 'type.social_web'].includes(item.type));
      }
    });
  }

  // Delete empty values and rebuild route
  buildRoute(routes: string[]) {
    let route = '';
    routes.forEach(item => {
      if (item && item.trim()) {
        route += '/' + item.replace(/^\/+|\/+$/g, '');
      }
    });
    return route;
  }
}
