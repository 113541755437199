import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-translate',
/*    template: `
    <button mat-icon-button class="matero-toolbar-button" [matMenuTriggerFor]="menu">
      <mat-icon>translate</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let lang of langs | keyvalue" (click)="useLanguage(lang.key)">
        <span>{{ lang.value }}</span>
      </button>
    </mat-menu>
  `, */
  template: ``,
  styles: [
    `
      .flag-icon {
        margin-right: 8px;
      }
    `,
  ],
})
export class TranslateComponent {
  langs = {
    'es': 'Español',
    'en': 'English'
  };

  constructor(public translate: TranslateService, private settings: SettingsService, private localize: LocalizeRouterService) {
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.settings.setLanguage(language);
    this.localize.changeLanguage(language);
  }
}
