import { Component, Input, OnInit } from '@angular/core';
import { Location as MapLocation} from 'app/graphql/types';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {
    @Input() location: MapLocation;
    zoom = 13;
    ngOnInit() {
       
    }
    open_maps(){
      let url = "http://maps.google.com/maps?z=12&t=m&q=loc:"+this.location.geolocation.latitude+","+this.location.geolocation.longitude;
      window.open(url, '_new');
    }
    ngOnDestroy() {
        
    }

}
