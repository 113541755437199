<ng-container *ngIf="(publicationsWidget$ | async)?.length > 0 && (publicationsWidget$ | async); let publications;">
  <mat-card>
    <mat-card-title>{{ 'general.last_news' | translate }}</mat-card-title>
    <mat-card-content>
      <div *ngFor="let publication of publications; let i = index"  style="width:100%;">
        <widget-publication-tile
        [publication]="publication"
        [first]="i === 0"
        [widget]="true">
      </widget-publication-tile>
    </div>
    <div  style="width: 100%;text-align:center; margin-top:16px;">
      <button mat-flat-button color="primary" [routerLink]="sharedService.buildRoute(['news'])">{{ 'general.show_more' | translate }}</button>
    </div>
    </mat-card-content>
  </mat-card>
</ng-container>


