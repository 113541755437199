import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DEAFULT_IMAGE_PROFILE, DEAFULT_IMAGE_PUBLICATION, DEAFULT_IMAGE_TEAM, DEAFULT_SHIELD_TEAM } from '../../../constants';
import { SharedService } from '../../../services/shared.service';
import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EntityState } from 'app/redux/state/entity.state';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TypesModel } from 'app/graphql/interfaces/typesModel';
import { TypeService } from 'app/graphql/services/type.service';

@UntilDestroy()
@Component({
  selector: 'app-sponsor',
  styleUrls: ['./sponsor.component.scss'],
  templateUrl: './sponsor.component.html',
})
export class SponsorComponent implements OnInit {

  @Select(EntityState.entity)
  entity$: Observable<Entity>;
  @Input() topSponsors: Boolean;
  
  seasons: TypesModel[];
  defaultImageTeam = DEAFULT_IMAGE_TEAM;
  body = null;
  defaultShieldTeam = DEAFULT_SHIELD_TEAM;
  defaultImageProfile = DEAFULT_IMAGE_PROFILE;
  entity: Entity = this.store.snapshot().Entity.entity;

  slideConfig = {
    "slidesToShow": 6,
    "slidesToScroll": 6,
    "autoplay": true,
    "autoplaySpeed": 4000,
    speed: 1000,
    "dots": false,
    "infinite": true,
    "centerPadding": "0px",
    "draggable": false,
    "arrows": true,
    "swipe": false,
    "rows": this.entity.sponsor.length > 12 ? 2 : 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 3,
          "slidesToScroll": 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2,
          "slidesToScroll": 2
        }
      }
    ]
  };

 
  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  constructor(private store: Store,private sharedService: SharedService,private typeService: TypeService, private titleService: Title, private metaService: Meta, private sanitizer: DomSanitizer, private route: ActivatedRoute, private translateService: TranslateService) {}

  submitEntity() {}

  ngOnInit() {


  }


}






