import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, filter,  } from 'rxjs/operators';
import { typesQuery } from '../operations/queries/typesQueries';
import { Store } from '@ngxs/store';
import { TypesModel } from '../interfaces/typesModel';
import { TranslateService } from '@ngx-translate/core';
import gql from 'graphql-tag';
import { EntityState } from 'app/redux/state/entity.state';

@Injectable({
  providedIn: 'root'
})

export class TypeService {

  constructor(
    private apollo: Apollo,
    private store: Store,
    private translate: TranslateService
    ) {

  }

  getTypes(entityId: string){
    return this.apollo.query<any>({
      query: gql(typesQuery),
      variables: {
        entityId
      }
    }).pipe(
      map(result => result.data.types)
    );
  }

  getFilteredTypes(type: string){
    return this.store.select(EntityState.typesData).pipe(map((data:any) => {
      let filterTypes = data.filter(item => item.type === type);
      let final = filterTypes.map(item => {
        if(item.langKey){
          item = { ...item, name: this.translate.instant(item.langKey) };
        }
        return item;
      });
      return final;
    }));
  }

  getFilteredTypesSnapshot(type: string){
    let types:[TypesModel] = this.store.snapshot().Entity.types;
    let filterTypes = types.filter(item => item.type === type);
    return filterTypes.map(item => {
      if(item.langKey){
        item = { ...item, name: this.translate.instant(item.langKey) };
      }
      return item;
    });
  }

  getType(id: String) {
    let response: TypesModel = {
      id:"1",
      langKey:"",
      name:"",
      type:"",
      image:""
    }
    if(id == null || id == undefined || id == ""){
      response = {
        id:"1",
        langKey:"",
        name:"",
        type:"",
        image:""
      }
      return response;
    }
    let types:[TypesModel] = this.store.snapshot().Entity.types;
    let type:TypesModel = types.filter(item => item.id === id)[0];

    response = {
      id:type.id,
      langKey:type.langKey ?? type.name,
      name:type.name,
      type:type.type,
      image:type.image
    }

    return response;
  }

   getIcon(langKey) {
    switch (langKey) {
      case "type.social_email":
        return 'email';
        break;
      case "type.social_facebook":
        return 'svg_facebook';
        break;
      case "type.social_instagram":
        return 'svg_instagram';
        break;
      case "type.social_youtube":
        return 'svg_youtube';
        break;
      case "type.social_Twitter":
        return 'svg_twitter';
        break;
      case "type.social_web":
        return 'language';
        break;
      default:
        return null;
    }
  }

}
