import { FormlyCheckboxHtmlInput } from './components/form-fields/checkbox-html/checkbox-html-input';
import { FormlyFileInput } from './components/form-fields/file-input/file-input';
import { PublicationGalleryComponent } from './components/publications/publication-gallery/publication-gallery.component';
import { ImgFallbackDirective } from './utils/img-fall.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import { MaterialExtensionsModule } from '@ng-matero/extensions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { StorageService } from './services/secure-storage.service';
import { SharedService } from './services/shared.service';
import { MaterialModule } from 'app/material.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { FormlyCropperImageInput } from './components/form-fields/cropper-image-input/cropper-image-input';
import { ImagePreloadDirective } from './directives/ImagePreload';
import { DialogFormComponent } from './components/dialogs/form/dialog-form.component';
import { EntityService } from 'app/graphql/services/entity.service';
import { TypeService } from 'app/graphql/services/type.service';
import { FormlyColorInput } from './components/form-fields/color-input/color-input';
import { ClickOutsideModule } from 'ng4-click-outside';
import { ColorSketchModule  } from 'ngx-color/sketch';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { FormlyCustomToggleInput } from './components/form-fields/custom-toggle-input/custom-toggle-input';
import { DialogConfirmationComponent } from './components/dialogs/confirmation/dialog-confirmation.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PipesModule } from './pipes/pipes.module';
import { FormlyDateInput } from './components/form-fields/date-input/date-input';
import { FormlyDateTimeInput } from './components/form-fields/datetime-input/datetime-input';
import { AnalyticsService } from './services/analytics.service';
import { MatchTileComponent } from './components/matches/match-tile/match-tile.component';
import { MatchIndividualTileComponent } from './components/matches/match-individual-tile/match-individual-tile.component';
import { PollComponent } from './components/publications/poll/poll.component';
import { FormPublicationComponent } from './components/publications/form-publication/form-publication.component';
import { EventPublicationComponent } from './components/publications/event-publication/event-publication.component';
import { PublicationTileComponent } from './components/publications/publication-tile/publication-tile.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { WidgetNewsComponent } from './components/widgets/news/news.component';
import { WidgetsComponent } from './components/widgets/widgets.component';
import { WidgetMatchesComponent } from './components/widgets/matches/matches.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SponsorComponent } from './components/widgets/sponsor/sponsor.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { WidgetPublicationTileComponent } from './components/widgets/news/widget-publication-tile/widget-publication-tile.component';
import { WidgetBannersComponent } from './components/widgets/banners/banners.component';
import { WidgetYulavaComponent } from './components/widgets/yulava/yulava.component';
import { PublicationMatchesComponent } from './components/publications/publication-matches/publication-matches.component';


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

const THIRD_MODULES = [
  ClickOutsideModule,
  ColorSketchModule ,
  MaterialModule,
  MaterialExtensionsModule,
  FlexLayoutModule,
  NgProgressModule,
  NgProgressRouterModule,
  NgProgressHttpModule,
  InfiniteScrollModule,
  PipesModule,
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyD3MkFMHK_YWHB68jKUKFnWBycYUbRrXcE'
  }),
  AgmJsMarkerClustererModule,
  NgSelectModule,
  FormlyModule.forRoot({
    types: [
      { name: 'cropper-image', component: FormlyCropperImageInput },
      { name: 'color', component: FormlyColorInput },
      { name: 'custom-toggle', component: FormlyCustomToggleInput },
      { name: 'date', component: FormlyDateInput },
      { name: 'datetime', component: FormlyDateTimeInput },
      { name: 'file', component: FormlyFileInput },
      { name: 'checkboxHTML', component: FormlyCheckboxHtmlInput },

    ]
  }),
  FormlyMatToggleModule,
  FormlyMaterialModule,
  ToastrModule,
  TranslateModule,
  NgxMasonryModule,
  SlickCarouselModule
/*   IvyCarouselModule */
];
const COMPONENTS = [CountdownComponent,GoogleMapsComponent,WidgetsComponent,SponsorComponent,MatchTileComponent,WidgetNewsComponent,WidgetBannersComponent, WidgetYulavaComponent, WidgetPublicationTileComponent,WidgetMatchesComponent,MatchIndividualTileComponent,PollComponent,FormPublicationComponent,PublicationTileComponent,PublicationGalleryComponent,PublicationMatchesComponent,EventPublicationComponent,BreadcrumbComponent, PageHeaderComponent, ErrorCodeComponent, FormlyCropperImageInput,FormlyColorInput,FormlyDateInput,FormlyDateTimeInput,FormlyCustomToggleInput,FormlyFileInput, FormlyCheckboxHtmlInput, DialogConfirmationComponent, DialogFormComponent];
const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [ImagePreloadDirective, ImgFallbackDirective];
const PIPES = [];

@NgModule({
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES],
  imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, ...THIRD_MODULES],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ...THIRD_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  providers: [StorageService, SharedService,EntityService,TypeService,AnalyticsService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    }],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class SharedModule {}
