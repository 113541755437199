<mat-card *ngIf="((lastMatches$ | async) && (lastMatches$ | async).length > 0 ) || ((nextMatches$ | async) && (nextMatches$ | async).length > 0)" mat-stretch-tabs mat-align-tabs="center" class="m-t-16">
  <div *ngIf="(nextMatches$ | async).length > 0 && (nextMatches$ | async); let nextMatches;">
    <mat-card-title>{{ 'matches.nextMatches' | translate }}</mat-card-title>
    <mat-card-content>
      <ng-container *ngFor="let match of nextMatches.slice(0,5);let e = index">
        <hr *ngIf="e !== 0" class="hr">
        <app-match-tile
                [routerLink]="sharedService.buildRoute(['matches', match.id])"
                [localMember]="match.localMember"
                [visitorMember]="match.visitorMember"
                [localTeam]="match.localTeam"
                [visitorTeam]="match.visitorTeam"
                [match]="match"
                [widget]="true">
        </app-match-tile>
      </ng-container>
    </mat-card-content>
  </div>
  <div *ngIf="(lastMatches$ | async).length > 0 && (lastMatches$ | async); let lastMatches;">
    <mat-card-title>{{ 'matches.lastMatches' | translate }}</mat-card-title>
    <mat-card-content>
      <ng-container *ngFor="let match of lastMatches.slice(-5);let e = index">
        <hr *ngIf="e !== 0" class="hr">
        <app-match-tile
                [routerLink]="sharedService.buildRoute(['matches', match.id])"
                [localTeam]="match.localTeam"
                [visitorTeam]="match.visitorTeam"
                [localMember]="match.localMember"
                [visitorMember]="match.visitorMember"
                [match]="match"
                [widget]="true">
        </app-match-tile>
      </ng-container>
      <div *ngIf="(lastMatches$ | async)?.length > 0 || (nextMatches$ | async)?.length > 0" style="width: 100%;text-align:center; margin-top:16px;">
        <button mat-flat-button color="primary" [routerLink]="sharedService.buildRoute(['matches'])">{{ 'general.show_more' | translate }}</button>
      </div>
    </mat-card-content>
  </div>

</mat-card>




