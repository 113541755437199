import { AnalyticsService } from './../../shared/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from './../../core/bootstrap/menu.service';
import { TypeService } from './../../graphql/services/type.service';
import { Entity } from './../../graphql/interfaces/entityModel';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostBinding,
  ElementRef,
  Inject,
  Optional,
  SecurityContext,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Directionality } from '@angular/cdk/bidi';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';

import { SettingsService, AppSettings } from '@core';
import { AppDirectionality } from '@shared';

import { Select, Store } from '@ngxs/store';
import { SharedService } from '@shared/services/shared.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EntityState } from 'app/redux/state/entity.state';
import { IMAGE_PROXY_XL, IMAGE_PROXY_XS, MOBILE_MEDIAQUERY, MONITOR_MEDIAQUERY, TABLET_MEDIAQUERY } from '@shared/constants';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentAdapter, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { DomSanitizer } from '@angular/platform-browser';



export const CUSTOM_MOMENT_FORMATS  = {
  parse: {
    dateInput: "l, LT"
  },
  display: {
    dateInput: "l, LT",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@UntilDestroy()
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  providers:[
    {provide: MAT_DATE_LOCALE, useValue: 'es-Es'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter, deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  ]
})


export class AdminLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('content', { static: true }) content: MatSidenavContent;

  @Select(EntityState.entity)
  entity$: Observable<Entity>;



  options = this.settings.getOptions();

  private layoutChanges: Subscription;
  path:string;
  socials: any[];
  private isMobileScreen = false;
  private isTabletScreen = false;
  get isOver(): boolean {
    return this.isMobileScreen || this.isTabletScreen;
  }

  private contentWidthFix = true;
  @HostBinding('class.matero-content-width-fix') get isContentWidthFix() {
    return (
      this.contentWidthFix &&
      this.options.navPos === 'side' &&
      this.options.sidenavOpened &&
      !this.isOver
    );
  }

  private collapsedWidthFix = true;
  @HostBinding('class.matero-sidenav-collapsed-fix') get isCollapsedWidthFix() {
    return (
      this.collapsedWidthFix &&
      (this.options.navPos === 'top' || (this.options.sidenavOpened && this.isOver))
    );
  }

  

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private overlay: OverlayContainer,
    private element: ElementRef,
    private settings: SettingsService,
    public translate: TranslateService,
    private sharedService: SharedService,
    private typeService: TypeService,
    private menuService: MenuService,
    private localize: LocalizeRouterService,
    private sanitize: DomSanitizer,
    private renderer: Renderer2,
    private analyticsService: AnalyticsService,
    private store: Store,
    private adapter: DateAdapter<any>,
    private ngxMatDateAdapter: NgxMatDateAdapter<any>,
    @Optional() @Inject(DOCUMENT) private _document: Document,
    @Inject(Directionality) public dir: AppDirectionality
  ) {
    this.dir.value = this.options.dir;
    this._document.body.dir = this.dir.value;

    this.layoutChanges = this.breakpointObserver
      .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY, MONITOR_MEDIAQUERY])
      .subscribe(state => {
        // SidenavOpened must be reset true when layout changes
        this.options.sidenavOpened = true;

        this.isMobileScreen = state.breakpoints[MOBILE_MEDIAQUERY];
        this.isTabletScreen = state.breakpoints[TABLET_MEDIAQUERY];
        this.options.sidenavCollapsed = false;//state.breakpoints[TABLET_MEDIAQUERY];
        this.contentWidthFix = state.breakpoints[MONITOR_MEDIAQUERY];
      });

    // TODO: Scroll top to container
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.path = evt.url;
        this.content.scrollTo({ top: 0 });
        if(this.isMobileScreen) this.sidenav.close();
        this.analyticsService.trackVirtualPageview(evt.url);
      }
    });

    this.entity$.subscribe(entity=>{
      if(entity){
        //this.store.dispatch(new GetPublicPublications());

        this.socials = entity.social.map(obj => {
          const type = this.typeService.getType(obj.typeId);
          return { ...obj, icon: this.typeService.getIcon(type.langKey), type: type.langKey };
        })
        .filter(item => !['type.social_email', 'type.social_web'].includes(item.type));

        if(entity.web){
          let defaults: AppSettings = {
            navPos: 'side',
          };

          if(entity.web.customCss) {
            const head = this._document.getElementsByTagName('head')[0];
            const css = sanitize.sanitize(SecurityContext.STYLE, entity.web.customCss);
            const style = document.createElement('style');
            style.type = 'text/css';
            style.appendChild(document.createTextNode(css));
            head.appendChild(style);
          }

          if(entity.web.jivochat) {
            const script = this.renderer.createElement('script');
            script.src = `https://static.cdn-apple.com/businesschat/start-chat-button/2/index.js`;
            this.renderer.appendChild(document.head, script);

            const script2 = this.renderer.createElement('script');
            script2.src = `//code.jivosite.com/widget/`+entity.web.jivochat;
            script2.async = true;
            this.renderer.appendChild(document.head, script2);
          }

          this._document.getElementById('appFavicon').setAttribute('href', this.sharedService.getImage(entity.image, IMAGE_PROXY_XS));

          if(entity.web.typeMenu){
            //Posicion del menú
            const type = this.typeService.getType(entity.web.typeMenu);
            if(type.langKey === 'web.design_menu_left'){
              defaults.navPos ='side';
            }else{
              defaults.navPos ='top';
            }
          }

          if(entity.web.typeFont){
            //Posicion del menú
            const type = this.typeService.getType(entity.web.typeFont);
            const root = document.documentElement;
            root.style.setProperty('--client-font-family', type.name + ', Raleway, sans-serif');
          }

          //tipo de borde
          if(entity.web.typeBorder){
            const root = document.documentElement;
            const typeBorder = this.typeService.getType(entity.web.typeBorder);

            if(typeBorder.langKey === 'web.design_border_very_rounded'){
              root.style.setProperty('--client-radius', '15px');
              root.style.setProperty('--client-radius-buttons', '25px');
            }else if(typeBorder.langKey === 'web.design_border_rounded'){
              root.style.setProperty('--client-radius', '5px');
              root.style.setProperty('--client-radius-buttons', '10px');
            }else{
              root.style.setProperty('--client-radius', '0px');
              root.style.setProperty('--client-radius-buttons', '0px');
            }
          }

          if(entity.web.backgroundImage){
            //imagen de fondo
            let image = this.sharedService.getImage(entity.web.backgroundImage, IMAGE_PROXY_XL);
            const root = document.documentElement;
            root.style.setProperty('--background-image', `url(${image})`);
          }
          if(entity.web.backgroundColor){
            //Color de fondo
            const root = document.documentElement;
            root.style.setProperty('--background-color', entity.web.backgroundColor);

          }


          this.settings.setLayout(defaults);
          /* this.gaService.configure('UA-35330214-3');
          this.gaService.event.emit({
            category: 'app',
            action: 'bootstrap'
          }); */

        }

        //Idioma de la web
        translate.addLangs(['es', 'en']);
        if(entity.web && entity.web.language){
          const type = this.typeService.getType(entity.web.language);

          translate.setDefaultLang(type.name);
          this.localize.changeLanguage(type.name);
          this.adapter.setLocale(type.name);
          this.ngxMatDateAdapter.setLocale(type.name);
          this._document.getElementById('id_lang').setAttribute('lang', type.name);
        }else{
          translate.setDefaultLang('es');
          this.localize.changeLanguage('es');
          this.adapter.setLocale('es');
          this.ngxMatDateAdapter.setLocale('es');
          this._document.getElementById('id_lang').setAttribute('lang', 'es');
        }

        this.menuService.set(entity);

        this.setClientColors(entity.primaryColor, entity.secondaryColor);
        if(entity.web.analytics){
          this.analyticsService.startTracking(entity.web.analytics);
        }


      }
    });
  }

  ngOnInit() {

    setTimeout(() => (this.contentWidthFix = this.collapsedWidthFix = false));
    this.entity$.pipe(untilDestroyed(this)).subscribe(entity => {
      if(entity !== null){

      }
    });
  }

  ngOnDestroy() {
    this.layoutChanges.unsubscribe();
  }

  toggleCollapsed() {
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }

  resetCollapsedState(timer = 400) {
    // TODO: Trigger when transition end
    setTimeout(() => {
      this.settings.setNavState('collapsed', this.options.sidenavCollapsed);
    }, timer);
  }

  sidenavCloseStart() {
    this.contentWidthFix = false;
  }

  sidenavOpenedChange(isOpened: boolean) {
    this.options.sidenavOpened = isOpened;
    this.settings.setNavState('opened', isOpened);

    this.collapsedWidthFix = !this.isOver;
    this.resetCollapsedState();
  }

  // Demo purposes only
  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
    this.toggleDirection(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.element.nativeElement.classList.add('theme-dark');
      this.overlay.getContainerElement().classList.add('theme-dark');
    } else {
      this.element.nativeElement.classList.remove('theme-dark');
      this.overlay.getContainerElement().classList.remove('theme-dark');
    }
  }

  toggleDirection(options: AppSettings) {
    this.dir.value = options.dir;
    this._document.body.dir = this.dir.value;
  }

  colorOpacity(color:string){
    let Color = require('color');
    let opacityColor = Color(color).alpha(0.8);
    return opacityColor
  }

  setClientColors(color: string, secondColor?: string, thirdColor?: string) {
    const root = document.documentElement;
    root.style.setProperty('--color-client', color);
    root.style.setProperty('--color-client-links', this.sharedService.checkTextGroupLink(color));
    root.style.setProperty('--color-client-second', secondColor);
    root.style.setProperty('--color-client-text', this.sharedService.checkTextGroupColor(color));
    root.style.setProperty('--color-client-text-transparency', this.sharedService.checkTextGroupColor(color)+ '20');
    root.style.setProperty('--color-client-second-text', this.sharedService.checkTextGroupColor(secondColor));
    root.style.setProperty('--color-client-third', thirdColor);
    root.style.setProperty('--color-client-transparency', color + '19');
    root.style.setProperty('--color-client-10', this.sharedService.LightenDarkenColor(color, 180));
    root.style.setProperty('--color-client-50', this.sharedService.LightenDarkenColor(color, 55));
    root.style.setProperty('--color-client-100', this.sharedService.LightenDarkenColor(color, 45));
    root.style.setProperty('--color-client-200', this.sharedService.LightenDarkenColor(color, 35));
    root.style.setProperty('--color-client-300', this.sharedService.LightenDarkenColor(color, 20));
    root.style.setProperty('--color-client-400', this.sharedService.LightenDarkenColor(color, 10));
    root.style.setProperty('--color-client-500', color);
    root.style.setProperty('--color-client-600', this.sharedService.LightenDarkenColor(color, -10));
    root.style.setProperty('--color-client-700', this.sharedService.LightenDarkenColor(color, -20));
    root.style.setProperty('--color-client-800', this.sharedService.LightenDarkenColor(color, -30));
    root.style.setProperty('--color-client-900', this.sharedService.LightenDarkenColor(color, -40));
    root.style.setProperty('--color-client-A100', this.sharedService.LightenDarkenColor(color, 70));
    root.style.setProperty('--color-client-A200', this.sharedService.LightenDarkenColor(color, 50));
    root.style.setProperty('--color-client-A400', this.sharedService.LightenDarkenColor(color, 30));
    root.style.setProperty('--color-client-A700', this.sharedService.LightenDarkenColor(color, 10));
    root.style.setProperty('--color-client-transparent', this.colorOpacity(color));

    this.sharedService.setPrimaryColor(color);
    this.sharedService.setSecondaryColor(secondColor);


    root.style.setProperty('--bg-high-transparency', color + '10');
    root.style.setProperty('--bg-medium-transparency', color + '40');
  }


  filterFunction(t): boolean {
    if(t.active != false) return true;
    return false;
  }
}
