
  <div *ngIf="formControl">
    <mat-form-field  style="width:100%" (click)="showPicker(!show_picker, $event)">
      <mat-label>{{ field.templateOptions.label }}</mat-label>
      <input matInput placeholder="{{ field.templateOptions.label }}" required [value]="formControl.value" readonly>
      <div class="color-square" [style.background-color]="formControl.value"></div>
    </mat-form-field>
    <div class="color-picker" [hidden]="!show_picker">
      <color-sketch [color]="formControl.value" disableAlpha='true' (onChangeComplete)="handleChange($event)" ></color-sketch>
    </div>
  </div>


