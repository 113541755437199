import { Entity } from './../../graphql/interfaces/entityModel';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '@shared/services/shared.service';
import { Select } from '@ngxs/store';
import { DEAFULT_IMAGE_ENTITY } from '@shared/constants';
import { EntityState } from 'app/redux/state/entity.state';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
})
export class UserPanelComponent {
  //Constantes
  defaultImageEntity = DEAFULT_IMAGE_ENTITY;

  @Select(EntityState.entity)
  entity$: Observable<Entity>;

  constructor(
    private sharedService: SharedService) {}


}
