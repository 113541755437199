
export const publicMultimediaQuery = `
query ($entityId: String!) {
  publicMultimedia(entityId: $entityId) {
    id
    name
    public
    active
    anyone
    items {
      id
      url
      userId
    }
    matchId
    competitionId
    teamTemplateId
  }
}
`;

export const multimediaTabsQuery = `
query ($entityId: String!) {
  multimediaTabs(entityId: $entityId) {
    showGalleries
    showMatches
    showCompetitions
    showTeams
  }
}
`;

export const multimediaGalleriesQuery = `
query ($entityId: String!) {
  multimediaGalleries(entityId: $entityId) {
    id
    name
    public
    active
    anyone
    items {
      id
      url
      userId
    }
    matchId
    competitionId
    teamTemplateId
  }
}
`;

export const multimediaTeamsQuery = `
query ($entityId: String!, $seasonId: String!) {
  multimediaTeams(entityId: $entityId, seasonId: $seasonId) {
    id
    name
    public
    active
    anyone
    items {
      id
      url
      userId
    }
    matchId
    competitionId
    teamTemplateId
  }
}
`;

export const multimediaCompetitionsQuery = `
query ($entityId: String!, $seasonId: String!) {
  multimediaCompetitions(entityId: $entityId, seasonId: $seasonId) {
    id
    name
    public
    active
    anyone
    items {
      id
      url
      userId
    }
    matchId
    competitionId
    teamTemplateId
  }
}
`;
