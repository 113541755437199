import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'removeHexColor'})
export class RemoveHexColorPipe implements PipeTransform {
  constructor(){}
  transform(value: string): string {
  return value.replace("#", "");
  }

}
