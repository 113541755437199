<div *ngIf="entity" class="m-b-16">
  <ng-container *ngFor="let banner of entity.banner;let e = index">
    <ng-container *ngIf="(home && banner.inHome) || (!home && banner.inOtherPages)">
      <a href="{{banner.url}}" target="{{ (banner.urlNewPage) ? 'new': '_self' }}" *ngIf="banner.url">
        <img class="pointer" src="{{ sharedService.getImage(banner.image) }}" title="{{ banner.name }}" alt="{{ banner.description }}" width="100%">     
      </a>
      <img *ngIf="!banner.url"  src="{{ sharedService.getImage(banner.image) }}" title="{{ banner.name }}" alt="{{ banner.description }}" width="100%">   
    </ng-container>  
  </ng-container>
</div>


