<div *ngIf="entity$ | async; let entity" class="matero-user-panel" fxLayout="column" fxLayoutAlign="center center">

  <img appImgFallback
    class="matero-entity-panel-avatar"
    [routerLink]="'/'"
    src="{{(entity.image) ? sharedService.getImage(entity.image) : sharedService.getImage(defaultImageEntity)}}"
    default="{{sharedService.getImage(defaultImageEntity)}}"
    alt="avatar"
    width="64"
  />
  <p style="font-size: 1rem; margin: 0; font-weight:700; white-space:normal; text-align:center; line-height: 1.2rem; max-width: 240px;">{{ entity.name }}</p>

</div>


<!-- <div class="sidebar_header" *ngIf="entity$ | async; let user">
  <img appImgFallback  src="{{(user.entities[0].entity.image) ? sharedService.getImage(user.entities[0].entity.image) : '/assets/dummy-user.png'}}" width=120 height=120>
  <div class="title">{{user.entities[0].entity.name}}</div>
  <div class="domain">@{{user.entities[0].entity.domain}}</div>
</div> -->
