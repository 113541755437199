import { LIMIT } from './../../shared/constants';
import { GetMorePublicPublications, GetPublicCurrentPublication, GetPublicPublications, GetPublicPublicationsWidget, SetPublicCurrentPublication } from './../actions/publication.actions';
import { PublicationService } from './../../graphql/services/publication.service';
import {  PublicPublicationQueryModel, PublicPublicationsQueryModel } from './../../graphql/interfaces/publicationModel';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from '@shared/services/secure-storage.service';
import { Publication } from 'app/graphql/types';
import { SharedService } from '@shared/services/shared.service';
export interface PublicationStateModel {
  publications: Publication[];
  publicationsWidget: Publication[];
  currentPublication: Publication;
  showMore: Boolean;
  page: number;
}

@Injectable()
@State<PublicationStateModel>({
  name: 'Publication',
  defaults:{
    showMore:false,
    publications: null,
    publicationsWidget: null,
    currentPublication: null,
    page: 0
  },
})

export class PublicationState {
  constructor(
    private publicationService: PublicationService,
    public ngZone: NgZone,
    public router: Router,
    private store: Store,
    private sharedService: SharedService,
    public storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ){}

  @Selector()
  static publications(state: PublicationStateModel){
    return state.publications;
  }

  @Selector()
  static publicationsWidget(state: PublicationStateModel){
    return state.publicationsWidget;
  }

  @Selector()
  static showMore(state: PublicationStateModel){
    return state.showMore;
  }

  @Selector()
  static currentPublication(state: PublicationStateModel){
    return state.currentPublication;
  }


   @Action(GetPublicPublications)
   getPublicpublications({ patchState, getState, dispatch }: StateContext<PublicationStateModel>, input:GetPublicPublications){
      //Preparamos la consulta
      let request :PublicPublicationsQueryModel = {
        entityId: this.store.snapshot().Entity.entity.id,
        limit: LIMIT,
        page: 0,
        competitionId: input.publicationsQuery.competitionId,
        teamId: input.publicationsQuery.teamId,
        matchId: input.publicationsQuery.matchId,
        tags: input.publicationsQuery.tags
      }
      this.publicationService.getPublicPublications(request).subscribe((data:[Publication]) =>{
        patchState({
          showMore: ((data || []).length < LIMIT) ? false : true,
          publications: data,
        });
      });
  }

  @Action(GetPublicPublicationsWidget)
   getPublicpublicationsWidget({ patchState, getState, dispatch }: StateContext<PublicationStateModel>, input:GetPublicPublicationsWidget){
      //Preparamos la consulta
      let request :PublicPublicationsQueryModel = {
        entityId: this.store.snapshot().Entity.entity.id,
        limit: 3,
        page: 0
      }
      this.publicationService.getPublicPublications(request).subscribe((data:[Publication]) =>{
        patchState({
          publicationsWidget: data,
        });
      });
  }

  @Action(GetMorePublicPublications)
   getMorePublicPublications({ patchState, getState, dispatch }: StateContext<PublicationStateModel>, input:GetMorePublicPublications){

      //Preparamos la consulta
      let payload :PublicPublicationsQueryModel = {
        entityId: this.store.snapshot().Entity.entity.id,
        limit: LIMIT,
        lastId: getState().publications.slice(-1)[0].id,
        competitionId: input.publicationsQuery.competitionId,
        teamId: input.publicationsQuery.teamId,
        matchId: input.publicationsQuery.matchId,
        tags: input.publicationsQuery.tags,
        page: getState().page + 1
      }
      this.publicationService.getPublicPublications(payload).subscribe((data:[Publication]) =>{
        const publications: Publication[] = [...(getState().publications || []), ...(data || [])];
        patchState({
          publications: publications,
          showMore: ((data || []).length < LIMIT) ? false : true,
          page: getState().page + 1
        });

      });

  }

  @Action(SetPublicCurrentPublication)
  setPublicCurrentPublication({ patchState, getState, dispatch }: StateContext<PublicationStateModel>, payload:SetPublicCurrentPublication){
        patchState({
          currentPublication: payload.publication
        });
  }

  @Action(GetPublicCurrentPublication)
  getPublicCurrentPublication({ patchState, getState, dispatch }: StateContext<PublicationStateModel>, payload:GetPublicCurrentPublication){

      let publication = null
      if(getState().publications){
        publication = getState().publications.find(item => item.urlTitle === payload.urlTitle);
      }

      if(publication){
        patchState({
          currentPublication: publication,
        });
      }
      
      let request_payload :PublicPublicationQueryModel = {
        entityId: this.store.snapshot().Entity.entity.id,
        urlTitle: payload.urlTitle
      }

      this.publicationService.getPublicPublication(request_payload).subscribe((data:any) =>{

        if(data != null){
          patchState({
            currentPublication: data,
          });
        }else{
          this.ngZone.run(() => {
            this.router.navigate([this.sharedService.buildRoute(['error', '404'])]);
          });
        }
      },
      err => {
        this.ngZone.run(() => {
          this.router.navigate([this.sharedService.buildRoute(['error', '404'])]);
        });
      }
      );
      
  }
}
