



    <div class="custom-toggle" fxLayout="row" fxLayoutAlign="space-between center">
      <div align="left">
        <div>{{ field.templateOptions.label }}</div>
<!--         <span class="f-s-12">{{ field.templateOptions.description }}</span> -->
      </div>
      <mat-slide-toggle [(ngModel)]="isChecked" (change)="handleChange($event)"></mat-slide-toggle>
    </div>





