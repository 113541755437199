import { Entity } from './../../graphql/interfaces/entityModel';
import { EntityService } from 'app/graphql/services/entity.service';
import { GetPublicEntity } from './../actions/entity.actions';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from '@shared/services/secure-storage.service';
import { TypeService } from 'app/graphql/services/type.service';
import { TypesModel } from 'app/graphql/interfaces/typesModel';

export interface EntityStateModel {
  entity: Entity;
  types: [TypesModel];
}

@Injectable()
@State<EntityStateModel>({
  name: 'Entity',
  defaults:{
    entity: null,
    types: null
  },
})

export class EntityState {
  constructor(
    private entityService: EntityService,
    private typeService: TypeService,
    public ngZone: NgZone,
    public router: Router,
    public storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ){}

  @Selector()
  static entity(state: EntityStateModel){
    return state.entity;
  }

  @Selector()
  static typesData(state: EntityStateModel){
    return state.types;
  }

   @Action(GetPublicEntity)
   getPublicEntity({ patchState, getState, dispatch }: StateContext<EntityStateModel>, payload:GetPublicEntity){
      this.entityService.getPublicEntity(payload.web_domain).subscribe((entity:Entity) =>{
        this.typeService.getTypes(entity.id).subscribe((types:any) =>{
          patchState({
            types: types,
            entity: entity,
          });
        });
      });
  }



}
