import { Member, QueryExtraCompetitionArgs, QueryMembersParticipatingCompetitionArgs, QueryTeamsParticipatingCompetitionArgs, Team } from './../../graphql/types';

import { CompetitionService } from '../../graphql/services/competition.service';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from '@shared/services/secure-storage.service';
import { Competition, QueryPublicCompetitionsArgs } from 'app/graphql/types';
import { GetPublicCompetitions, GetPublicCurrentCompetition } from '../actions/competitions.actions';
import { LIMIT, TYPE_COMPETITION_TEAM } from '@shared/constants';


export interface CompetitionStateModel {
  competitions: Competition[];
  currentCompetition: Competition;
  currentCompetitionTeams : Team[];
  currentCompetitionMembers : Member[];
  page: number;
}

@Injectable()
@State<CompetitionStateModel>({
  name: 'Competition',
  defaults:{
    competitions: null,
    currentCompetition: null,
    currentCompetitionTeams: [],
    currentCompetitionMembers: [],
    page: 0
  },
})

export class CompetitionState {
  constructor(
    private competitionService: CompetitionService,
    public ngZone: NgZone,
    public router: Router,
    private store: Store,
    public storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ){}

  @Selector()
  static competitions(state: CompetitionStateModel){
    return state.competitions;
  }

  @Selector()
  static currentCompetition(state: CompetitionStateModel){
    return state.currentCompetition;
  }

  @Selector()
  static currentCompetitionTeams(state: CompetitionStateModel){
    return state.currentCompetitionTeams;
  }

  @Selector()
  static currentCompetitionMembers(state: CompetitionStateModel){
    return state.currentCompetitionMembers;
  }

   @Action(GetPublicCompetitions)
   getPublicCompetitions({ patchState, getState, dispatch }: StateContext<CompetitionStateModel>, {seasonId}:GetPublicCompetitions){

      //Preparamos la consulta
      let payload :QueryPublicCompetitionsArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
        seasonId: seasonId
      }
      this.competitionService.getPublicCompetitions(payload).subscribe((data:any) =>{
        patchState({
          competitions: data,
        });
      });

  }

  @Action(GetPublicCurrentCompetition)
  getPublicCurrentPublication({ patchState, getState, dispatch }: StateContext<CompetitionStateModel>, payload:GetPublicCurrentCompetition){

    let competition: Competition = null
    if(getState().competitions){
      competition = getState().competitions.find(item => item.id === payload.id);
    }

    if(competition){
      patchState({
        currentCompetition: competition,
      });
    }

    //Preparamos la consulta
    let requestPayload :QueryExtraCompetitionArgs = {
      entityId: this.store.snapshot().Entity.entity.id,
      competitionId: payload.id
    }

    this.competitionService.getPublicExtraCompetition(requestPayload).subscribe((data:Competition) =>{
      patchState({
        currentCompetition: data,
      });
      if(data.typeId == TYPE_COMPETITION_TEAM){
        //Preparamos la consulta de participantes
        let requestPayloadParticipants :QueryTeamsParticipatingCompetitionArgs = {
          entityId: this.store.snapshot().Entity.entity.id,
          competitionId: payload.id
        }
        this.competitionService.getTeamsParticipatingCompetition(requestPayloadParticipants).subscribe((participants:any) =>{
          patchState({
            currentCompetitionMembers: [],
            currentCompetitionTeams: participants,
          });
        });
      }else{
        //Preparamos la consulta de participantes
        let requestPayloadParticipants :QueryMembersParticipatingCompetitionArgs = {
          entityId: this.store.snapshot().Entity.entity.id,
          competitionId: payload.id
        }
        this.competitionService.getMembersParticipatingCompetition(requestPayloadParticipants).subscribe((participants:any) =>{
          patchState({
            currentCompetitionMembers: participants,
            currentCompetitionTeams: [],
          });
        });
      }
    });








  }
}
