import { GetPublicCurrentTeam, GetPublicTeams, SetPublicCurrentTeam } from './../actions/team.actions';
import { TeamService } from './../../graphql/services/team.service';
import { PublicListTeamMembersQueryModel, PublicTeamQueryModel, PublicTeamsQueryModel } from './../../graphql/interfaces/teamModel';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from '@shared/services/secure-storage.service';
import {  Team, TeamMember } from 'app/graphql/types';
import { SharedService } from '@shared/services/shared.service';


export interface TeamStateModel {
  teams: Team[];
  currentTeam: Team;
  players: TeamMember[];
  coachingStaff: TeamMember[];
  collaborators:TeamMember[];
  page: number;
}

@Injectable()
@State<TeamStateModel>({
  name: 'Team',
  defaults:{
    teams: null,
    currentTeam: null,
    players: [],
    coachingStaff: [],
    collaborators:[],
    page: 0
  },
})

export class TeamState {
  constructor(
    private teamService: TeamService,
    public ngZone: NgZone,
    public router: Router,
    private store: Store,
    private sharedService: SharedService,
    public storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ){}

  @Selector()
  static teams(state: TeamStateModel){
    return state.teams;
  }

  @Selector()
  static players(state: TeamStateModel){
    return state.players;
  }

  @Selector()
  static coachingStaff(state: TeamStateModel){
    return state.coachingStaff;
  }

  @Selector()
  static collaborators(state: TeamStateModel){
    return state.collaborators;
  }

  @Selector()
  static currentTeam(state: TeamStateModel){
    return state.currentTeam;
  }

   @Action(GetPublicTeams)
   getPublicTeams({ patchState, getState, dispatch }: StateContext<TeamStateModel>, {seasonId}:GetPublicTeams){

      //Preparamos la consulta
      let payload :PublicTeamsQueryModel = {
        entityId: this.store.snapshot().Entity.entity.id,
        seasonId: seasonId
      }
      this.teamService.getPublicTeams(payload).subscribe((data:any) =>{
        patchState({
          teams: data,
        });
      });

  }

  @Action(SetPublicCurrentTeam)
  setPublicCurrentTeam({ patchState, getState, dispatch }: StateContext<TeamStateModel>, payload:SetPublicCurrentTeam){

        patchState({
          currentTeam: payload.team,
          players: [],
          coachingStaff: [],
          collaborators:[],
        });

        if(payload.team.teamTemplate[0].visibleOnWeb){

          //Recuperamos cuerpo técnico
          let request :PublicListTeamMembersQueryModel = {
            entityId: this.store.snapshot().Entity.entity.id,
            teamId: payload.team.id,
            teamTemplateId: payload.team.teamTemplate[0].id,
            isPlayers: true,
            isCoachingStaff: true,
            isCollaborators: true
          }

          this.teamService.getPublicListTeamMembers(request).subscribe((data:any) =>{
            if(data != null){
              patchState({
                players: data.players,
                coachingStaff: data.coachingStaff,
                collaborators: data.collaborators,
              });
            }
          });
        }
  }

  @Action(GetPublicCurrentTeam)
  getPublicCurrentPublication({ patchState, getState, dispatch }: StateContext<TeamStateModel>, payload:GetPublicCurrentTeam){

      let team: Team = null
      if(getState().teams){
        team = {...getState().teams.find(item => item.id === payload.id)};
      }

      if(team){
        team.teamTemplate = team.teamTemplate.filter(teamTemplate => teamTemplate.seasonId === payload.seasonId);
        dispatch(new SetPublicCurrentTeam(team));
      }else{
        let request_payload :PublicTeamQueryModel = {
          entityId: this.store.snapshot().Entity.entity.id,
          teamId: payload.id
        }
        this.teamService.getPublicTeam(request_payload).subscribe((data:Team) =>{
          if(data != null){
            //dejamos solo la temporada que nos interesa
            data.teamTemplate = data.teamTemplate.filter(teamTemplate => teamTemplate.seasonId === payload.seasonId);
            dispatch(new SetPublicCurrentTeam(data));
          }else{
            this.ngZone.run(() => {
              this.router.navigate([this.sharedService.buildRoute(['error', '404'])]);
            });
          }
        },
        err => {
          this.ngZone.run(() => {
            this.router.navigate([this.sharedService.buildRoute(['error', '404'])]);
          });
        }
        );
      }

  }
}
