import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '@core/bootstrap/menu.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() nav: any[] = [];
  @Input() detail: string;

  constructor(private router: Router, private menuService: MenuService, private localize: LocalizeRouterService) {}

  ngOnInit() {
    this.generateBreadCrumb();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.detail) {
      this.generateBreadCrumb();
    }
  }

  generateBreadCrumb(){
    this.nav = [];

    if (this.nav.length === 0) {
      this.genBreadcrumb();
    }

    if(this.detail){
      this.nav.push({name:this.detail});
    }
  }

  trackByNavlink(index: number, navlink: string): string {
    return navlink;
  }

  genBreadcrumb() {
    const states = this.router.url.slice(1).split('/');
    this.nav = this.menuService.getMenuLevel(states);
    this.nav.unshift({name:'menu.home'});

    this.nav.forEach(item => {
      if(item.route){
        item.route = this.localize.translateRoute(item.route);
      }
    })
  }
}
