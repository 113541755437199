import { LIMIT } from '../../shared/constants';
import { GetMultimediaCompetitions, GetMultimediaGalleries, GetMultimediaTabs, GetMultimediaTeams, GetPublicCurrentMultimedia, GetPublicMultimedia} from '../actions/multimedia.actions';

import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from '@shared/services/secure-storage.service';
import { Multimedia, MultimediaTabs, QueryMultimediaCompetitionsArgs, QueryPublicMultimediaArgs } from 'app/graphql/types';
import { MultimediaService } from 'app/graphql/services/multimedia.service';
import { SharedService } from '@shared/services/shared.service';

export interface MultimediaStateModel {
  multimedia: Multimedia[];
  multimediaGalleries: Multimedia[];
  multimediaTeams: Multimedia[];
  multimediaCompetitions: Multimedia[];
  currentMultimedia: Multimedia;
  showMore: Boolean;
  showCompetitions: Boolean;
  showGalleries: Boolean;
  showTeams: Boolean;
  showMatches: Boolean;
  page: number;
}

@Injectable()
@State<MultimediaStateModel>({
  name: 'Multimedia',
  defaults:{
    showMore:false,
    showCompetitions:false,
    showGalleries:false,
    showTeams:false,
    showMatches:false,
    multimedia: null,
    multimediaGalleries: null,
    multimediaTeams: null,
    multimediaCompetitions: null,
    currentMultimedia: null,
    page: 0
  },
})

export class MultimediaState {
  constructor(
    private multimediaService: MultimediaService,
    public ngZone: NgZone,
    public router: Router,
    private store: Store,
    private sharedService: SharedService,
    public storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ){}

  @Selector()
  static multimedia(state: MultimediaStateModel){
    return state.multimedia;
  }

  @Selector()
  static multimediaGalleries(state: MultimediaStateModel){
    return state.multimediaGalleries;
  }

  @Selector()
  static multimediaTeams(state: MultimediaStateModel){
    return state.multimediaTeams;
  }

  @Selector()
  static multimediaCompetitions(state: MultimediaStateModel){
    return state.multimediaCompetitions;
  }
  

  @Selector()
  static showMore(state: MultimediaStateModel){
    return state.showMore;
  }

  @Selector()
  static currentMultimedia(state: MultimediaStateModel){
    return state.currentMultimedia;
  }

  @Selector()
  static multimediaTabs(state: MultimediaStateModel){
    return {
      showCompetitions: state.showCompetitions,
      showGalleries: state.showGalleries,
      showTeams: state.showTeams,
      showMatches: state.showMatches,
    }
  }


  @Action(GetPublicMultimedia)
  getPublicmultimedia({ patchState, getState, dispatch }: StateContext<MultimediaStateModel>){
      //Preparamos la consulta
      let request :QueryPublicMultimediaArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
      }
      this.multimediaService.getPublicMultimedia(request).subscribe((data:[Multimedia]) =>{
        patchState({
          showMore: ((data || []).length < LIMIT) ? false : true,
          multimedia: data,
        });
      });
  }

  @Action(GetMultimediaGalleries)
   getMultimediaGalleries({ patchState, getState, dispatch }: StateContext<MultimediaStateModel>){
      //Preparamos la consulta
      let request :QueryPublicMultimediaArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
      }
      this.multimediaService.getMultimediaGalleries(request).subscribe((data:[Multimedia]) =>{
        patchState({
          showMore: ((data || []).length < LIMIT) ? false : true,
          multimediaGalleries: data,
        });
      });
  }

  @Action(GetMultimediaTeams)
   getMultimediaTeams({ patchState, getState, dispatch }: StateContext<MultimediaStateModel>, payload:GetMultimediaTeams){
      //Preparamos la consulta
      let request :QueryMultimediaCompetitionsArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
        seasonId: payload.seasonId
      }
      this.multimediaService.getMultimediaTeams(request).subscribe((data:[Multimedia]) =>{
        patchState({
          showMore: ((data || []).length < LIMIT) ? false : true,
          multimediaTeams: data,
        });
      });
  }

  @Action(GetMultimediaCompetitions)
   getMultimediaCompetitions({ patchState, getState, dispatch }: StateContext<MultimediaStateModel>, payload:GetMultimediaTeams){
      //Preparamos la consulta
      let request :QueryMultimediaCompetitionsArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
        seasonId: payload.seasonId
      }
      this.multimediaService.getMultimediaCompetitions(request).subscribe((data:[Multimedia]) =>{
        patchState({
          showMore: ((data || []).length < LIMIT) ? false : true,
          multimediaCompetitions: data,
        });
      });
  }
  

  @Action(GetMultimediaTabs)
   getmultimediaTabs({ patchState, getState, dispatch }: StateContext<MultimediaStateModel>){
      //Preparamos la consulta
      let request :QueryPublicMultimediaArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
      }
      this.multimediaService.getMultimediaTabs(request).subscribe((data:MultimediaTabs) =>{
        patchState({
          showCompetitions: data.showCompetitions,
          showGalleries: data.showGalleries,
          showMatches: data.showMatches,
          showTeams: data.showTeams
        });
      });
  }

  @Action(GetPublicCurrentMultimedia)
  GetPublicCurrentMultimedia({ patchState, getState, dispatch }: StateContext<MultimediaStateModel>, payload:GetPublicCurrentMultimedia){

    let currentMultimedia = null
    if(getState().multimedia){
      currentMultimedia = getState().multimedia.find(item => item.id === payload.id);
    }

    if(currentMultimedia){
      patchState({
        currentMultimedia: currentMultimedia,
      });
    }else{
      let request :QueryPublicMultimediaArgs = {
        entityId: this.store.snapshot().Entity.entity.id,
      }
      this.multimediaService.getPublicMultimedia(request).subscribe((data:[Multimedia]) =>{
        currentMultimedia = data.find(item => item.id === payload.id);
        if(currentMultimedia){
          patchState({
            multimedia: data,
            currentMultimedia: currentMultimedia,
          });
        }else{
          this.ngZone.run(() => {
            this.router.navigate([this.sharedService.buildRoute(['error', '404'])]);
          });
        }
      });
    }
  }
}
