import { Multimedia, QueryPublicMultimediaArgs } from "app/graphql/types";

export class GetPublicMultimedia {
  static readonly type = '[MULTIMEDIA] Get public multimedia';
  constructor(){}
}

export class GetMultimediaTabs {
  static readonly type = '[MULTIMEDIA] Get public tabs multimedia';
  constructor(){}
}

export class GetMultimediaGalleries {
  static readonly type = '[MULTIMEDIA] Get public galleries multimedia';
  constructor(){}
}

export class GetMultimediaTeams {
  static readonly type = '[MULTIMEDIA] Get public teams multimedia';
  constructor(public readonly seasonId: string){}
}

export class GetMultimediaCompetitions {
  static readonly type = '[MULTIMEDIA] Get public competitions multimedia';
  constructor(public readonly seasonId: string){}
}


export class GetPublicCurrentMultimedia {
  static readonly type = '[MULTIMEDIA] Get public current multimedia';
  constructor(public readonly id: string){}
}


