<div class="timer" *ngIf="(daysToDday + hoursToDday + minutesToDday + secondsToDday) >= 0">
  <h4> {{ title | translate }} </h4>
  <span id="days"> {{daysToDday}} </span>{{ 'general.days' | translate }}
  <span id="hours"> {{hoursToDday}} </span>{{ 'general.hours' | translate }}
  <span id="minutes"> {{minutesToDday}} </span>{{ 'general.minuts' | translate }}
  <span id="seconds"> {{secondsToDday}} </span>{{ 'general.sec' | translate }}
</div>
<div class="timer" *ngIf="(daysToDday + hoursToDday + minutesToDday + secondsToDday) < 0">
  <h4> {{ finish_text | translate }} </h4>
</div>
