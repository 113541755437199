export const teamResponse = `
  id
  entityId
  name
  categoryId
  sportId
  shield
  favourite
  teamTemplate {
    id
    seasonId
    visibleOnWeb
    image
  }
`;

export const teamDetailResponse = `
  id
  entityId
  name
  categoryId
  sportId
  shield
  favourite
  customization {
    customId
    value
  }
  equipment {
    id
    shirtColor
    pantsColor
    stockingsColor
  }
  teamTemplate {
    id
    seasonId
    visibleOnWeb
    image
    multimedia{
      id
      name
      public
      active
      anyone
      items {
        id
        url
        userId
      }
    }
  }
`;

export const memberResponse =
`
  id
  entityId
  image
  firstName
  lastName
`;

export const competitionResponse =
`
  id
  entityId
  typeId
  seasonId
  name
  externalLink
  categoryId
  sportId
  icon
  favourite
  private
  multimedia{
    id
    name
    public
    active
    anyone
    items {
      id
      url
      userId
    }
  }
`;

export const matchResponse =
`
  id
  localId
  visitorId
  statusId
  date
  localResult
  url
  visitorResult
  matchDayId
  externalReference
  typeId
  competition {
    id
    name
    entityId
    typeId
    seasonId
    categoryId
    sportId
    icon
    externalLink
    favourite
    private
    matchDays {
      id
      startDate
      finishDate
      detail
    }
  }
  localTeam {
    id
    name
    shield
    categoryId
    sportId
  }
  visitorTeam {
    id
    name
    shield
    categoryId
    sportId
  }
  localMember {
    id
    firstName
    lastName
    image
  }
  visitorMember {
    id
    firstName
    lastName
    image
  }
  multimedia{
    id
    name
    public
    active
    anyone
    items {
      id
      url
      userId
    }
  }
  location{
    id
    entityId
    name
    capacity
    geolocation{
      latitude
      longitude
      formattedAddress
    }
  }
`;

export const publicationResponse =
`
  id
  entityId
  mainImage
  title
  urlTitle
  body
  active
  public
  numComments
  seasonId
  galleryImages
  matchComponent {
    fromDate
    toDate
    competitionId
  }
  geolocation{
    id
    latitude
    longitude
    formattedAddress
  }
  tags{
    id
    name
    color
  }
  event{
    id
    description
    startDate
    finishDate
    allowParticipation
  }
  poll{
    id
    description
    finishDate
    options
  }
  form {
    id
  }
  team{
    id
    entityId
    name
    categoryId
    sportId
    shield
    favourite
    teamTemplate {
      id
      seasonId
      image
    }
  }
  competition{
    id
    entityId
    typeId
    seasonId
    name
    categoryId
    sportId
    icon
    favourite
    private
    externalLink
  }
  match{
    id
    localId
    visitorId
    statusId
    date
    localResult
    url
    visitorResult
    matchDayId
    externalReference
    typeId
    competition {
      id
      name
      entityId
      typeId
      seasonId
      categoryId
      sportId
      icon
      externalLink
      favourite
      private
      matchDays {
        id
        startDate
        finishDate
        detail
      }
    }
    localTeam {
      id
      name
      shield
      categoryId
      sportId
    }
    visitorTeam {
      id
      name
      shield
      categoryId
      sportId
    }
    localMember {
      id
      firstName
      lastName
      image
    }
    visitorMember {
      id
      firstName
      lastName
      image
    }
  }
  canComment
  date
  scheduledDate
  expirationDate
  `;

export const publicationDetailResponse =
`
  id
  entityId
  mainImage
  title
  urlTitle
  body
  active
  public
  numComments
  seasonId
  galleryImages
  matchComponent {
    fromDate
    toDate
    competitionId
    matches {
      `+matchResponse+`
    }
  }
  geolocation{
    id
    latitude
    longitude
    formattedAddress
  }
  tags{
    id
    name
    color
  }
  event{
    id
    description
    startDate
    finishDate
    allowParticipation
    participation {
      id
      userId
      date
    }
    numAnswers
  }
  poll{
    id
    description
    finishDate
    options
    answers {
      id
      userId
      date
      answer
    }
  }
  form {
    id
    customFields{
      id
      typeId
      name
      nameKey
      require
      listOfValours
    }
  }
  team{
    id
    entityId
    name
    categoryId
    sportId
    shield
    favourite
    teamTemplate {
      id
      seasonId
      image
    }
  }
  competition{
    id
    entityId
    typeId
    seasonId
    name
    categoryId
    sportId
    icon
    favourite
    private
    externalLink
  }
  match{
    `+matchResponse+`
  }
  canComment
  date
  scheduledDate
  expirationDate
  `;
