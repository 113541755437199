import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: 'dialog-confirmation.component.html',
})
export class DialogConfirmationComponent {
  title: string = '';
  message: string = '';
  confirmButtonText = this.translate.instant('form.accept');
  cancelButtonText = this.translate.instant('form.cancel');
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any, private translate: TranslateService,
    private dialogRef: MatDialogRef<DialogConfirmationComponent>) {
      if(data){
        this.message = data.message || this.message;
        this.title = data.title || this.title;
        if (data.buttonText) {
          this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
          this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        }
      }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
