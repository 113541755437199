import { PublicationService } from '../../../../graphql/services/publication.service';
import { GetPublicCurrentPublication, SetPublicCurrentPublication } from '../../../../redux/actions/publication.actions';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DEAFULT_IMAGE_PUBLICATION } from '../../../constants';
import { SharedService } from '../../../services/shared.service';
import { PublicationState } from '../../../../redux/state/publication.state';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetMorePublicPublications, GetPublicPublications } from 'app/redux/actions/publication.actions';
import { Poll, Publication } from 'app/graphql/interfaces/publicationModel';
import { Observable } from 'rxjs';
import { DEAFULT_IMAGE_ENTITY } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityState } from 'app/redux/state/entity.state';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@UntilDestroy()
@Component({
  selector: 'app-poll',
  styleUrls: ['./poll.component.scss'],
  templateUrl: './poll.component.html',
})
export class PollComponent implements OnInit, OnChanges {

  @Input() poll: Poll;
  @Input() publicationId: string;

  body = null;
  defaultImagePublication = DEAFULT_IMAGE_PUBLICATION;
  entity: Entity = this.store.snapshot().Entity.entity;

  show_vote_button = true;
  poll_ended = false;
  selected_option: number;
  dateStr: string;
  selectedValue: number = null;
  color = null;
  total_votes = 0;
  voted = false;
  loading = false;

  options: any[] = [];

  constructor(private store: Store,private sharedService: SharedService,public translate: TranslateService,private _snackBar: MatSnackBar,private publicationService: PublicationService,  private http: HttpClient, private titleService: Title, private metaService: Meta, private sanitizer: DomSanitizer, private route: ActivatedRoute, private translateService: TranslateService) {
    this.color = this.checkLuminance(this.entity.primaryColor);
    this.setCheckboxBackgroundColor();
  }

  ngOnInit() {
    this.initSelectionValue();
    this.total_votes = this.poll.answers.length;
    this.options = [];
    this.poll.options.forEach(option => {
      this.options.push({option:option, votes:this.poll.answers.filter(answer=>answer.answer == option).length})
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.poll) {
      this.initSelectionValue();
      this.total_votes = this.poll.answers.length;
      this.options = [];
      this.poll.options.forEach(option => {
        this.options.push({option:option, votes:this.poll.answers.filter(answer=>answer.answer == option).length})
      })
    }

  }

  private setCheckboxBackgroundColor() {
    const root = document.documentElement;
    if (this.color) {
      root.style.setProperty('--radio-bg-color', this.color);
      root.style.setProperty('--shadow-bg-color', this.rgba2rgb(this.hex2rgba(this.color, '.10')));
      root.style.setProperty('--selected-bg-color', this.rgba2rgb(this.hex2rgba(this.color, '.30')));
    }
  }

  initSelectionValue() {
    const polls = JSON.parse(localStorage.getItem('Polls')) || [];
    if(polls.length){
      const option = polls.find(item => item.id === this.poll.id);
      if(option && option.option){
        this.show_vote_button = false;
        this.voted = true;
        for (let idx = 0; idx < this.poll.options.length; idx++) {
          if (this.poll.options[idx] == option.option) this.selectedValue = idx;
        }
      }
    }
  }

  selectOption(idx: number) {
    /* if (Boolean(this.voted)) return; */
    /* for (const option of this.poll.options) {
      option.selected = 0;
    } */

    this.selected_option = Number(this.poll.options[idx]);
  }

  vote() {
    if (this.selectedValue !== null && this.poll.options[this.selectedValue]) {
      this.loading = true;

      this.publicationService.publicCreateOrUpdatePollAnswer({entityId:this.entity.id, answer: this.poll.options[this.selectedValue], publicationId: this.publicationId}).subscribe(( data:any ) => {
        //Actualizamos el state
        this.store.dispatch(new SetPublicCurrentPublication(data));
        this._snackBar.open(this.translate.instant('publication.success_vote'), null, {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
        this.show_vote_button = false;
          /* this.setPercentage(true); */
          this.voted = true;
          const polls = JSON.parse(localStorage.getItem('Polls')) || [];
          polls.push({option:this.poll.options[this.selectedValue], id:this.poll.id})
          localStorage.setItem('Polls',JSON.stringify(polls));
        this.loading = false;
      },(error) => {
        this.loading = false;
      });

    }
  }

  wrapperSelectOption(idx: number): void {
    if (!this.poll_ended && this.show_vote_button) this.selectedValue = idx;
  }

  /* private setPercentage(shouldIncrement = false) {
    if (this.poll && this.options) {
      if (shouldIncrement) {
        this.poll.options[this.selectedValue].votes += 1;
      }
      const totalVotes = this.poll.options.map((option) => option.votes).reduce((a, b) => a + b, 0);
      for (const option of this.poll.options) {
        option.pct = totalVotes > 0 && option.votes > 0 ? (option.votes * 100) / totalVotes : 0;
      }
    }
  } */

  getVotesGradient(idx: number) {
    let gradient = new Object();
    if (this.poll && this.poll.options && this.poll.options[idx]) {
      let color: string;
      if (idx == this.selectedValue) {
        color = this.rgba2rgb(this.hex2rgba(this.color, 0.3));
      } else {
        color = this.rgba2rgb(this.hex2rgba(this.color, 0.1));
      }
      const percent = this.total_votes > 0 && this.options[idx].votes > 0 ? (this.options[idx].votes * 100) / this.total_votes : 0;
      gradient = {
        background: `linear-gradient(to right, ${color} ${percent}%, rgba(255,255,255,0) ${percent}%)`,
        filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='${this.color}', endColorstr='#ffffff', GradientType=1 `,
        border: `1px  ${color} solid !important`,
      };
      /*
      NOTE: Revisar el gradiente para todos los navegadores.

      'background': `-moz-linear-gradient(left, ${color} ${percent}%, rgba(255,255,255,0) ${percent}%)`,
      'background': `-webkit-gradient(left top, right top, color-stop(${percent}%, ${color}), color-stop(${percent}%, rgba(255,255,255,0)))`,
      'background': `-webkit-linear-gradient(left, ${color} ${percent}%, rgba(255,255,255,0) ${percent}%)`,
      'background': `-o-linear-gradient(left, ${color} ${percent}%, rgba(255,255,255,0) ${percent}%)`,
      'background': `-ms-linear-gradient(left, ${color} ${percent}%, rgba(255,255,255,0) ${percent}%)`,
      */
    }
    return gradient;
  }

  private hex2rgba(hex, opacity) {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      // tslint:disable-next-line: no-bitwise
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
    }
    throw new Error('Bad Hex');
  }

  private rgba2rgb(rgbaColor) {
    const rgbaColorSplitted = this.splitRgbaColor(rgbaColor);
    const rgbaBackground = [255, 255, 255];

    if (rgbaColorSplitted.length < 1 || rgbaColorSplitted.length > 4) return `rgb(255,255,255)`;
    const alpha = rgbaColorSplitted[3];
    return `rgb(${(1 - alpha) * rgbaBackground[0] + alpha * rgbaColorSplitted[0]}, ${
      (1 - alpha) * rgbaBackground[1] + alpha * rgbaColorSplitted[1]
    }, ${(1 - alpha) * rgbaBackground[2] + alpha * rgbaColorSplitted[2]})`;
  }

  private splitRgbaColor(rgbaColor): Array<number> {
    const sep = rgbaColor.indexOf(',') > -1 ? ',' : ' ';
    const colorSplitted = rgbaColor.substr(5).split(')')[0].split(sep);
    // Strip the slash if using space-separated syntax
    if (colorSplitted.indexOf('/') > -1) {
      colorSplitted.splice(3, 1);
    }

    // tslint:disable-next-line: forin
    for (const R in colorSplitted) {
      const r = colorSplitted[R];
      if (r.indexOf('%') > -1) {
        const p = r.substr(0, r.length - 1) / 100;
        if (Number(R) < 3) {
          colorSplitted[R] = Number(Math.round(p * 255));
        } else {
          colorSplitted[R] = Number(p);
        }
      }
    }
    return colorSplitted;
  }

  checkLuminance(color: string): string {
    let resp = 'white';
    const rgb = this.hexToRgb(color);
    if (rgb) {
      const luminance = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
      resp = luminance < 190 ?color : '#666666'  ;
    }
    return resp;
  }

  hexToRgb(hex?: string) {
    const color = hex || localStorage.getItem('client_color');
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

}






