import gql from 'graphql-tag';

export const typesQuery = `
  query types ($entityId: String) {
    types( entityId: $entityId ) {
      type
      id
      name
    	langKey
    	image
      entityId
    }
  }
`;
