
<mat-card class="publication-form" fxFlex="100" fxFlex.gt-sm="100">
  <mat-card-title class="publication-card">
    <mat-icon matTooltip="{{ 'publication.form' | translate }}">list_alt</mat-icon>
    &nbsp;
  </mat-card-title>
  <mat-card-content>
    <div style="width:100%">
      <form [formGroup]="form" (ngSubmit)="submitForm(formDirective)" #formDirective="ngForm">
        <formly-form [model]="model_form" [fields]="fields" [options]="options" [form]="form">
        </formly-form>
      <button  mat-flat-button color="accent" [class.btn_spinner]="loading" [disabled]="loading || !form.valid">{{ 'publication.send' | translate }}</button>
      <div style="color:#c1c1c1;font-size: 0.8rem;text-align: justify;" *ngIf="content" class="page-content" fxLayout="row wrap" [innerHTML]="content"></div>
    </form>
    </div>
  </mat-card-content>
</mat-card>


