import { environment } from "@env/environment";

export const ENDPOINT= environment.SERVER_URL;
export const IMAGES_ENDPOINT= environment.IMAGES_URL;
export const GS_BUCKET= environment.GS_BUCKET;
export const IMG_SALT= "79756c617661";
export const IMG_KEY= "79756c6176617375706572736563726574";
export const API_STORAGE_DIRECTORY = "yulava/";
export const IMAGE_TRANSFORM_ENDPOINT= ENDPOINT+'/functions/imageTransform/';
export const DEAFULT_IMAGE_PROFILE =  API_STORAGE_DIRECTORY+"default/default_image_profile.png";
export const DEAFULT_IMAGE_ENTITY =   API_STORAGE_DIRECTORY+"default/default_image_entity.png";
export const DEAFULT_IMAGE_PUBLICATION =   API_STORAGE_DIRECTORY+"default/svg/default_publications.svg";
export const DEAFULT_IMAGE_TEAM =   API_STORAGE_DIRECTORY+"default/svg/default_teams.svg";
export const DEAFULT_SHIELD_TEAM =   API_STORAGE_DIRECTORY+"default/default_shield_team.png";
export const DEAFULT_SHIELD_COMPETITION =   API_STORAGE_DIRECTORY+"default/default_icon_competition.png";
export const URI_STORAGE_PROFILE = API_STORAGE_DIRECTORY + "users/profile/";
export const URI_STORAGE_ENTITY = API_STORAGE_DIRECTORY + "entities/image/";
export const URI_STORAGE_DOCUMENT = API_STORAGE_DIRECTORY + "entities/[idEntity]/document/";

//Image sizes
export const IMAGE_XS = 'width=120&height=120';
export const IMAGE_S = 'width=265&height=265';
export const IMAGE_M = 'width=600&height=600';
export const IMAGE_L = 'width=1200&height=1200';
export const IMAGE_XL = 'width=1920&height=1920';
export const IMAGE_PROXY_XS = ':120:120';
export const IMAGE_PROXY_S = ':265:265';
export const IMAGE_PROXY_M = ':600:600';
export const IMAGE_PROXY_L = ':1200:1200';
export const IMAGE_PROXY_XL = ':1920:1920';

//Menú transparente
export const TYPE_MENU_TRANSPARENT = "611564e4e0f4f93f104a0376";

// Tipos
export const TYPE_ENTITY = "ENTITY";
export const TYPE_SOCIAL = "SOCIAL";

export const TYPE_COMPETITION_TEAM = "600aab5eedb4803c5265f2a0";

export const MODULE_TEAM =  "5e9ae8d82d47302b981a83bc";
export const MODULE_MEMBER = "5e9ae8be2d47302b981a83bb";
export const MODULE_COMUNICATION = "5e862dfdc331bf12200f0e2a";
export const MODULE_WEB = "5fd5fbe021a1ad1141cb3db4";
export const MODULE_COMPETITION = "6002b6fe1f045c01ed9e860d";

//ALIGN
export const TYPE_ALIGN_CENTER = "614f45a37557d3428ce2d3de";
export const TYPE_ALIGN_LEFT = "614f45997557d3428ce2d3dd";
export const TYPE_ALIGN_RIGHT = "614f45847557d3428ce2d3dc";

export const TYPE_ALIGN_VERTICAL_CENTER = "63504a2cec86f8da955e00f6";
export const TYPE_ALIGN_VERTICAL_TOP = "63504a06ec86f8da955e00f2";
export const TYPE_ALIGN_VERTICAL_BOTTOM = "63504a1aec86f8da955e00f4";

// TIPO FORM
export const TYPE_FORM_TEXT = "5ed3b2a72ac0154f804ce1d4";
export const TYPE_FORM_TEXT_AREA = "5ed3b2b82ac0154f804ce1d5";
export const TYPE_FORM_CHECK = "5ed3b2c52ac0154f804ce1d6";
export const TYPE_FORM_COLOR = "5ed55604db9b03310c6ed638";
export const TYPE_FORM_NUMBER = "5ed55622db9b03310c6ed639";
export const TYPE_FORM_DATE = "5ed55f8fdb9b03310c6ed63b";
export const TYPE_FORM_DATETIME = "5ed55f9fdb9b03310c6ed63c";
export const TYPE_FORM_EMAIL = "5ee549d31a723c0824c7c99b";
export const TYPE_FORM_LIST_VALOURS = "5fe33c4faf54190ba3dcd16f";
export const TYPE_FORM_DOCUMENT = "609eb02cf4de5e2b3a16866c";

// STATUS
export const STATUS_UNDEFINED = "602c01538b9a9f151a7c2ed0";
export const STATUS_NOT_STARTED = "602c01dc8b9a9f151a7c2ed1";
export const STATUS_IN_GAME = "602c02488b9a9f151a7c2ed2";
export const STATUS_HALFTIME = "602c02cc8b9a9f151a7c2ed3";
export const STATUS_FINALIZED = "602c03148b9a9f151a7c2ed4";
export const STATUS_SUSPENDED = "602c03548b9a9f151a7c2ed5";

export const LIMIT = 6;

export const MODULES = {
  MODULE_TEAM :  "5e9ae8d82d47302b981a83bc",
  MODULE_MEMBER : "5e9ae8be2d47302b981a83bb",
  MODULE_COMUNICATION : "5e862dfdc331bf12200f0e2a",
}

export const MOBILE_MEDIAQUERY = 'screen and (max-width: 599px)';
export const TABLET_MEDIAQUERY = 'screen and (min-width: 600px) and (max-width: 959px)';
export const MONITOR_MEDIAQUERY = 'screen and (min-width: 960px)';

export enum TYPE_OF_CUT {
  COVER,
  INSIDE,
  OUTSIDE,
  CONTAIN,
  FILL
}

export enum TYPE_OF_QUALITY {
  HIGH,
  MEDIUM,
  LOW
}

export enum TYPE_ACTIONS {
  READ,
  EDIT,
  DELETE,
  CREATE
}
