import gql from 'graphql-tag';

export const loginResponse = `
      id
      firstName
      lastName
      image
      status
      lastName
      socials{
        id
        typeId
        value
      }
      entities {
        entityId
        mainEntity
        entity {
          id
          name
          domain
          code
          image
          verified
          primaryColor
          secondaryColor
          requiresAcceptance
          social{
            id
            typeId
            value
          }
          sponsor{
            id
            name
            url
            image
            description
          }
          modules {
            id
            moduleId
            expirationDate
            active
          }
        }
        roles
        permissions {
          action,
          read
          edit
          delete
          create
          global
        }
      }
      `;

export const setMainEntityMutation = `
  mutation setMainEntity($entityId: String!) {
    setMainEntity(entityId: $entityId) {
      `+loginResponse+`
    }
  }
`;

export const createEntityUserMutation = `
  mutation createEntityUser($input: EntitiesInput!) {
    createEntityUser(input: $input) {
      `+loginResponse+`
    }
  }
`;


export const createEntityMutation = `
  mutation createEntity ($entityTypeId: String, $image: String, $name: String, $domain: String, $primaryColor: String, $secondaryColor: String, $updatedBy: String, $createdBy: String ) {
    createEntity(input: {
        entityTypeId: $entityTypeId
        name: $name
        domain:$domain
        image: $image
        verified:false
        primaryColor: $primaryColor
        secondaryColor:$secondaryColor
        updatedBy:$updatedBy
        createdBy: $createdBy
      }) {
        `+loginResponse+`
    }
  }
`;

export const updateEntityMutation = `
  mutation updateEntity ($id: String!, $image: String, $name: String!, $primaryColor: String, $secondaryColor: String, $requiresAcceptance: Boolean, $updatedBy: String! ) {
    updateEntity(id: $id,
     input: {
        name: $name
        image: $image
        primaryColor: $primaryColor
        secondaryColor:$secondaryColor
        requiresAcceptance: $requiresAcceptance
        updatedBy:$updatedBy
      }) {
        `+loginResponse+`
    }
  }
`;

export const createOrUpdateEntitySocialMutation = `
  mutation createOrUpdateEntitySocial ($entityId: String!, $id: String, $typeId: String!, $value: String!, $updatedBy: String!, $createdBy: String ) {
    createOrUpdateEntitySocial(entityId: $entityId,
     input: {
      id: $id
      typeId: $typeId
      value: $value
      updatedBy: $updatedBy
      createdBy: $createdBy
      }) {
        `+loginResponse+`
    }
  }
`;

export const deleteEntitySocialMutation = `
  mutation deleteEntitySocial ($entityId: String!, $id: String!) {
    deleteEntitySocial(entityId: $entityId, id: $id ) {
        `+loginResponse+`
    }
  }
`;



