import { Competition } from "app/graphql/types";

export class GetPublicCompetitions {
  static readonly type = '[Competition] Get public Competitions';
  constructor(public readonly seasonId: string){}
}

export class GetPublicCurrentCompetition {
  static readonly type = '[Competition] Get public current Competition';
  constructor(public readonly id: string){}
}



