
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import gql from 'graphql-tag';
import { QueryMatchArgs, QueryMatchesArgs, QueryMatchesWithMultimediaArgs } from '../types';
import { matchesQuery, matchesWithMultimediaQuery, matchQuery } from '../operations/queries/matchQueries';


@Injectable({
  providedIn: 'root'
})

export class MatchService {

  constructor(
    private apollo: Apollo,
    private translateService: TranslateService
    ) {

  }

  getPublicMatches(data:QueryMatchesArgs){
    return this.apollo.query<any>({
      query: gql(matchesQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.matches)
    );
  }

  getPublicMatchesWithMultimedia(data:QueryMatchesWithMultimediaArgs){
    return this.apollo.query<any>({
      query: gql(matchesWithMultimediaQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.matchesWithMultimedia)
    );
  }

  getPublicMatch(data:QueryMatchArgs){
    return this.apollo.query<any>({
      query: gql(matchQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.match)
    );
  }

 /*  getTeamsParticipatingMatch(data:QueryTeamsParticipatingMatchArgs){
    return this.apollo.query<any>({
      query: gql(teamsParticipatingMatchQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.teamsParticipatingMatch)
    );
  }

  getMembersParticipatingMatch(data:QueryMembersParticipatingMatchArgs){
    return this.apollo.query<any>({
      query: gql(membersParticipatingMatchQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.membersParticipatingMatch)
    );
  }  */

  /* getPublicMatch(data:PublicMatchQueryModel){
    return this.apollo.query<any>({
      query: gql(publicMatchQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.publicMatch)
    );
  }

  getPublicListMatchMembers(data:PublicListMatchMembersQueryModel){
    return this.apollo.query<any>({
      query: gql(publicListMatchMembersQuery),
      variables:  data ,
    }).pipe(
      map(result => result.data.publicListMatchMembers)
    );
  }

  publicCreateOrUpdatePollAnswer(data:PublicCreateOrUpdatePollAnswerMutationModel){
    return this.apollo.mutate<any>({
      mutation: gql(PublicCreateOrUpdatePollAnswerMutation),
      variables: data
    }).pipe(
      map((result:any) => result.data.publicCreateOrUpdatePollAnswer)
    );
  }

  publicCreateFormAnswer(data:PublicCreateFormAnswerMutationModel){
    return this.apollo.mutate<any>({
      mutation: gql(PublicCreateFormAnswerMutation),
      variables: data
    }).pipe(
      map((result:any) => result.data.publicCreateFormAnswer)
    );
  }

  generateForm(form:FormPublication){
    let fields: FormlyFieldConfig[] = [];

    form.customFields.forEach(item => {
      switch(item.typeId){
        case TYPE_FORM_TEXT:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              required: item.require,
              label:item.name
            }
          });
        break;
        case TYPE_FORM_TEXT_AREA:
          fields.push({
            className: '',
            type: 'textarea',
            key: item.id,
            templateOptions: {
              required: item.require,
              label:item.name,
              rows: 8,
            }
          });
        break;
        case TYPE_FORM_CHECK:
          fields.push({
            className: '',
            type: 'custom-toggle',
            key: item.id,
            expressionProperties: {
              'templateOptions.label': item.name,
            },
            templateOptions: {
              required: item.require,
              label:item.name,
            }
          });
        break;
        case TYPE_FORM_COLOR:
          fields.push({
            className: '',
            type: 'color',
            key: item.id,
            defaultValue: '#c1c1c1',
            templateOptions: {
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_NUMBER:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              type: 'number',
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_DATE:
          fields.push({
            className: '',
            type: 'date',
            key: item.id,
            templateOptions: {
              label:item.name,
            },
          });
        break;
        case TYPE_FORM_DATETIME:
          fields.push({
            className: '',
            type: 'datetime',
            key: item.id,
            templateOptions: {
              label:item.name,
            }
          });
        break;
        case TYPE_FORM_EMAIL:
          fields.push({
            className: '',
            type: 'input',
            key: item.id,
            templateOptions: {
              type: 'email',
              label:item.name,
            },
            validators: {
              ip: {
                expression: (c) => !c.value || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" `+this.translateService.instant('validarions.not_valid_email'),
              },
            },
          });
        break;
        case TYPE_FORM_LIST_VALOURS:
          let options = [];
          item.listOfValours.forEach(value =>{
            options.push({label: value, value: value});
          });
          fields.push({
            className: '',
            type: 'select',
            key: item.id,
            templateOptions: {
              label: item.name,
              options: options,
            },
          });
        break;
      }
    });

    return fields;
  } */


}
