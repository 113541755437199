<div class="matero-row sponsor-content" *ngIf="entity$ | async; let entity" fxLayout="row wrap">
    <span *ngIf="topSponsors == undefined || topSponsors === false" class="m-b-8"><b>{{ "menu.sponsors" | translate }}:</b></span>
    <div class="matero-col" fxFlex="100" fxFlex.gt-md="100" >
        <div class="matero-row" fxLayout="row wrap">
            <div  class="matero-col" fxFlex="100" fxFlex.gt-sm="100">
                <div class="matero-row sponsors m-y-8 m-l-0 m-r-0" fxLayout="row wrap">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <ng-container *ngFor="let sponsor of entity.sponsor; let i = index">
                            <div ngxSlickItem *ngIf="sponsor.active != false" class="slide sponsor-card">
                                <div  class="pointer" [routerLink]="sharedService.buildRoute(['sponsors', sponsor.id])">
                                <img class="pointer" 
                                src="{{(sponsor.image) ? sharedService.getImage(sponsor.image) : sharedService.getSVG(defaultImageTeam, (entity.primaryColor | removeHexColor))}}" alt="" width="100%">     
                                <a href="{{sponsor.url}}" *ngIf="sponsor.url" target="new" class="pointer" style="position: absolute;
                                    top: 2px;
                                    right: 2px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    background-color: #ffffff;">
                                       <mat-icon class="menu-icon" style="font-size: 12px; width: 12px;height: 15px;"><i class="fa fa-external-link-alt"></i></mat-icon>
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                        
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
        <div style="position: relative;" *ngIf="topSponsors == undefined || topSponsors === false">
            <hr style="margin: 32px;"> 
            <div style="width: 100%;text-align:center; position: absolute; top:-18px;">
              <button mat-flat-button color="primary" [routerLink]="sharedService.buildRoute(['sponsors'])">{{ 'general.show_more' | translate }}</button>
            </div>
          </div>
    </div>

</div>




