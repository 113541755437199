import { PublicPublicationsQueryModel } from "app/graphql/interfaces/publicationModel";
import { Publication } from "app/graphql/types";

export class GetPublicPublications {
  static readonly type = '[PUBLICATION] Get public publications';
  constructor(public readonly publicationsQuery: PublicPublicationsQueryModel){}
}

export class GetPublicPublicationsWidget {
  static readonly type = '[PUBLICATION] Get public publications widget';
  constructor(public readonly publicationsQuery: PublicPublicationsQueryModel){}
}


export class GetMorePublicPublications {
  static readonly type = '[PUBLICATION] Get more public publications';
  constructor(public readonly publicationsQuery: PublicPublicationsQueryModel){}
}

export class GetPublicCurrentPublication {
  static readonly type = '[PUBLICATION] Get public current publication';
  constructor(public readonly urlTitle: string){}
}

export class SetPublicCurrentPublication {
  static readonly type = '[PUBLICATION] Set public current publication';
  constructor(public readonly publication: Publication){}
}



