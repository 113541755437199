import { SettingsService } from './../../core/bootstrap/settings.service';
import { DEAFULT_IMAGE_ENTITY } from './../../shared/constants';
import { SharedService } from './../../shared/services/shared.service';
import { TypeService } from 'app/graphql/services/type.service';
import { Entity } from './../../graphql/interfaces/entityModel';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import * as screenfull from 'screenfull';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { EntityState } from 'app/redux/state/entity.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() showToggle = true;
  @Input() showBranding = true;

  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleSidenavNotice = new EventEmitter<void>();

  private get screenfull(): screenfull.Screenfull {
    return screenfull as screenfull.Screenfull;
  }

  options = this.settings.getOptions();

  @Select(EntityState.entity)
  entity$: Observable<Entity>;
  socials: any[];
  defaultImageEntity = DEAFULT_IMAGE_ENTITY;

  constructor(public typeService: TypeService, private sharedService: SharedService, private settings: SettingsService,) {}

  ngOnInit() {

    /* this.entity$.pipe(untilDestroyed(this)).subscribe(entity => {
      if(entity){
        this.socials = entity.social.map(obj=> {
          const type = this.typeService.getType(obj.typeId);
          return ({ ...obj, icon: this.typeService.getIcon(type.langKey), type: type.langKey });
        }).filter(item=> !["type.social_email", "type.social_web"].includes(item.type));
      }
    }); */
  }

  // TODO:
  toggleFullscreen() {
    if (this.screenfull.enabled) {
      this.screenfull.toggle();
    }
  }
}
