import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AngularFireStorage } from '@angular/fire/storage';
import { tap, finalize } from 'rxjs/operators';
const SECRET_KEY = 'adasdasdfasdf23dasd';

@Injectable()
export class StorageService {

constructor(private storage: AngularFireStorage) { }

encrypt(data) {
  data = CryptoJS.AES.encrypt(data, SECRET_KEY);
  data = data.toString();
  return data;
}

decrypt(data) {
  data = CryptoJS.AES.decrypt(data, SECRET_KEY);
  data = data.toString(CryptoJS.enc.Utf8);
  return data;
}

// Set the json data to local storage
setItem(key: string, value: any) {
  localStorage.setItem(key, value);
}
// Get the json value from local storage
getItem(key: string) {
  const data = localStorage.getItem(key);
  return (data) ? data : null;
}

// Set the json data to local storage
setJsonValue(key: string, value: any) {
  localStorage.setItem(key, this.encrypt(value));
}
// Get the json value from local storage
getJsonValue(key: string) {
  const data = localStorage.getItem(key);
  return (data) ? JSON.parse(this.decrypt(data)) : null;
}
// Clear the local storage
clearStorage() {
  return localStorage.clear();
}

//Tarea para subir archivo
uploadCloudStorage(name: string, data: any) {
  return this.storage.upload(name, data);
}

//Referencia del archivo
referenceCloudStorage(name: string) {
  return this.storage.ref(name);
}

async uploadFile(path: string, file: File) {
  let referencia = this.referenceCloudStorage(path);
  return referencia.put(file);

}

deleteFile(path: string) {
  let referencia = this.referenceCloudStorage(path);
  return referencia.delete();

}

}
