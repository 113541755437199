import gql from 'graphql-tag';

export const publicEntityQuery = `
  query publicEntity($webDomain: String!) {
    publicEntity(webDomain: $webDomain) {
      id
      entityTypeId
      name
      domain
      webDomain{
        id
        url
        statusLangKey
        main
        status
      }
      url
      image
      verified
      primaryColor
      secondaryColor
      defaultSeason{
        id
      }
      social{
        id
        typeId
        value
        createdBy
        updatedAt
      }
      sponsor {
        id
        name
        url
        image
        description
        active
        createdBy
        updatedAt
      }
      modules{
        id
        moduleId
        expirationDate
        active
      }
      web{
        language
        typeMenu
        typeFont
        typeBorder
        backgroundImage
        backgroundColor
        showSponsorsHome
        autoContent
        legalWarning
        emailWarning
        legalCookies
        name
        tel1
        tel2
        fax
        cif
        street
        email
        analytics
        jivochat
        customCss
        customHead
      }
      banner{
        id
        name
        url
        image
        description
        urlNewPage
        inHome
        inOtherPages
      }
      homeImage{
        id
        name
        url
        image
        description
        urlNewPage
        active
        homeImagesOverlap
        colorOverlapOnlyText
        colorOverlap
        textOverlap
        textColorOverlap
        buttonColorOverlap
        buttonTextOverlap
        buttonLinkOverload
        urlNewPageOverlap
        align
        alignVertical
      }
      pages{
        id
        entityId
        title
        urlTitle
        galleryImages
        body
        parentId
        active
        public
        geolocation{
          id
          latitude
          longitude
          formattedAddress
        }
        form {
          id
          customFields{
            id
            typeId
            name
            nameKey
            require
            listOfValours
          }
        }
      }
    }
  }
`;

export const publicPagesQuery = `
  query publicPages($entityId: String!) {
    publicPages(entityId: $entityId) {
      id
      entityId
      title
      urlTitle
      galleryImages
      body
      active
      public
      poll
      event
      form
    }
  }
`;
