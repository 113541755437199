
import { Entity } from './../../graphql/interfaces/entityModel';
import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { SharedService } from '@shared/services/shared.service';
import { DEAFULT_IMAGE_PROFILE } from '@shared/constants';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EntityState } from 'app/redux/state/entity.state';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
})
export class UserComponent {
  //Constantes
  defaultImageProfile = DEAFULT_IMAGE_PROFILE;

  @Select(EntityState.entity)
  entity$: Observable<Entity>;

  constructor(private sharedService: SharedService, private store: Store,private bottomSheet: MatBottomSheet) {

    }

    logOut(){
      //this.store.dispatch(new SignOut());
    }

}
