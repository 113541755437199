
import { PublicationService } from '../../../../graphql/services/publication.service';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { DEAFULT_IMAGE_PUBLICATION, URI_STORAGE_DOCUMENT } from '../../../constants';
import { SharedService } from '../../../services/shared.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { FormPublication} from 'app/graphql/interfaces/publicationModel';
import { HttpClient } from '@angular/common/http';
import { Entity } from 'app/graphql/interfaces/entityModel';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { StorageService } from '@shared/services/secure-storage.service';
import { v4 as uuidv4 }  from 'uuid';

@UntilDestroy()
@Component({
  selector: 'app-form-publication',
  styleUrls: ['./form-publication.component.scss'],
  templateUrl: './form-publication.component.html',
})
export class FormPublicationComponent implements OnInit, OnChanges {

  @Input() formPublication: FormPublication;
  @Input() publicationId: string;
  @Input() inPage: boolean;

  body = null;
  defaultImagePublication = DEAFULT_IMAGE_PUBLICATION;
  entity: Entity = this.store.snapshot().Entity.entity;
  loading: boolean = false;

  content: SafeHtml = null;
  form = new FormGroup({});
  model_form: any = {};
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [];

  constructor(private store: Store,private sharedService: SharedService,private storageService: StorageService, public translate: TranslateService,private _snackBar: MatSnackBar,private publicationService: PublicationService,  private http: HttpClient, private titleService: Title, private metaService: Meta, private sanitizer: DomSanitizer, private route: ActivatedRoute, private translateService: TranslateService) {

  }

  ngOnInit() {
    this.fields = this.publicationService.generateForm(this.formPublication);
    if(this.entity.web.emailWarning){
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.entity.web.emailWarning);
    }else{
      this.http.get('assets/templates/contact-form/'+this.translateService.currentLang+'.html', {responseType: 'text'}).subscribe(data => {
        this.content = this.sanitizer.bypassSecurityTrustHtml(data.replace(/{{name}}/g, this.entity.web.name)
        .replace(/{{email}}/g, this.entity.web.email)
        .replace(/{{tel1}}/g, this.entity.web.tel1)
        .replace(/{{tel2}}/g, this.entity.web.tel2)
        .replace(/{{fax}}/g, this.entity.web.fax)
        .replace(/{{street}}/g, this.entity.web.street)
        .replace(/{{cif}}/g, this.entity.web.cif)
        .replace(/{{web}}/g, this.entity.webDomain.filter(item => item.main == true)[0].url));
      })
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formPublication) {
      this.fields = this.publicationService.generateForm(changes.formPublication.currentValue);
    }
  }

   async submitForm(formDirective: FormGroupDirective){
    this.loading = true;
    let error = false;

    let formData: any = [];

    for(const key of Object.keys(this.model_form)){
      if(this.model_form[key] instanceof File) {
        let path = URI_STORAGE_DOCUMENT.replace("[idEntity]", this.entity.id) + uuidv4() + "." + this.model_form[key].name.split('.').pop();
        let result = await this.storageService.uploadFile(path , this.model_form[key]);

        if(result.state != "success"){
          this._snackBar.open(this.translate.instant('error.error_api'), null, {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
          this.loading = false;
          error = true;
        }

        formData.push({
          customId: key,
          value: path
        });

      } else {
        formData.push({
          customId: key,
          value: this.model_form[key]
        });
      }

    };

    if(!error) {
      if(this.inPage){
        this.publicationService.publicCreatePageFormAnswer({entityId:this.entity.id, pageId: this.publicationId, answers: formData}).subscribe(( data:any ) => {

          this._snackBar.open(this.translate.instant('publication.send_ok'), null, {
            duration: 5000,
            panelClass: ['success-snackbar']
          });

          this.form.reset();
          formDirective.resetForm();
          this.loading = false;

        },(error) => {

          this._snackBar.open(this.translate.instant('publication.send_error'), null, {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
          this.loading = false;

        });
      }else{
        this.publicationService.publicCreateFormAnswer({entityId:this.entity.id, publicationId: this.publicationId, answers: formData}).subscribe(( data:any ) => {

          this._snackBar.open(this.translate.instant('publication.send_ok'), null, {
            duration: 5000,
            panelClass: ['success-snackbar']
          });

          this.form.reset();
          formDirective.resetForm();
          this.loading = false;

        },(error) => {

          this._snackBar.open(this.translate.instant('publication.send_error'), null, {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
          this.loading = false;

        });
      }
    }
  }



}






